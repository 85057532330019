import React from "react";
import { differenceInDays, isToday, startOfDay } from "date-fns"
import { useHistory } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";

import { formatDate } from '@beacharound/beacharound-ui-helpers';
import { Tile } from "@beacharound/beacharound-ui";

import { useActivities } from "../../context/activities";
import { viewNotifications } from "../../api/notifications";

//  Tipi di notifiche
//  --------------------------------------
//  type                        category
//  --------------------------------------
//  SISTEM                  |   -
//  RESERVATIONS            |   NEW
//  RESERVATIONS            |   PAID
//  RESERVATIONS            |   DUPLICATE
//  BEACHAROUND_PAYMENTS    |   PAID
//  BEACHAROUND_PAYMENTS    |   EXPIRED
//  TRANSACTIONS_SCHEDULED  |   EXPIRED
//  QUOTATIONS              |   ACCEPTED
//  QUOTATIONS              |   DECLINED
//  HOTEL_REPORTS           |   NEW
//  --------------------------------------

export default function NotificationsListItem({
    notification,
    noBorder
}) {

    const { activeActivity: activity } = useActivities();
    const history = useHistory();

    const cache = useQueryClient();

    const { mutate: setView, status } = useMutation(viewNotifications, {
        onSuccess: () => {
            return cache.invalidateQueries(["notifications", activity?._id])
        }
    });

    function viewHandler() {

        setView({
            activityId: activity?._id,
            data: {
                notifications: [notification?._id]
            }
        });

        if (targetUrl) {
            history.push(targetUrl);
        }
    }

    let notificationTimeFormat = "HH:mm";
    const today = startOfDay(new Date());
    const notificationDate = new Date(notification?.createdAt);
    const dateDiff = differenceInDays(today, notificationDate);

    if (!isToday(notificationDate) && dateDiff <= 7) {
        notificationTimeFormat = "EEEE d MMM - HH:mm";
    }
    if (dateDiff >= 7) {
        notificationTimeFormat = "dd/MM/yyyy - HH:mm";
    }

    let title = notification?.title;
    let text = notification?.content;
    let time = formatDate(notificationDate, notificationTimeFormat);
    let targetUrl;
    let actions = [];

    if (notification.type === "SISTEM" && !notification.view) {
        actions = [
            {
                text: "Segna come già letta",
                action: viewHandler,
                color: "black",
                status
            }
        ];
    }

    if (notification.type === "RESERVATIONS" && notification.category === "NEW") {
        title = "Nuova prenotazione ricevuta!"
        text = "Hai appena ricevuto una prenotazione online, clicca per controllare."
        targetUrl = `/beaches/${activity?._id}/reservations/${notification.subject}`
        actions = [
            {
                text: "Controlla >",
                action: viewHandler,
                status
            }
        ];
    }

    if (notification.type === "RESERVATIONS" && notification.category === "PAID") {
        title = "Prenotazione pagata!"
        text = "Hai appena ricevuto il pagamento di una prenotazione, clicca per controllare."
        targetUrl = `/beaches/${activity?._id}/reservations/${notification.subject}`
        actions = [
            {
                text: "Controlla >",
                action: viewHandler,
                status
            }
        ];
    }

    if (notification.type === "RESERVATIONS" && notification.category === "DUPLICATE") {
        title = "Prenotazioni duplicate!"
        text = "Le prenotazioni trovate sono state duplicate con successo, clicca per controllare."
        targetUrl = `/beaches/${activity?._id}/reservations/${notification.subject}`
        actions = [
            {
                text: "Controlla >",
                action: viewHandler,
                status
            }
        ];
    }

    if (notification.type === "BEACHAROUND_PAYMENTS" && notification?.category === "PAID") {
        title = "Nuovo pagamento ricevuto!";
        text = "Hai appena ricevuto un pagamento online, clicca per controllare.";
        targetUrl = `/beaches/${activity?._id}/payments/${notification?.subject}`;
        actions = [
            {
                text: "Controlla >",
                action: viewHandler,
                status
            }
        ];
    }

    if (notification?.type === "BEACHAROUND_PAYMENTS" && notification?.category === "EXPIRED") {
        title = "Richiesta di pagamento scaduta!";
        text = "È appena scaduta una richiesta di pagamento, clicca per controllare ed eventualmente prolungare la scadenza.";
        targetUrl = `/beaches/${activity?._id}/payments/${notification?.subject}`;
        actions = [
            {
                text: "Controlla >",
                action: viewHandler,
                status
            }
        ];
    }

    if (notification?.type === "TRANSACTIONS_SCHEDULED" && notification?.category === "EXPIRED") {
        title = "Pagamento da richiedere entro oggi!";
        text = "Uno dei promemoria di pagamento, clicca per controllare.";
        targetUrl = `/beaches/${activity?._id}/transactionsScheduled/${notification?.subject}`;
        actions = [
            {
                text: "Controlla >",
                action: viewHandler,
                status
            }
        ];
    }

    if (notification?.type === "QUOTATIONS" && notification?.category === "ACCEPTED") {
        title = "Preventivo accettato!";
        text = "Un cliente ha appena accettato il preventivo, clicca per controllare.";
        targetUrl = `/beaches/${activity?._id}/quotes/simple/${notification?.subject}`;
        actions = [
            {
                text: "Controlla >",
                action: viewHandler,
                status
            }
        ];
    }

    if (notification?.type === "QUOTATIONS" && notification?.category === "DECLINED") {
        title = "Preventivo rifiutato";
        text = "Un cliente ha appena rifiutato il preventivo, clicca per controllare.";
        targetUrl = `/beaches/${activity?._id}/quotes/simple/${notification?.subject}`;
        actions = [
            {
                text: "Controlla >",
                action: viewHandler,
                status
            }
        ];
    }

    if (notification?.type === "HOTEL_REPORTS" && notification?.category === "NEW") {
        title = "Report hotel pronto!";
        text = "Il report che hai creato poco fa è pronto, clicca per controllare.";
        targetUrl = `/beaches/${activity?._id}/hotels/reports`; // Serve anche l'id della linkedActivity
        actions = [
            {
                text: "Controlla >",
                action: viewHandler,
                status
            }
        ];
    }

    if (notification?.type === "COUPONS" && notification?.category === "DEACTIVATION") {
        title = "Nuovo coupon generato!";
        text = "È stato generato un nuovo coupon per un posto disattivato online, clicca per controllare.";
        targetUrl = `/beaches/${activity?._id}/coupons/${notification?.subject}`;
        actions = [
            {
                text: "Controlla >",
                action: viewHandler,
                status
            }
        ];
    }

    return (
        <Tile
            pretitle={`<strong><small>${time}</small></strong>`}
            title={title}
            active={!notification?.view}
            text={`<small>${text}</small>`}
            actions={actions}
            noBorder={noBorder}
            mobileSmallTitle
            small
        />
    );
}