import { lazy } from 'react';
import MainLayout from '../layouts/MainLayout/MainLayout';

const SettingsList = lazy(() => import('../pages/Shared/Settings/SettingsList'));
const SettingsDetail = lazy(() => import('../pages/Shared/Settings/SettingsDetail'));
const Info = lazy(() => import('../pages/Shared/Settings/Info/Info'));
const Services = lazy(() => import('../pages/Shared/Settings/Services/Services'));
const Contacts = lazy(() => import('../pages/Shared/Settings/Contacts/Contacts'));
const Account = lazy(() => import('../pages/Shared/Settings/Account/Account'));

const UsersList = lazy(() => import('../pages/Shared/Settings/Users/UsersList'));
const UsersDetail = lazy(() => import('../pages/Shared/Settings/Users/UsersDetail'));

const BillingList = lazy(() => import('../pages/Shared/Settings/Billing/BillingList'));
const BillingDetail = lazy(() => import('../pages/Shared/Settings/Billing/BillingDetail'));
const BillingCreate = lazy(() => import('../pages/Shared/Settings/Billing/BillingCreate'));

export default [
    {
        path: '/users/:userId',
        component: UsersDetail,
        layout: MainLayout,
        permissions: { memberships: ["MANAGEMENT"] },
        analytics: { name: ["Utenti", "Dettaglio"] }
    },{
        path: '/billing/create',
        component: BillingCreate,
        layout: MainLayout,
        exact: true,
        analytics: { name: ["Profili di fatturazione", "Creazione"] }
    },{
        path: '/billing/:profileId',
        component: BillingDetail,
        layout: MainLayout,
        analytics: { name: ["Profili di fatturazione", "Dettaglio"] }
        
    },{
        path: "/",
        component: SettingsList,
        exact: true,
        analytics: { name: ["Impostazioni"] }
    },{
        path: '/',
        component: SettingsDetail,
        layout: MainLayout,
        routes: [{
            path: '/info',
            component: Info,
            analytics: { name: ["Info"] },
            permissions: { memberships: ["BASIC"] }
        }, {
            path: '/services',
            component: Services,
            analytics: { name: ["Servizi"] },
            permissions: { memberships: ["BASIC"] }
        }, {
            path: '/users',
            component: UsersList,
            exact: true,
            analytics: { name: ["Utenti"] }
        }, {
            path: '/billing',
            component: BillingList,
            exact: true,
            analytics: { name: ["Profili fatturazione"] }
        }, {
            path: '/contacts',
            component: Contacts,
            exact: true,
            analytics: { name: ["Contatti"] }
        }, {
            path: '/account',
            component: Account,
            analytics: { name: ["Account"] }
        }].map(route => {
            return {
                ...route,
                path: `/hotels/:hotelId/settings${route.path}`,
                private: true,
                analytics: { name: ["Hotel", "Impostazioni", ...(route.analytics?.name || [])] }
            }
        })
    }
].map(route => {
    return {
        ...route,
        path: `/hotels/:hotelId/settings${route.path}`,
        private: true,
        analytics: { name: ["Hotel", "Impostazioni", ...(route.analytics?.name || [])] }
    }
})
