import axios from '../_defaults/printers/epson';

import { create as createXml } from "xmlbuilder2";

import { getNonFiscalReceiptXml, getOrderReceiptXml, getPrinterStatusXml, getReservationProductsXml } from './lib/choose';
import { format } from 'date-fns/esm';

export async function getPrinterStatus({ printer, url }) {
    const xml = getPrinterStatusXml({ printer });
    try {
        const response = await axios.post(changeMethodUrl(url), xml);
        return response?.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export async function printReservationProducts({ printer, printerUrl, data }) {

    const xml = getReservationProductsXml({ printer, data });

    try {
        const response = await axios.post(changeMethodUrl(printerUrl), xml);
        return response?.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export async function epsonPrintOrderReceipt({ printer, printerUrl, receipt }) {

    const xml = getOrderReceiptXml({ printer, receipt });

    try {
        const response = await axios.post(changeMethodUrl(printerUrl), xml);
        return response?.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export async function epsonPrintNonFiscalReceipt({ activityName, printer, printerUrl, receipt }) {

    const xml = getNonFiscalReceiptXml({ activityName, printer, receipt });

    try {
        const response = await axios.post(changeMethodUrl(printerUrl), xml.toString());
        return response?.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export async function epsonPrintFiscalReceipt({ printerUrl, receipt }) {

    const xml = createXml({ version: "1.0", encoding: "UTF-8" });
    const envelope = xml.ele({
        "s:Envelope": {
            "@xmlns:s": "http://schemas.xmlsoap.org/soap/envelope/"
        }
    });
    const body = envelope.ele("s:Body");
    const print = body.ele("printerFiscalReceipt")
    print.ele({
        "displayText": {
            "@operator": 1,
            "@data": "Stampa scontrino"
        }
    });
    const fiscal = print.ele({ "beginFiscalReceipt": { "@operator": 1 } });
    const { products, amount, method, adjustement, reservations, overrides } = receipt;

    // console.log(reservations);

    if (!!reservations?.length) {
        for (const reservation of reservations) {
            let reservationString = `Prenotazione dal ${format(new Date(reservation.checkin), 'dd/MM')} al ${format(new Date(reservation.checkout), 'dd/MM ')}`;
            if (reservation?.duration === 1) {
                reservationString = `Prenotazione del ${format(new Date(reservation.checkin), 'dd/MM')}`;
            }
            fiscal.ele({
                "printRecMessage": {
                    "@operator": 1,
                    "@message": reservationString,
                    "@justification": 1,
                    "@messageType": 4
                }
            })
            if (reservation?.placedProduct?.number) {
                fiscal.ele({
                    "printRecMessage": {
                        "@operator": 1,
                        "@message": `Posto: ${reservation?.placedProduct?.number}`,
                        "@justification": 1,
                        "@messageType": 4
                    }
                })
            }
            fiscal.ele({
                "printRecMessage": {
                    "@operator": 1,
                    "@message": `------------------------`,
                    "@justification": 1,
                    "@messageType": 4
                }
            })
        }
    }

    if (!overrides?.includes("MODIFICATIONS")) {
        for (const product of products) {
            let name = product.description || product.name;
            let quantity = product.quantity;
            let price = product.amount;
            let department = product.department;
            if (quantity > 1) {
                name = `${name} x${quantity}`
            }
            fiscal.ele({
                "printRecItem": {
                    "@operator": 1,
                    "@description": name,
                    "@quantity": 1,
                    "@unitPrice": price,
                    "@department": department || 1,
                    "@justification": 1,
                }
            });
        };
    }

    if (overrides?.includes("MODIFICATIONS")) {
        fiscal.ele({
            "printRecItem": {
                "@operator": 1,
                "@description": "Modifiche aggiuntive",
                "@quantity": 1,
                "@unitPrice": amount,
                "@department": 1,
                "@justification": 1,
            }
        });
    }

    if (adjustement?.amount && adjustement?.amount < 0) {
        fiscal.ele({
            "printRecSubtotalAdjustment": {
                "@operator": 1,
                "@adjustmentType": 1,
                "@description": adjustement?.description,
                "@amount": Math.abs(adjustement?.amount),
                "@justification": 1
            }
        });
    }

    if (adjustement?.amount && adjustement?.amount > 0) {
        fiscal.ele({
            "printRecSubtotalAdjustment": {
                "@operator": 1,
                "@adjustmentType": 6,
                "@description": adjustement?.description,
                "@amount": Math.abs(adjustement?.amount),
                "@justification": 1
            }
        });
    }

    if (amount) {
        let paymentType = 0;
        switch (method) {
            case "cash":
                paymentType = 0
                break;
            case "card":
                paymentType = 2
                break;
            case "transfer":
                paymentType = 2
                break;
            case "check":
                paymentType = 2
                break;
            case "applepay":
                paymentType = 2
                break;
            case "googlepay":
                paymentType = 2
                break;
            default:
                paymentType = 0
                break;
        }
        fiscal.ele({
            "printRecTotal": {
                "@operator": 1,
                "@description": "Totale",
                "@payment": amount,
                "@paymentType": paymentType,
                "@justification": 1
            }
        });
    }

    print.ele({ "endFiscalReceipt": { "@operator": 1 } });

    try {
        const response = await axios.post(changeMethodUrl(printerUrl), xml.toString());
        return response?.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export async function epsonPrintFiscalVoid({ printerUrl, receipt }) {

    const xml = createXml({ version: "1.0", encoding: "UTF-8" });
    const envelope = xml.ele({
        "s:Envelope": {
            "@xmlns:s": "http://schemas.xmlsoap.org/soap/envelope/"
        }
    });
    const body = envelope.ele("s:Body");
    const print = body.ele("printerCommand")
    print.ele({
        "directIO": {
            "@command": "1078",
            "@data": receipt.cancelString
        }
    });

    try {
        const response = await axios.post(changeMethodUrl(printerUrl), xml.toString());
        return response?.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export async function epsonPrintReport({ printerUrl, type }) {

    const xml = createXml({ version: "1.0", encoding: "UTF-8" });
    const envelope = xml.ele({
        "s:Envelope": {
            "@xmlns:s": "http://schemas.xmlsoap.org/soap/envelope/"
        }
    });
    const body = envelope.ele("s:Body");
    const print = body.ele("printerFiscalReport");
    let reportElement;
    switch (type) {
        case "z":
            reportElement = "printZReport"
            break;
        case "x":
            reportElement = "printXReport"
            break;
        default:
            reportElement = "printZReport"
            break;
    }
    print.ele({
        [reportElement]: {
            "@operator": 1,
            "@timeout": 10000
        }
    });

    try {
        const response = await axios.post(changeMethodUrl(printerUrl), xml.toString());
        return response?.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

function changeMethodUrl(printerUrl) {
    return printerUrl;
    // return printerUrl?.replace("https:", window.location.protocol) || "";
}