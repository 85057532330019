import React, { Fragment } from 'react';

import { ButtonsRow, Column, ColumnContainer, Divider, LoadingContainer, MainButton, MainContainer, NormalPopup, Paragraph, SimpleField } from '@beacharound/beacharound-ui';
import { useQuery } from 'react-query';
import { getReceipt } from '../../api/receipts';

export default function PreviewReceiptPopup({
    visible,
    setVisible,
    receiptId,
    activityId,
    confirmHandler,
    confirmStatus
}) {

    const { data: receipt, isLoading } = useQuery(["receipts", activityId, receiptId], () => getReceipt({
        activityId,
        receiptId
    }), {
        enabled: !!receiptId
    });

    const isModifications = receipt?.overrides?.includes("MODIFICATIONS");

    return (
        <NormalPopup
            visible={visible}
            setVisible={setVisible}
            title="Preview dello scontrino"
        >
            <LoadingContainer isLoading={isLoading}>
                <MainContainer autoWidth>
                    {!isModifications && receipt?.products?.map((product, index) => {

                        return (
                            <ColumnContainer lowMargin key={index}>
                                <Column column={2}>
                                    <SimpleField
                                        noMargin
                                        label={product.name || product.description}
                                        value={`Quantità: ${product.quantity} - Reparto: ${product.department || 1}`}
                                    />
                                </Column>
                                <Column column={2} flexEnd>
                                    <Paragraph
                                        text={`${product.amount?.toFixed(2)} €`} 
                                        align="right"
                                    />
                                </Column>
                            </ColumnContainer>
                        )
                    })}

                    {!!isModifications &&
                        <ColumnContainer>
                            <Column column={2}>
                                <SimpleField
                                    noMargin
                                    label="Modifiche aggiuntive"
                                    value={`Quantità: 1 - Reparto: 1`}
                                />
                            </Column>
                            <Column column={2} flexEnd>
                                <Paragraph
                                    text={`${receipt?.amount?.toFixed(2)} €`} 
                                    align="right"
                                />
                            </Column>
                        </ColumnContainer>
                    }

                    {receipt?.subtotal > 0 &&
                        <ColumnContainer lowMargin>
                            <Divider />
                            <Column column={2}>
                                <SimpleField
                                    inline
                                    noMargin
                                    label="Subtotale"
                                />
                            </Column>
                            <Column column={2} flexEnd>
                                <Paragraph
                                    text={`${receipt?.subtotal?.toFixed(2)} €`}
                                    align="right"
                                />
                            </Column>
                        </ColumnContainer>
                    }
                    {!!receipt?.adjustement?.amount &&
                        <ColumnContainer lowMargin>
                            {!receipt?.subtotal && <Divider />}
                            <Column column={2}>
                                <SimpleField
                                    inline
                                    noMargin
                                    label={receipt?.adjustement?.description || " "}
                                />
                            </Column>
                            <Column column={2} flexEnd>
                                <Paragraph
                                    text={`${!receipt?.adjustement?.description ? "* " : ""}${receipt?.adjustement?.amount?.toFixed(2)} €`}
                                    align="right"
                                />
                            </Column>
                        </ColumnContainer>
                    }

                    {receipt?.amount &&
                        <Fragment>
                            <Divider />
                            <ColumnContainer lowMargin>
                                <Column column={2}>
                                    <Paragraph text={"<strong>TOTALE</strong>"}/>
                                </Column>
                                <Column column={2} flexEnd>
                                    <Paragraph text={`<strong>${receipt?.amount?.toFixed(2)} €</strong>`} align="right" />
                                </Column>
                            </ColumnContainer>
                            <ButtonsRow fill>
                                <MainButton text="Annulla" border action={() => setVisible()}/>
                                <MainButton text="Stampa" action={confirmHandler} status={confirmStatus} />
                            </ButtonsRow>
                        </Fragment>
                    }

                    {!!receipt?.adjustement?.amount && !receipt?.adjustement?.description &&
                        <Fragment>
                            <Divider />
                            <Paragraph text="* Questo importo indica un aggiustamento prezzo nello scontrino. L'importo è calcolato sulla base di eventuali pagamenti precedentemente fiscalizzati, sconti applicati alle prenotazioni, utilizzo di voucher o prezzi personalizzati delle prenotazioni." />
                        </Fragment>
                    }
                </MainContainer>
            </LoadingContainer>
        </NormalPopup>
    )
}