export const version = "1";

export const title = `GESTIONE DELLE PRENOTAZIONI E DEI PAGAMENTI ATTRAVERSO BEACHAROUND`;

export const condition = `In caso di selezione di un pacchetto che preveda la possibilità di ricevere prenotazioni attraverso Beacharound.com, il Gestore avrà la possibilità di visualizzarle direttamente dalla propria pagina personale.
L’utente effettua il pagamento della prenotazione attraverso Beacharound.com.
Beacharound s.r.l. si impegna e si obbliga a versare al Gestore gli importi incassati entro il giorno quindici del mese successivo a quello della prenotazione e/o del pagamento effettuati dall’utente.
Per ciascuna prenotazione ricevuta o pagata attraverso Beacharound.com è dovuta a Beacharound s.r.l. una provvigione dell’importo concordato al momento della selezione del pacchetto prescelto (salvo accordi diversi e successivi accordi intercorsi tra Beacharound s.r.l. e il Gestore), che sarà trattenuta da Beacharound s.r.l. al momento del versamento al Gestore degli importi incassati.`

export const checks = [{
    name: "booking_view",
    label: "L’utente dichiara di accettare le condizioni di registrazione e di adesione ai pacchetti di Beacharound Business, con particolare riferimento all’articolo 3 sopra riportato."
}]