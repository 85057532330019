import { lazy } from 'react';
import MainLayout from '../layouts/MainLayout/MainLayout';

// Catalogo
const BeachEvents = lazy(() => import('../pages/Beach/Events/BeachEvents'));

const EventsAdd = lazy(() => import('../pages/Beach/Events/Events/EventsAdd'));
const EventsDetail = lazy(() => import('../pages/Beach/Events/Events/EventsDetail'));
const EventsList = lazy(() => import('../pages/Beach/Events/Events/EventsList'));

const TicketsDetail = lazy(() => import('../pages/Beach/Events/Tickets/TicketsDetail'));
const TicketsAdd = lazy(() => import('../pages/Beach/Events/Tickets/TicketsAdd'));

const ReservaionsList = lazy(() => import('../pages/Beach/Events/Reservations/ReservaionsList'))
const ReservaionsDetail = lazy(() => import('../pages/Beach/Events/Reservations/ReservationsDetail'))
const ReservaionsDetailInfo = lazy(() => import('../pages/Beach/Events/Reservations/Detail/ReservationsDetailInfo'))
const BeachReservationsDetailTransactions = lazy(() => import('../pages/Beach/Reservations/Detail/BeachReservationsDetailTransactions'));
const BeachReservationsDetailBeacharoundPayments = lazy(() => import('../pages/Beach/Reservations/Detail/BeachReservationsDetailBeacharoundPayments'));

const Settings = lazy(() => import('../pages/Beach/Events/Settings/Settings'));

export default [{
    path: '/reservations/:reservationId',
    component: ReservaionsDetail,
    layout: MainLayout,
    analytics: { name: ["Prenotazioni", "Dettaglio"] },
    routes: [{
        path: ``,
        component: ReservaionsDetailInfo,
        exact: true,
        analytics: { name: ["Info"] }
    },{
        path: `/transactions`,
        exact: true,
        component: BeachReservationsDetailTransactions,
        analytics: { name: ["Transazioni"] }
    },{
        path: `/payments`,
        exact: true,
        component: BeachReservationsDetailBeacharoundPayments,
        analytics: { name: ["Pagamenti"] }
    }].map(route => {
        return {
            ...route,
            path: `/beaches/:beachId/events/reservations/:reservationId${route.path}`,
            private: true,
            permissions: { memberships: ["MANAGEMENT", "BOOKING"] },
            analytics: { name: ["Spiagge", "Eventi", "Prenotazioni", ...(route.analytics?.name || [])] }
        }
    })
},{
    path: '/events/:eventId/tickets/add',
    component: TicketsAdd,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Ticket","Creazione"] }
},{
    path: '/events/:eventId/tickets/:ticketId',
    component: TicketsDetail,
    layout: MainLayout,
    analytics: { name: ["Ticket","Dettaglio"] }
},{
    path: '/events/add',
    component: EventsAdd,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Creazione"] }
},{
    path: '/events/:eventId',
    component: EventsDetail,
    layout: MainLayout,
    analytics: { name: ["Dettaglio"] }
},{
    path: '',
    component: BeachEvents,
    layout: MainLayout,
    routes: [{
        path: `/reservations`,
        component: ReservaionsList,
        exact: true,
        analytics: { name: ["Prenotazioni"] }
    }, {
        path: `/events`,
        component: EventsList,
        exact: true,
        analytics: { name: ["Lista"] }
    }, {
        path: `/settings`,
        component: Settings,
        exact: true,
        analytics: { name: ["Impostazioni"] }
    }].map(route => {
        return {
            ...route,
            path: `/beaches/:beachId/events${route.path}`,
            private: true,
            permissions: { memberships: ["MANAGEMENT", "BOOKING"] },
            analytics: { name: ["Spiagge", "Eventi", ...(route.analytics?.name || [])] }
        }
    })
}].map(route => {
    return {
        ...route,
        path: `/beaches/:beachId/events${route.path}`,
        private: true,
        permissions: { memberships: ["MANAGEMENT", "BOOKING"] },
        analytics: { name: ["Spiagge", "Eventi", ...(route.analytics?.name || [])] }
    }
})

