import React, { createContext, useContext, useEffect, useState } from 'react';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import { getPrinters, printPrinterReport } from '../api/printers';
import { getPrinterStatus } from '../api/printers/epson';
import { createReceipt, printReceiptFiscal, printReceiptFiscalVoid, printReceiptNonFiscal, printReceiptOrder, printReceiptsOrdersToPrint } from '../api/receipts';
import PopupError from '../components/Popups/PopupError';
import PrintersListPopup from '../components/Printers/PrintersListPopup';
import PreviewReceiptPopup from '../components/Receipts/PreviewReceiptPopup';
import { useActivities } from './activities';

const Context = createContext({
    lastPrinter: undefined,
    printers: [],
});

export function usePrinters() {
    return useContext(Context);
}

export function PrinterContext(props) {

    const [errorPopup, setErrorPopup] = useState()
    const [afterPreviewData, setAfterPreviewData] = useState()
    const [previewPopup, setPreviewPopup] = useState()
    const [previewReceipt, setPreviewReceipt] = useState()
    const [lastPrinterId, setLastPrinterId] = useState()
    const [choosePrinter, setChoosePrinter] = useState()
    const [connectedPrintersIds, setConnectedPrintersIds] = useState([])
    const [selectedPrinter, setSelectedPrinter] = useState()
    const { activeActivity } = useActivities();
    const activityId = activeActivity?._id;
    let deviceId = localStorage.getItem("baMainDeviceId");

    const { data: printers } = useQuery(["printers", activityId], () =>
        getPrinters({ activityId }),
        {
            enabled: !!activityId
        }
    );

    useQueries(
        !!printers?.length ? printers?.map(printer => ({
            queryKey: ["printer", activityId, printer._id], queryFn: () => getPrinterStatus({ printer, url: printer.url }), onSuccess: () => {
                return setConnectedPrintersIds(state => {
                    if (!state.includes(printer._id)) {
                        return [
                            ...state,
                            printer._id
                        ]
                    };
                    return state;
                })
            }
        })) : []
    );

    const connectedPrinters = !!printers?.length ? printers?.filter(({ _id }) => !!connectedPrintersIds.includes(_id)) : [];
    const connectedFiscalPrinters = connectedPrinters?.filter(({ fiscal }) => !!fiscal);

    function setLastPrinter(printerId) {
        return setLastPrinterId(printerId);
    }

    useEffect(() => {
        if (selectedPrinter && selectedPrinter?._id !== lastPrinterId) {
            return setLastPrinterId(selectedPrinter?._id)
        };
        return;
    }, [selectedPrinter, lastPrinterId])

    const lastPrinter = lastPrinterId && printers?.find(({ _id }) => _id === lastPrinterId);

    // FUNZIONI RELATIVE AGLI ORDINI
    // Se troviamo una stampante assegnata a questo dispositivio
    // Andiamo a cercare gli ordini da stampare
    // Creiamo i receipts di ogni ordine
    // Stampiamo il receipt
    // Salviamo il nuovo status del receipt

    const defaultPrinters = connectedPrinters?.filter(({ mainDevice }) => mainDevice === deviceId)

    const cache = useQueryClient();

    // useQuery(["receipts", "to-print", activityId], () =>
    //     printReceiptsOrdersToPrint({ activityId, printers: defaultPrinters }),
    //     {
    //         enabled: !!activityId && !!defaultPrinters?.length,
    //         refetchInterval: 1000 * 10,
    //         refetchOnWindowFocus: false,
    //         refetchOnMount: false
    //     }
    // );

    useQueries(
        defaultPrinters?.map(printer => ({
            queryKey: ["receipts", "to-print", activityId, printer._id],
            queryFn: () => printReceiptsOrdersToPrint({ activityId, printer }),
            enabled: !!activityId && !!defaultPrinters?.length,
            refetchInterval: 1000 * 10,
            refetchOnWindowFocus: false,
            refetchOnMount: false
        })) || []
    );

    const { mutateAsync: printOrderMutation, status: printOrderStatus, reset: printOrderReset } = useMutation(printReceiptOrder, {
        onSuccess: onPrintSuccess,
        onError: onPrintError
    });
    const { mutateAsync: printNonFiscalMutation, status: printNonFiscalStatus, reset: printNonFiscalReset } = useMutation(printReceiptNonFiscal, {
        onSuccess: onPrintSuccess,
        onError: onPrintError
    });
    const { mutateAsync: createReceiptMutation, status: createReceiptStatus, reset: createReceiptReset } = useMutation(createReceipt, {
        onSuccess: onPrintSuccessPreview,
        onError: onPrintError
    });
    const { mutateAsync: printFiscalMutation, status: printFiscalStatus, reset: printFiscalReset } = useMutation(printReceiptFiscal, {
        onSuccess: onPrintSuccess,
        onError: onPrintError
    });
    const { mutateAsync: printFiscalVoidMutation, status: printVoidStatus, reset: printFiscalVoidReset } = useMutation(printReceiptFiscalVoid, {
        onSuccess: onPrintSuccess,
        onError: onPrintError
    });
    const { mutateAsync: printReportMutation, status: printReportStatus, reset: printReportReset } = useMutation(printPrinterReport, {
        onSuccess: onPrintSuccess,
        onError: onPrintError
    });

    function onPrintSuccessPreview(data, variables) {
        setPreviewReceipt(data);
        return setPreviewPopup(variables)
    }

    function onPrintSuccess(data, variables) {

        if (choosePrinter?.type === "closure") {
            cache.invalidateQueries(["printers", activityId])
        }
        setChoosePrinter();
        printOrderReset();
        printNonFiscalReset();
        printReportReset();
        printFiscalReset();
        printFiscalVoidReset();
        createReceiptReset();
        setAfterPreviewData();
        setPreviewPopup();
        setPreviewReceipt();

        if (variables?.callback && typeof variables.callback === "function") {
            return variables?.callback(data);
        }
    }

    function onPrintError(error, variables) {
        setErrorPopup({
            message: error?.response?.data?.message || "Errore durante la stampa",
            callback: variables?.callback
        });
        printOrderReset();
        printNonFiscalReset();
        printReportReset();
        printFiscalReset();
        printFiscalVoidReset();
        createReceiptReset();
    }

    function printOrder({ orderId }, printer) {
        if (!printer && connectedPrinters?.length > 1) {
            return setChoosePrinter({ type: "order", data: { orderId } })
        }
        let printerToUse = printer || connectedPrinters?.[0];
        return printOrderMutation({ activityId, orderId, printer: printerToUse })
    }

    async function printNonFiscal(data, printer) {
        if (!printer && connectedPrinters?.length > 1) {
            return setChoosePrinter({ type: "non-fiscal", data })
        }
        let printerToUse = printer || connectedPrinters?.[0];
        return printNonFiscalMutation({ activityId, activityName: activeActivity?.name, printer: printerToUse, ...data })
    }

    async function printFiscal(data, printer) {
        if (!printer && connectedFiscalPrinters?.length > 1) {
            return setChoosePrinter({ type: "fiscal", data })
        }
        if (data.preview) {
            setAfterPreviewData({ data: { ...data, preview: false }, printer });
            return createReceiptMutation({ activityId, data: { transaction: data.transactionId, singleTransaction: data.singleTransaction, type: "FISCAL" }, ...data })
        }
        let printerToUse = printer || connectedFiscalPrinters?.[0];
        return printFiscalMutation({ activityId, printer: printerToUse, ...data })
    }

    async function printFiscalVoid(data) {
        try {
            await printFiscalVoidMutation({ activityId, printers: connectedPrinters, ...data })
        } catch (error) {
            throw error;
        }
    }

    async function printReceiptHandler() {
        return printFiscal(
            {
                ...afterPreviewData?.data,
                transactionId: undefined,
                receiptId: previewReceipt?._id
            },
            afterPreviewData?.printer
        )
    }

    function closePreviewPopupHandler() {
        if (afterPreviewData?.data?.callback && typeof afterPreviewData.data.callback === "function") {
            setPreviewPopup();
            return afterPreviewData.data.callback(previewReceipt);
        }
        return setPreviewPopup();
    }

    function closeErrorPopupHandler() {
        if (errorPopup?.callback && typeof errorPopup.callback === "function") {
            setErrorPopup();
            return errorPopup.callback();
        }
        return setErrorPopup();
    }

    function doDailyClosure(printer) {
        if (!printer && connectedPrinters?.length > 1) {
            return setChoosePrinter({ type: "closure" })
        }
        let printerToUse = printer || connectedPrinters?.[0];
        return printReportMutation({ activityId, printer: printerToUse });
    }

    function onPrintSelect(printer) {
        setSelectedPrinter(printer);
        if (choosePrinter?.type === "order") {
            return printOrder(choosePrinter.data, printer);
        }
        if (choosePrinter?.type === "non-fiscal") {
            setChoosePrinter();
            return printNonFiscal(choosePrinter.data, printer);
        }
        if (choosePrinter?.type === "fiscal") {
            setChoosePrinter();
            return printFiscal(choosePrinter.data, printer);
        }
        if (choosePrinter?.type === "closure") {
            setChoosePrinter();
            return doDailyClosure(choosePrinter.data, printer);
        }
    }

    return (
        <Context.Provider
            value={{
                printers,
                connectedPrinters,
                connectedFiscalPrinters,
                lastPrinter,
                setLastPrinter,
                hasPrinters: !!connectedPrinters?.length,
                hasFiscalPrinters: !!connectedFiscalPrinters?.length,
                selectedPrinter,
                setSelectedPrinter,
                printOrder,
                printNonFiscal,
                printFiscal,
                printFiscalVoid,
                doDailyClosure,
                printOrderStatus,
                printNonFiscalStatus,
                printFiscalStatus,
                printVoidStatus,
                printReportStatus,
                createReceiptStatus
            }}
        >
            {props.children}
            <PrintersListPopup
                visible={choosePrinter}
                setVisible={setChoosePrinter}
                setLastPrinterId={setLastPrinterId}
                onPrinterSelect={onPrintSelect}
                type={choosePrinter?.type}
                connectedOnly
            />
            <PreviewReceiptPopup
                visible={previewPopup}
                setVisible={closePreviewPopupHandler}
                confirmHandler={printReceiptHandler}
                confirmStatus={printFiscalStatus}
                activityId={activityId}
                receiptId={previewReceipt?._id}
            />
            <PopupError
                visible={errorPopup}
                setVisible={closeErrorPopupHandler}
                message={errorPopup?.message}
            />

        </Context.Provider>
    )
}