
export function getProductPriceString(prices, daysQuantity, relatedQuantity, relatedProductId, {noDefault} = {}) {
    const orderedPrices = prices.sort((a, b) => a.daysQuantity - b.daysQuantity);
    const defaultPrice = prices.find(price => !price.daysQuantity);
    let variatedPrices = orderedPrices.filter(price => daysQuantity >= 1 ? price.daysQuantity <= daysQuantity && price.daysQuantity !== 0.5 : price.daysQuantity === 0.5);
    const noBundleVariatedPrices = variatedPrices.filter(price => !price.bundle);
    let bundleVariatedPrices;

    if (relatedQuantity && relatedProductId) {
        const bundlePrices = variatedPrices.filter(price => (price.relatedQuantity === relatedQuantity) && (price.bundle === relatedProductId));

        if (bundlePrices?.length > 0) {
            bundleVariatedPrices = bundlePrices
        }
    }

    variatedPrices = bundleVariatedPrices || noBundleVariatedPrices;

    const variatedPrice = variatedPrices[variatedPrices.length - 1];
    let pricePerDay = variatedPrice || defaultPrice;
    if(
        // Usato per mostrare la tabella con i trattini dei prodotti collegati con prezzi personalizzati
        (!!noDefault && (!bundleVariatedPrices || bundleVariatedPrices?.length <= 0)) ||
        (daysQuantity === 0.5 && !variatedPrice)
    ) {
        return ["-", {...pricePerDay, value: 0}]
    }
    const daysForCalculations = daysQuantity <= 1 ? 1 : daysQuantity;
    let fixedPriceValue = Number(pricePerDay?.value) * daysForCalculations;
    let fixedDefaultPriceValue = Number(defaultPrice?.value) * daysForCalculations;
    if(!fixedPriceValue) {
        return ["-", {...pricePerDay, value: 0}]
    }
    let priceString = `${fixedPriceValue.toFixed(2)} €`

    if (variatedPrice && !relatedProductId && fixedDefaultPriceValue) {
        priceString = `<strike> ${fixedDefaultPriceValue.toFixed(2)} €</strike> ${priceString}`
    }

    return [priceString, pricePerDay];
}

export function isProductInBundle(relatedProductId, prices) {
    return prices?.some(price => price.bundle === relatedProductId);
}

export function getProductPricesString(prices, daysQuantity, relatedProductId, overrides, hasBundle, referencePrices) {

    if(referencePrices) {
        let referenceString = "";
        referencePrices.map(({quantity, price}) => {
            return referenceString = `${referenceString}${referenceString.length > 0 ? ' - ' : ''}x${quantity}: <strong>${hasBundle ? "" : "+"}${Number(price).toFixed(2)}€</strong>`;
        });
        return referenceString;
    }

    const orderedPrices = prices.sort((a, b) => a.daysQuantity - b.daysQuantity);
    const defaultPrice = prices.find(price => !price.daysQuantity);
    let variatedPrices = orderedPrices.filter(price => price.daysQuantity <= daysQuantity);
    const noBundleVariatedPrices = variatedPrices.filter(price => !price.bundle);
    let bundleVariatedPrices;
    let bundlePrices = variatedPrices.filter(price => (price.bundle === relatedProductId));

    if (relatedProductId && bundlePrices.length > 0) {

        if(overrides?.minOrder) {
            bundlePrices = bundlePrices.filter(price => price.relatedQuantity >= overrides.minOrder)
        }
        
        if(overrides?.maxOrder) {
            bundlePrices = bundlePrices.filter(price => price.relatedQuantity <= overrides.maxOrder)
        }

        if (bundlePrices?.length > 0) {
            bundleVariatedPrices = bundlePrices
        }
    }

    if (!bundleVariatedPrices) {
        let minOrder = overrides?.minOrder ? Number(overrides.minOrder) : 1;
        let maxOrder = overrides?.maxOrder ? Number(overrides.maxOrder) : 4;
        let priceToUse = variatedPrices?.length > 0 ? variatedPrices[variatedPrices.length - 1] : defaultPrice;
        let orderArray = Array.apply(null, Array(maxOrder)).map(function (x, i) {
            return {...priceToUse, relatedQuantity: i + 1, value: Number(priceToUse.value) * (i + 1)}; }
        ).filter(({relatedQuantity}) => relatedQuantity >= minOrder);
        bundleVariatedPrices = orderArray;
    }

    let string = "";

    variatedPrices = bundleVariatedPrices || noBundleVariatedPrices;
    
    variatedPrices = variatedPrices.filter(({daysQuantity: days, relatedQuantity: quantity, _id}) => {
        const priceWithSameQuantity = variatedPrices.find(variated => variated.relatedQuantity === quantity && variated._id !== _id);
        return !priceWithSameQuantity || priceWithSameQuantity.daysQuantity < days;
    });

    variatedPrices.sort((a, b) => a.relatedQuantity - b.relatedQuantity).map(variatedPrice => {
        let pricePerDay = variatedPrice || defaultPrice;
        let fixedPriceValue = Number(pricePerDay?.value) * daysQuantity;
        let priceString = `${fixedPriceValue.toFixed(2)} €`

        if(variatedPrice.relatedQuantity) {
            return string = `${string}${string.length > 0 ? ' - ' : ''}x${variatedPrice.relatedQuantity}: <strong>${hasBundle ? "" : "+"}${priceString}</strong>`;
        }
        return string = `${string}${string.length > 0 ? ' - ' : ''}prezzo base: ${priceString}`;
    })

    return string;
}

