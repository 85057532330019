import { lazy } from 'react';
import MainLayout from '../layouts/MainLayout/MainLayout';

const BeachDashboard = lazy(() => import('../pages/Beach/BeachDashboard'));
const BeachMap = lazy(() => import('../pages/Map/BeachMap'));
const BeachPlanner = lazy(() => import('../pages/Beach/Planner/Planner'));
const BeachMapSchedule = lazy(() => import('../pages/Map/BeachMapSchedule'));
const BeachMapConfirmSchedule = lazy(() => import('../pages/Map/BeachMapConfirmSchedule'));
const BeachMapGroupCreate = lazy(() => import('../pages/Map/BeachMapGroupCreate'));
const BeachMapAvailabilities = lazy(() => import('../pages/Map/BeachMapAvailabilities'));
const BeachMapManualSchedule = lazy(() => import('../pages/Map/BeachMapManualSchedule'));

const BeachSubscriptionsList = lazy(() => import('../pages/Beach/Subscriptions/BeachSubscriptionsList'));
const BeachSubscriptionsDetail = lazy(() => import('../pages/Beach/Subscriptions/BeachSubscriptionsDetail'));
const BeachStats = lazy(() => import('../pages/Beach/Stats/BeachStats'));
const BeachMembership = lazy(() => import('../pages/Beach/BeachMembership'));
// const BeachArrivalsPrint = lazy(() => import('../pages/Beach/BeachArrivalsPrint/BeachArrivalsPrint'));
const NewBeachReservationMap = lazy(() => import("../pages/Beach/Reservations/NewBeachReservationMap"));
const BeachDemoAlert = lazy(() => import("../pages/Beach/BeachDemoAlert"));

export default [{
	path: "/",
	component: BeachDashboard,
	layout: MainLayout,
	exact: true,
	analytics: { name: ["Dashboard"] },
}, {
	path: "/map",
	component: BeachMap,
	layout: MainLayout,
	layoutOptions: { noPadding: true },
	exact: true,
	permissions: { memberships: ["MANAGEMENT"], roles: ["admin", "bagnino"] },
	analytics: { name: ["Mappa"] },
}, {
	path: "/new-beach-reservation",
	component: NewBeachReservationMap,
	layout: MainLayout,
	exact: true,
	permissions: { memberships: ["MANAGEMENT"], roles: ["admin"] },
	analytics: { name: ["Nuova prenotazione"] },
}, {
	path: "/planner",
	component: BeachPlanner,
	layout: MainLayout,
	exact: true,
	permissions: { memberships: ["MANAGEMENT"], roles: ["admin"] },
	analytics: { name: ["Planner"] },
}, {
	path: "/map/schedule",
	component: BeachMapSchedule,
	layout: MainLayout,
	exact: true,
	permissions: { memberships: ["MANAGEMENT"], roles: ["admin", "bagnino"] },
	analytics: { name: ["Mappa", "Programmazione"] },
}, {
	path: "/map/confirm-schedule",
	component: BeachMapConfirmSchedule,
	layout: MainLayout,
	exact: true,
	permissions: { memberships: ["MANAGEMENT"], roles: ["admin", "bagnino"] },
	analytics: { name: ["Mappa", "Programmazione"] },
}, {
	path: "/map/group",
	component: BeachMapGroupCreate,
	layout: MainLayout,
	exact: true,
	permissions: { memberships: ["MANAGEMENT"], roles: ["admin", "bagnino"] },
	analytics: { name: ["Mappa", "Aggiunta gruppo"] },
}, {
	path: "/map/manual-schedule",
	component: BeachMapManualSchedule,
	layout: MainLayout,
	exact: true,
	permissions: { memberships: ["MANAGEMENT"], roles: ["admin", "bagnino"] },
	analytics: { name: ["Mappa", "Disponibilità"] },
}, {
	path: "/map/availabilities",
	component: BeachMapAvailabilities,
	layout: MainLayout,
	exact: true,
	permissions: { memberships: ["MANAGEMENT"], roles: ["admin", "bagnino"] },
	analytics: { name: ["Mappa", "Disponibilità"] },
}, {
// 	path: "/map/arrivals-print",
// 	component: BeachArrivalsPrint,
// 	layout: MainLayout,
// 	exact: true,
// 	permissions: { memberships: ["MANAGEMENT"], roles: ["admin", "bagnino"] },
// }, {
	path: "/stats",
	component: BeachStats,
	layout: MainLayout,
	exact: true,
	permissions: { memberships: ["MANAGEMENT"], roles: ["admin"] },
	analytics: { name: ["Statistiche"] },
}, {
	path: "/subscriptions",
	component: BeachSubscriptionsList,
	layout: MainLayout,
	exact: true,
	permissions: { memberships: ["BOOKING"] },
	analytics: { name: ["Abbonamenti"] },
}, {
	path: "/subscriptions/:subscriptionId",
	component: BeachSubscriptionsDetail,
	layout: MainLayout,
	exact: true,
	permissions: { memberships: ["BOOKING"] },
	analytics: { name: ["Abbonamenti", "Dettaglio"] },
}, {
	path: "/membership-update",
	component: BeachMembership,
	layout: MainLayout,
	exact: true,
	analytics: { name: ["Abbonamenti", "Aggiornamento"] },
}, {
	path: "/demo",
	component: BeachDemoAlert,
	layout: MainLayout,
	exact: true,
	analytics: { name: ["Spiaggia demo"] },
}].map((route) => {
	return {
		...route,
		routes: route.routes?.map(subRoute => {
			return {
				...subRoute,
				path: `/beaches/:beachId${subRoute.path}`
			}
		}),
		path: `/beaches/:beachId${route.path}`,
		private: true,
		analytics: { name: ["Spiagge", ...(route.analytics?.name || [])] },
	}
})
