import { lazy } from 'react';

import MainLayout from '../layouts/MainLayout/MainLayout';

const BeachPaymentsList = lazy(() => import('../pages/Beach/BeachPayments/BeachPaymentsList.js'));
const BeachPaymentDetail = lazy(() => import('../pages/Beach/BeachPayments/Detail/BeachPaymentDetail.js'));

export default [
    {
        path: '',
        component: BeachPaymentsList,
        layout: MainLayout,
        exact: true,
        analytics: { name: ["Pagamenti"] }
    },
    {
        path: '/:paymentId',
        component: BeachPaymentDetail,
        layout: MainLayout,
        exact: true,
        analytics: { name: ["Dettaglio pagamento"] }
    }
].map((route) => ({
    ...route,
    path: `/beaches/:beachId/payments${route.path}`,
    private: true,
    permissions: { memberships: ["MANAGEMENT"] },
    analytics: {
        name: ["Spiagge", "Pagamenti", ...(route.analytics?.name || [])],
    }
}));