import { lazy } from 'react';

import MainLayout from "../layouts/MainLayout/MainLayout.js";

const Notifications = lazy(() => import('../pages/Notifications/Notifications.js'));

export default [
  {
    path: "/beaches/:beachId/notifications",
    component: Notifications,
    layout: MainLayout,
    exact: true,
    private: true,
    analytics: { name: ["Notifications"] }
  }
];