const subtitle = "Scopri le soluzioni personalizzate di Beacharound.";
const description = "Scopri le soluzioni che Beacharound ha pensato per te e fai crescere il tuo business ora.";
const paragraph = "In realtà lo strumento di Beacharound è molto di più: è il tuo migliore assistente! Concentrati su ciò che è veramente importante ed affida al tuo assistente gli aspetti gestionali. Puoi tenere tutto sotto controllo da qualsiasi dispositivo in tempo reale. Ricevi acconti in pochi click anche da clienti che ti contattano via telefono o email. Inviandoli un semplice modulo eviterai spiacevoli sorprese e riceverai subito il denaro. Stampa una lista aggiornata dei posti che si liberano e di quelli che si occupano da condividere con i tuoi collaboratori e migliora il servizio ai tuoi clienti con tempi di attesa ridotti. Questi sono solo alcuni degli aspetti che contraddistinguono il nostro strumento.";

export const arrayA = [
    "Gestionale spiaggia",
    "Gestionale lido",
    "Gestionale bagno",
    "Gestionale stabilimento",
    "Software spiaggia",
    "Software lido",
    "Software bagno",
    "Software stabilimento",
    "Tool spiaggia",
    "Tool lido",
    "Tool bagno",
    "Tool stabilimento",
    "Strumento gestione"
]

export const arrayB = [
    "prenotazione ombrellone",
    "prenotazione online",
    "booking ombrellone",
    "booking online",
    "partenze arrivi",
]

export const arrayC = [
    "demo gratis",
    "in cloud",
    "facile e veloce",
    "prova gratuita",
    "tutto incluso",
    "telefono o tablet",
    "senza attivazione",
    "statistiche avanzate",
    "pagamento anticipato",
]

export function getMassiveUrl() {
    let urls = [];

    for (let indexC = 0; indexC < arrayC.length; indexC++) {
        const c = arrayC[indexC];
        
        for (let indexB = 0; indexB < arrayB.length; indexB++) {
            const b = arrayB[indexB];

            for (let indexA = 0; indexA < arrayA.length; indexA++) {
                const a = arrayA[indexA];
                urls.push({
                    path: `/${a} ${b} ${c}`.replace(/\s/g, "-").toLocaleLowerCase(),
                    seo: {
                        title: `${a} ${b} ${c}`,
                        subtitle: `${a} ${b} ${c}. ${subtitle}`,
                        description: `${a} ${b} ${c}. ${description}`,
                        paragraph: `${a} ${b} ${c}. ${paragraph}`,
                    }
                })
            }
        }
    }

    return urls;
}