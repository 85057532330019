import { lazy } from 'react';
import MainLayout from '../layouts/MainLayout/MainLayout';

const EntrancesList = lazy(() => import('../pages/Beach/Entrances/EntrancesList'));
const EntrancesScanner = lazy(() => import('../pages/Beach/Entrances/EntrancesScanner'));
const EntrancesConfirm = lazy(() => import('../pages/Beach/Entrances/EntrancesConfirm'));
const EntrancesCode = lazy(() => import('../pages/Beach/Entrances/EntrancesCode'));
const EntrancesDetail = lazy(() => import('../pages/Beach/Entrances/EntrancesDetail'));

export default [{
    path: "/entrances",
    component: EntrancesList,
    layout: MainLayout,
    exact: true,
    permissions: { memberships: ["MANAGEMENT"] },
    analytics: { name: ["Lista accessi"] },
},{
    path: "/entrances/scanner",
    component: EntrancesScanner,
    layout: MainLayout,
    exact: true,
    permissions: { memberships: ["MANAGEMENT"] },
    analytics: { name: ["Scansione accesso"] },
},{
    path: "/entrances/scanner/code",
    component: EntrancesCode,
    layout: MainLayout,
    exact: true,
    permissions: { memberships: ["MANAGEMENT"] },
    analytics: { name: ["Accesso con codice"] },
},{
    path: "/entrances/:entranceId",
    component: EntrancesDetail,
    layout: MainLayout,
    exact: true,
    permissions: { memberships: ["MANAGEMENT"] },
    analytics: { name: ["Dettaglio accesso"] },
},{
    path: "/entrances/:reservationId/confirm",
    component: EntrancesConfirm,
    layout: MainLayout,
    exact: true,
    permissions: { memberships: ["MANAGEMENT"] },
    analytics: { name: ["Conferma scansione accesso"] },
}].map((route) => {
    return {
        ...route,
        routes: route.routes?.map(subRoute => {
            return {
                ...subRoute,
                path: `/beaches/:beachId${subRoute.path}`
            }
        }),
        path: `/beaches/:beachId${route.path}`,
        private: true,
        analytics: { name: ["Spiagge", ...(route.analytics?.name || [])] },
    }
})
