import { duplicateReservations } from '../reservations/beaches';
import axios from '../_defaults/v2/user';

export async function getBeachesMaps({ activityId }) {
    try {
        const result = await axios.get(`/maps/${activityId}`);
        return result?.data;
    } catch (error) {
        console.log(error.result);
        throw error;
    }
}

export async function getBeachMapPublic({ slug, params }) {
    try {
        const response = await axios.get(`/beaches/${slug}/map`, { params });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function getBeachesMap({ activityId, beachMapId, params }) {
    try {
        const result = await axios.get(`/maps/${activityId}/${beachMapId}`, { params });
        return result?.data;
    } catch (error) {
        console.log(error.result);
        throw error;
    }
}

export async function getBeachesMapActive({activityId, params}) {
    try {
        const result = await axios.get(`/maps/${activityId}/active`, {params});
        return result?.data;
    } catch(error) {
        console.log(error.result);
        throw error;
    }
}

export async function createBeachesMap({activityId, data}) {
    try {
        const result = await axios.post(`/maps/${activityId}`, data);
        return result?.data;
    } catch (error) {
        console.log(error.result);
        throw error;
    }
}

export async function updateBeachesMap({ activityId, beachMapId, data }) {
    try {
        const result = await axios.post(`/maps/${activityId}/${beachMapId}`, data);
        return result?.data;
    } catch (error) {
        console.log(error.result);
        throw error;
    }
}

export async function duplicateBeachesMap({ activityId, mapId, data }) {
    try {
        const result = await axios.post(`/maps/${activityId}/${mapId}/duplicate`, data);
        return result?.data;
    } catch (error) {
        console.log(error.result);
        throw error;
    }
}

export async function duplicateBeachesMapAndReservations({
    activityId,
    mapId,
    data,
    checkDuplicateReservations,
    reservationsFilters
}) {
    try {
        const result = await duplicateBeachesMap({ activityId, mapId, data });
        if (checkDuplicateReservations) {
            await duplicateReservations({
                activityId, data: {
                    mapId: result?._id
                },
                params: reservationsFilters
            });
        }

        return result?.data;
    } catch (error) {
        console.log(error.result);
        throw error;
    }
}

export async function createPlacedProductFromMap({ activityId, mapId, data }) {
    try {
        const result = await axios.post(`/maps/${activityId}/${mapId}/placedproducts`, data);
        return result?.data;
    } catch (error) {
        console.log(error.result);
        throw error;
    }
}

export async function removePlacedProductFromMap({ activityId, mapId, placedProductId }) {
    try {
        const result = await axios.delete(`/maps/${activityId}/${mapId}/placedproducts/${placedProductId}`);
        return result?.data;
    } catch (error) {
        console.log(error.result);
        throw error;
    }
}