import { lazy } from 'react';

import MainLayout from '../layouts/MainLayout/MainLayout';

const DeactivationsList = lazy(() => import('../pages/Beach/Deactivations/DeactivationsList.js'));
const DeactivationsDetail = lazy(() => import('../pages/Beach/Deactivations/DeactivationsDetail.js'));

export default [{
    path: "",
    component: DeactivationsList,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Lista"] }
}, {
    path: "/:deactivationId",
    component: DeactivationsDetail,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Dettaglio"] }
}].map((route) => ({
    ...route,
    path: `/beaches/:beachId/deactivations${route.path}`,
    private: true,
    permissions: { memberships: ["MANAGEMENT"] },
    analytics: {
        name: ["Disattivazioni", ...(route.analytics?.name || [])]
    }
}));