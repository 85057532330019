import React, { useEffect } from 'react';
import { useLocation, Route } from 'react-router-dom';
import { useQuery } from 'react-query';

import { MainLayoutContainer, MainLayoutContent } from '@beacharound/beacharound-ui';

import { getUser } from 'api/user';
import { getOrganizations } from 'api/organizations';

import MainHeaderBusiness from 'components/MainHeader';

export default function MainLayout({ children, options, private: routePrivate }) {

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const { data: user } = useQuery('user', getUser);
    const { data: organizations } = useQuery("organizations", getOrganizations);

    const activities = organizations?.reduce((accumulator, { activities }) => {
        if (!!activities && Array.isArray(activities)) {
            return [
                ...accumulator,
                ...activities?.map(data => ({
                    ...data,
                    ...data.activity,
                    type: data?.type,
                    beachType: data?.activity?.type,
                    image: data?.activity?.cover
                }))
            ];
        }
        return accumulator;
    }, []) || [];

    return (
        <Route>
            <MainLayoutContainer>
                <MainHeaderBusiness user={user} activities={activities} />

                <MainLayoutContent noPadding={options?.noPadding} large={options?.large}>
                    {children}
                </MainLayoutContent>
            </MainLayoutContainer>
        </Route>
    );
}