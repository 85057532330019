import React, { useState, Fragment } from 'react';
import Cookies from 'js-cookie';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { ButtonsRow, CheckboxInput, Column, Divider, MainButton, MainForm, MainFormContent, NormalPopup, ScrollableField, SectionTitle, TitleContainer } from '@beacharound/beacharound-ui';

import { checks as privacyChecks, title as privacyTitle, condition as privacyCondition, version as privacyVersion } from '../../data/conditions/privacy';
import { checks as termsChecks, title as termsTitle, condition as termsCondition, version as termsVersion } from '../../data/conditions/terms';
import { checks as managementChecks, title as managementTitle, condition as managementCondition, version as managementVersion } from '../../data/conditions/management';
import { checks as bookingChecks, title as bookingTitle, condition as bookingCondition, version as bookingVersion } from '../../data/conditions/booking';

import { getMissingUserConsent, saveUserConsent } from '../../api/user';
import { useMemberships } from '../../context/memberships';
import { useConsents } from '../../context/usersConsents';
import { useActivities } from '../../context/activities';

export default function UsersConsentsCheck() {

    const [conditionsPopup, setConditionsPopup] = useState(true);
    const { isBooking, isManagement } = useMemberships();
    const { areVerified, setAreVerified } = useConsents();
    const userId = Cookies.get("user-id");
    const { beachId, hotelId } = useParams();

    let consents = [
        ...privacyChecks.map(({ name }) => ({
            type: name,
            version: privacyVersion,
            site: "business",
            reference: userId,
            referenceType: "users"
        })),
        ...termsChecks.map(({ name }) => ({
            type: name,
            version: termsVersion,
            site: "business",
            reference: userId,
            referenceType: "users"
        })),
    ];

    if ((isBooking || isManagement) && !hotelId) {
        consents = [
            ...consents,
            ...bookingChecks.map(({ name }) => ({
                type: name,
                version: bookingVersion,
                site: "business",
                reference: hotelId || beachId,
                referenceType: hotelId ? "hotels" : "beaches"
            })),
        ]
    }

    if (isManagement && !hotelId) {
        consents = [
            ...consents,
            ...managementChecks.map(({ name }) => ({
                type: name,
                version: managementVersion,
                site: "business",
                reference: hotelId || beachId,
                referenceType: hotelId ? "hotels" : "beaches"
            })),
        ]
    }
    const cache = useQueryClient();
    const { isDemo } = useActivities();
    const { data } = useQuery(["userConsents", userId, hotelId || beachId, consents], () => getMissingUserConsent({
        data: consents
    }), {
        enabled: areVerified !== (hotelId || beachId) && !isDemo,
        retry: 1,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onError: () => setAreVerified(hotelId || beachId)
    })
    const { mutateAsync: saveCondition, status } = useMutation(saveUserConsent, {
        onSuccess: () => setAreVerified(hotelId || beachId)
    });

    let constraints = {};
    let conditionsToAsk = [];
    let conditionsToSave = [];
    let conditionsToShow = [];

    if (data?.length > 0) {
        conditionsToAsk = consents.filter(consent => data?.some(({ type }) => type === consent.type))
    }

    let askPrivacy = conditionsToAsk.some(({ type }) => privacyChecks.some(({ name }) => name === type));
    let askTerms = conditionsToAsk.some(({ type }) => termsChecks.some(({ name }) => name === type));
    let askBooking = conditionsToAsk.some(({ type }) => bookingChecks.some(({ name }) => name === type));
    let askManagement = conditionsToAsk.some(({ type }) => managementChecks.some(({ name }) => name === type));

    if (askPrivacy) {
        constraints = {
            ...constraints,
            ...privacyChecks.reduce((acc, con) => ({ ...acc, [con.name]: { presence: true } }), {})
        }
        conditionsToShow = [
            ...conditionsToShow,
            {
                title: privacyTitle,
                content: privacyCondition,
                checks: privacyChecks
            }
        ]
        conditionsToSave = [
            ...conditionsToSave,
            ...privacyChecks.map(check => ({
                title: privacyTitle,
                content: privacyCondition,
                version: privacyVersion,
                preview: check.label,
                type: check.name,
                reference: userId,
                referenceType: "users"
            }))
        ]
    }

    if (askTerms) {
        constraints = {
            ...constraints,
            ...termsChecks.reduce((acc, con) => ({ ...acc, [con.name]: { presence: true } }), {})
        }
        conditionsToShow = [
            ...conditionsToShow,
            {
                title: termsTitle,
                content: termsCondition,
                checks: termsChecks
            }
        ]
        conditionsToSave = [
            ...conditionsToSave,
            ...termsChecks.map(check => ({
                title: termsTitle,
                content: termsCondition,
                version: termsVersion,
                preview: check.label,
                type: check.name,
                reference: userId,
                referenceType: "users"
            }))
        ]
    }

    if (askBooking) {
        constraints = {
            ...constraints,
            ...bookingChecks.reduce((acc, con) => ({ ...acc, [con.name]: { presence: true } }), {})
        }
        conditionsToShow = [
            ...conditionsToShow,
            {
                title: bookingTitle,
                content: bookingCondition,
                checks: bookingChecks
            }
        ]
        conditionsToSave = [
            ...conditionsToSave,
            ...bookingChecks.map(check => ({
                title: bookingTitle,
                content: bookingCondition,
                version: bookingVersion,
                preview: check.label,
                type: check.name,
                reference: beachId || hotelId,
                referenceType: beachId ? "beaches" : "hotels"
            }))
        ]
    }

    if (askManagement) {
        constraints = {
            ...constraints,
            ...managementChecks.reduce((acc, con) => ({ ...acc, [con.name]: { presence: true } }), {})
        }
        conditionsToShow = [
            ...conditionsToShow,
            {
                title: managementTitle,
                content: managementCondition,
                checks: managementChecks
            }
        ]
        conditionsToSave = [
            ...conditionsToSave,
            ...managementChecks.map(check => ({
                title: managementTitle,
                content: managementCondition,
                version: managementVersion,
                preview: check.label,
                type: check.name,
                reference: beachId || hotelId,
                referenceType: beachId ? "beaches" : "hotels"
            }))
        ]
    }

    async function acceptConditionHandler(data) {
        try {

            await Promise.all(conditionsToSave.map(async condition => {
                await saveCondition({
                    data: {
                        ...condition
                    }
                })
            }))

            cache.invalidateQueries(["userConsents", userId, hotelId || beachId, consents])
            cache.setQueryData(["userConsents", userId, hotelId || beachId, consents], [])

            setConditionsPopup();

        } catch (error) {
            console.log(error)
        }
    }

    return (

        <Fragment>
            {/*  CONDIZIONI  */}
            <NormalPopup
                visible={conditionsPopup && conditionsToSave.length > 0 && !isDemo}
                title="Abbiamo aggiornato le nostre condizioni di utilizzo"
                subtitle="Ti preghiamo di leggere attentamente e dare il tuo consenso per poter procedere con il normale utilizzo di Beacharound Business"
            >
                <MainForm onSubmit={acceptConditionHandler} constraints={constraints}>
                    <MainFormContent>

                        {conditionsToShow.map(({ title, content, checks }) => (
                            <Column>
                                <TitleContainer>
                                    <SectionTitle text={title} />
                                </TitleContainer>

                                <ScrollableField text={content} />

                                {checks?.map((check, index) => <CheckboxInput
                                    key={index}
                                    name={check.name}
                                    label={check.label}
                                />)}

                                <Divider />
                            </Column>
                        ))}

                    </MainFormContent>

                    <ButtonsRow fill>
                        <MainButton submit text="Procedi" status={status} />
                    </ButtonsRow>
                </MainForm>
            </NormalPopup>

        </Fragment>
    )
}