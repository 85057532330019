import axios from '../_defaults/v2/user';
import { getRepeatedReservationsDates } from '../../helpers/reservations';
import { createTransaction } from 'api/transactions';
import { createCoupon } from 'api/coupons';

export async function getReservations({ activityId, params }) {
    try {
        const response = await axios.get(
            `/reservations/${activityId}`,
            { params }
        );

        if (response?.data) {
            return response.data;
        }
    } catch (error) {
        if (error?.response?.status === 404) {
            return [];
        }

        throw error;
    }
}

export async function getNearestReservations({ activityId, params }) {
    try {
        const response = await axios.get(
            `/reservations/${activityId}/nearest`,
            { params }
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error?.response?.status === 404) {
            return [];
        }

        throw error;
    }
}

export async function getReservation({ activityId, reservationId }) {
    try {
        const response = await axios.get(
            `/reservations/${activityId}/${reservationId}`
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error?.response?.status === 404) {
            return {};
        }

        throw error;
    }
}

export async function createReservation({ activityId, data }) {
    try {
        const response = await axios.post(
            `/reservations/${activityId}`,
            data
        );

        if (response.status === 206) {
            return response;
        }

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function createAndConfirmReservation({ activityId, data }) {
    try {
        const response = await createReservation({ activityId, data });

        if (response.status === 206) {
            return response;
        }

        await confirmReservation({ activityId, reservationId: response?._id });

        if (response) {
            return response;
        }
    } catch (error) {
        throw error;
    }
}

export async function createRepeatedReservation({ activityId, data, daysOfTheWeek }) {
    try {
        const dates = getRepeatedReservationsDates({
            daysOfTheWeek,
            checkin: data.checkin,
            checkout: data.checkout
        });

        if (!dates?.length) {
            const error = { response: { status: 404 } };
            throw error;
        }

        const reservationsGroup = await createReseravationsGroup({
            activityId,
            data: {
                name: "Prenotazioni ripetute"
            }
        });

        let reservationsToCheck = [];

        await Promise.all(dates.map(async ({ checkin, checkout }) => {
            try {
                await createReservation({
                    activityId,
                    data: {
                        group: reservationsGroup?._id,
                        checkin,
                        checkout,
                        durationType: data?.durationType,
                        placedProduct: data?.placedProduct
                    }
                });
            } catch (error) {
                if (error?.response?.status === 409) {
                    reservationsToCheck = [
                        ...reservationsToCheck,
                        {
                            _id: error?.response?.data?._id,
                            checkin: error?.response?.data?.checkin,
                            status: 409
                        }
                    ];
                    return;
                }
                throw error;
            }
        }));

        return {
            group: reservationsGroup,
            reservations: reservationsToCheck?.sort((a, b) => new Date(a?.checkin).getTime() - new Date(b?.checkin).getTime())
        };
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export async function createAndConfirmRepeatedReservation({ activityId, data, daysOfTheWeek }) {
    try {
        const dates = getRepeatedReservationsDates({
            daysOfTheWeek,
            checkin: data.checkin,
            checkout: data.checkout
        });

        if (!dates?.length) {
            const error = { response: { status: 404 } };
            throw error;
        }

        const reservationsGroup = await createReseravationsGroup({
            activityId,
            data: {
                name: "Prenotazioni ripetute"
            }
        });

        let reservationsToCheck = [];
        await Promise.all(dates.map(async ({ checkin, checkout }) => {
            let reservation;
            try {
                reservation = await createReservation({
                    activityId,
                    data: {
                        group: reservationsGroup?._id,
                        checkin,
                        checkout,
                        durationType: data?.durationType,
                        placedProduct: data?.placedProduct
                    }
                });
            } catch (error) {
                if (error?.response?.status === 409) {
                    reservationsToCheck = [
                        ...reservationsToCheck,
                        {
                            _id: error?.response?.data?._id,
                            checkin: error?.response?.data?.checkin,
                            status: 409
                        }
                    ];
                    return;
                }
                throw error;
            }
            await confirmReservation({ activityId, reservationId: reservation?._id });
        }));

        return {
            group: reservationsGroup,
            reservations: reservationsToCheck?.sort((a, b) => new Date(a?.checkin).getTime() - new Date(b?.checkin).getTime())
        };
    } catch (error) {
        console.log(error);
    }
}

export async function createReservationSeasonal({ activityId, data }) {
    try {
        const response = await axios.post(
            `/business-beaches/${activityId}/reservations/seasonal`,
            data
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function updateReservation({ activityId, reservationId, data }) {
    try {
        const response = await axios.post(
            `/reservations/${activityId}/${reservationId}`,
            data
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function updateReservationDates({ activityId, reservationId, data }) {
    try {
        const response = await axios.patch(
            `/reservations/${activityId}/${reservationId}/dates`,
            data
        );

        if (response?.data) {
            return {
                ...response.data,
                status: response?.status
            };
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function updateReservationPrice({ activityId, reservationId, data }) {
    try {
        const response = await axios.patch(
            `/reservations/${activityId}/${reservationId}/price`,
            data
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function updateReservationVouchersPrice({ activityId, reservationId, data }) {
    try {
        const response = await axios.patch(
            `/reservations/${activityId}/${reservationId}/prices/vouchers`,
            data
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function confirmReservation({ activityId, reservationId, data }) {
    try {
        const response = await axios.post(
            `/reservations/${activityId}/${reservationId}/confirm`,
            data
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function confirmReservations({ activityId, reservations = [] }) {
    try {
        await Promise.all(reservations.map(async (reservationId) => {
            return await confirmReservation({
                activityId,
                reservationId
            });
        }));
    } catch (error) {
        console.log(error.response);
    }
}

export async function confirmReservationAndCreateTransaction({ activityId, reservationId, transaction, data }) {
    try {
        const reservation = await confirmReservation({
            activityId,
            reservationId,
            data
        });

        const transactionData = await createTransaction({
            activityId,
            data: {
                status: "ACCEPTED",
                paidBy: reservation?.customers?.[0]?.customer,
                paidByType: "customers",
                ...transaction,
                subjects: [{
                    subject: reservationId,
                    subjectType: "reservations",
                    amount: transaction?.amount
                }]
            }
        });

        return { reservation, transaction: transactionData };
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function confirmReservationAndCreatePayment({ activityId, reservationId, payment, data }) {
    try {
        const reservation = await confirmReservation({
            activityId,
            reservationId,
            data
        });

        const paymentData = await askForPayment({
            activityId,
            reservationId,
            data: payment
        });

        return { reservation, payment: paymentData };
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function cancelReservation({ activityId, reservationId }) {
    try {
        const response = await axios.post(
            `/reservations/${activityId}/${reservationId}/cancel`,
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function cancelSelectedReservation({
    activityId,
    reservation,
    options
}) {

    let responses = {};

    const isActiveReservation = !["CHECKINGOUT", "CANCELED"].includes(reservation?.status);
    const canRemoveTransactions = !!isActiveReservation && options?.removeTransactions && reservation?.unpaid < reservation?.price;
    const canGenerateCoupon = !!isActiveReservation && options?.generateCoupon && reservation?.price > 0;
    const canCancelReservation = !!isActiveReservation;
    const canRemoveReservation = options?.removeReservation;

    if (canRemoveTransactions) {
        const amount = reservation?.price - reservation?.unpaid;
        try {
            const response = await createTransaction({
                activityId,
                data: {
                    method: "cash",
                    status: "ACCEPTED",
                    amount,
                    type: "REFOUND",
                    subjects: [{
                        subject: reservation?._id,
                        subjectType: "reservations",
                        amount
                    }]
                }
            });

            if (response?.data) {
                responses = {
                    ...responses,
                    removeTransactions: response.data
                };
            }
        } catch (error) {
            console.error({
                type: "removeTransactions",
                code: error?.response?.code,
                message: error?.response?.message,
                reservationId: reservation?._id
            })
            return {
                type: "removeTransactions",
                code: error?.response?.code,
                message: error?.response?.message,
                reservationId: reservation?._id
            };
        }
    }

    if (canCancelReservation) {
        try {
            // const response = await axios.post(
            //     `/reservations/${activityId}/${reservation?._id}/cancel`,
            // );

            const response = await cancelReservation({
                activityId,
                reservationId: reservation?._id
            });

            if (response?.data) {
                responses = {
                    ...responses,
                    cancelReservation: response.data
                };
            }
        } catch (error) {
            console.error({
                type: "cancelReservation",
                code: error?.response?.code,
                message: error?.response?.message,
                reservationId: reservation?._id
            })
            return {
                type: "cancelReservation",
                code: error?.response?.code,
                message: error?.response?.message,
                reservationId: reservation?._id
            };
        }
    }

    if (canGenerateCoupon) {
        try {

            const response = await createCoupon({
                activityId,
                data: {
                    type: 'cancel',
                    discountType: 'fixed',
                    reservation: reservation?._id,
                    value: reservation?.price * options?.couponPercentage * 0.01
                }
            });
            if (response?.data) {
                responses = {
                    ...responses,
                    generateCoupon: response.data
                };
            }
        } catch (error) {
            console.error({
                type: "generateCoupon",
                code: error?.response?.code,
                message: error?.response?.message,
                reservationId: reservation?._id
            });
            return {
                type: "generateCoupon",
                code: error?.response?.code,
                message: error?.response?.message,
                reservationId: reservation?._id
            };
        }
    }

    if (canRemoveReservation) {
        try {
            const response = await deleteReservation({
                activityId,
                reservationId: reservation?._id
            });
            if (response?.data) {
                responses = {
                    ...responses,
                    removeReservation: response.data
                };
            }
        } catch (error) {
            return {
                type: "removeReservation",
                code: error?.response?.code,
                message: error?.response?.message,
                reservationId: reservation?._id
            };
        }
    }

    return responses;
}

export async function cancelSelectedReservations({
    activityId,
    selectedReservations = [],
    options,
    setCounterState
}) {

    let counter = 0;
    try {
        const data = await Promise.all(selectedReservations.map(async (reservation) => {

            let res = await cancelSelectedReservation({
                activityId,
                reservation,
                options
            });

            if (typeof setCounterState === "function") {
                counter++;
                setCounterState(counter);
            }

            return res;
        }));

        return data ?? [];
    } catch (error) {
        console.error({ counter, error });
        return {
            counter: counter + 1,
            error
        };
    }
}

export async function copyReservation({
    activityId,
    newReservation
}) {

    try {
        let reservation = await createReservation({
            activityId,
            data: {
                ...newReservation?.reservationPayload,
                ...(newReservation?.isSeasonal ? { ...newReservation?.bundlesPayload?.[0] } : {}),
                controlsToSkip: ["checkinCheckoutApproved"]
            }
        }).catch(error => {
            const status = error?.status || error?.response?.status;
            if (status === 403) {
                // La'API restituisce 403 (per lo stagionale) ma il tipo di risposta suggerisce che sitratti di un 409 (conflitto tra due prenotazioni)
                return {
                    status: 409,
                    message: "La prenotazione non è stata copiata perché la postazione risulta già occupata.",
                    data: {
                        origin: newReservation?.reservationPayload?.origin,
                    }
                };
            }
            if (status === 409) {
                return error?.response?.data;
            }

            if (status !== 410) throw error;
        });

        if (!!reservation?._id) {
            if (!!newReservation?.customersPayload && !!newReservation?.customersPayload?.length) {
                await Promise.all(newReservation?.customersPayload?.map(async (customerId) => {
                    return await addCustomerToReservation({
                        activityId,
                        reservationId: reservation?._id,
                        data: {
                            _id: customerId
                        }
                    });
                }));
            }
            if (!!newReservation?.hotelId) {
                await addHotelToReservation({
                    activityId,
                    reservationId: reservation?._id,
                    data: {
                        _id: newReservation?.hotelId
                    }
                });
            }
            if (!newReservation?.isSeasonal && !!newReservation?.bundlesPayload?.length) {
                for (const bundle of newReservation?.bundlesPayload) {
                    await addProductToReservation({
                        activityId,
                        reservationId: reservation?._id,
                        data: {
                            ...bundle,
                            controlsToSkip: ["checkinCheckoutApproved"]
                        }
                    });
                }
            }

            reservation = await confirmReservation({
                activityId,
                reservationId: reservation?._id
            });
        }
        return reservation ?? {};

    } catch (error) {
        const status = error?.status || error?.response?.status;
        console.log("[ERROR: %s]", status);
        console.log(error);
    }
}

export async function getReservationsToCopy({
    activityId,
    reservations = []
}) {
    let data = [];
    try {
        data = await Promise.all(reservations?.map(async reservationId =>
            await getReservation({ activityId, reservationId })
        ));

        return data || [];

    } catch (error) {
        console.log(error);
    }
}

export async function copyReservations({
    activityId,
    reservations = [],
    setCounterState
}) {
    let data = [];
    let counter = 0;
    try {
        for (const newReservation of reservations) {

            const response = await copyReservation({
                activityId,
                newReservation
            });

            if (!!response) {
                data.push(response);
            }
            if (typeof setCounterState === "function") {
                counter++;
                setCounterState(counter);
            }
        }

        return data;

    } catch (error) {
        console.error({ counter, error });
        return {
            counter: counter + 1,
            error
        };
    }
}

export async function deleteReservation({ activityId, reservationId }) {
    try {
        const response = await axios.delete(
            `/reservations/${activityId}/${reservationId}`,
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function deleteReservations({ activityId, params }) {
    try {
        const response = await axios.delete(
            `/reservations/${activityId}`,
            { params }
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function confirmPendingReservation({ activityId, reservationId, data }) {
    try {
        const response = await axios.post(
            `/reservations/${activityId}/${reservationId}/pending/confirm`,
            { ...(data || {}) }
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function cancelPendingReservation({ activityId, reservationId }) {
    try {
        const response = await axios.delete(
            `/reservations/${activityId}/${reservationId}/pending/cancel`,
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function createReservationTransaction({ activityId, reservationId, data }) {
    try {
        const response = await axios.post(`/business-beaches/${activityId}/reservations/${reservationId}/transactions`, { ...data });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function sendReservation({ activityId, reservationId, data }) {
    try {
        const response = await axios.post(`/reservations/${activityId}/${reservationId}/send`, (data || {}));

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function duplicateReservations({ activityId, data, params }) {
    try {
        const response = await axios.post(`/reservations/${activityId}/duplicate`, data, {
            params
        });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function getReservationTransactions({ activityId, reservationId }) {
    try {
        const response = await axios.get(
            `/business-transactions/${activityId}/subjects/${reservationId}`
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error?.response?.status === 404) {
            return [];
        }
        throw error;
    }
}

export async function askForPayment({ activityId, reservationId, data }) {
    try {
        const response = await axios.post(
            `/beacharound-payments/${activityId}/reservations/${reservationId}`,
            { ...data }
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function askForGroupPayment({ activityId, groupId, data }) {
    try {
        const response = await axios.post(
            `/beacharound-payments/${activityId}/groups/${groupId}`,
            { ...data }
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function getReservationBeacharoundPayments({ activityId, reservationId }) {
    try {
        const response = await axios.get(
            `/beacharound-payments/${activityId}/reservations/${reservationId}`
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error?.response?.status === 404) {
            return [];
        }
        throw error;
    }
}

export async function getBeacharoundPayment({ activityId, paymentId }) {
    try {
        const response = await axios.get(
            `/beacharound-payments/${activityId}/${paymentId}`
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function getBeacharoundPayments({ activityId, params }) {
    try {
        const response = await axios.get(
            `/beacharound-payments/${activityId}`,
            { params }
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function updateBeacharoundPayment({ activityId, paymentId, data }) {
    try {
        const response = await axios.post(
            `/beacharound-payments/${activityId}/${paymentId}`,
            data
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function sendBeacharoundPayment({ activityId, paymentId, data }) {
    try {
        const response = await axios.post(
            `/beacharound-payments/${activityId}/${paymentId}/send`,
            data
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function addProductToReservation({ activityId, reservationId, data }) {
    try {
        const response = await axios.post(
            `/reservations/${activityId}/${reservationId}/products`,
            data
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function setReservationProductQuantity({ activityId, reservationId, productId, data }) {
    try {
        const response = await axios.post(
            `/reservations/${activityId}/${reservationId}/products/${productId}/quantity`,
            data
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function removeProductFromReservation({ activityId, reservationId, productId }) {
    try {
        const response = await axios.delete(
            `/reservations/${activityId}/${reservationId}/products/${productId}`
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function replaceProductToReservation({ activityId, reservationId, productToRemove, productToAdd }) {
    try {
        await removeProductFromReservation({ activityId, reservationId, productId: productToRemove });
        const data = await addProductToReservation({ activityId, reservationId, data: productToAdd });
        return data;
    } catch (error) {
        throw error;
    }
}

export async function addCustomerToReservation({ activityId, reservationId, data, groupId }) {
    try {
        const response = await axios.post(
            `/reservations/${activityId}/${reservationId}/customers`,
            data,
            groupId && { params: { groupId } }
        );

        if (response?.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function removeCustomerFromReservation({ activityId, reservationId, customerId, groupId }) {
    try {
        const response = await axios.delete(
            `/reservations/${activityId}/${reservationId}/customers/${customerId}`,
            groupId && { params: { groupId } }
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function addHotelToReservation({ activityId, reservationId, data, groupId }) {
    try {
        const response = await axios.post(
            `/reservations/${activityId}/${reservationId}/hotels`,
            data,
            groupId && { params: { groupId } }
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function removeHotelFromReservation({ activityId, reservationId, hotelId }) {
    try {
        const response = await axios.delete(
            `/reservations/${activityId}/${reservationId}/hotels/${hotelId}`
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function addDetailToReservation({ activityId, reservationId, data }) {
    try {
        const response = await axios.post(
            `/reservations/${activityId}/${reservationId}/details`,
            data
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function removeDetailFromReservation({ activityId, reservationId, detailKey }) {
    try {
        const response = await axios.delete(
            `/reservations/${activityId}/${reservationId}/details/${detailKey}`
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function addCouponToReservation({ activityId, reservationId, couponId }) {
    try {
        const response = await axios.post(
            `/reservations/${activityId}/${reservationId}/coupons/${couponId}`
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function removeCouponFromReservation({ activityId, reservationId, couponId }) {
    try {
        const response = await axios.delete(
            `/reservations/${activityId}/${reservationId}/coupons/${couponId}`
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function addIncrementToReservation({ activityId, reservationId, data }) {
    try {
        const response = await axios.post(
            `/reservations/${activityId}/${reservationId}/increments`,
            data
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function removeIncrementFromReservation({ activityId, reservationId, incrementId }) {
    try {
        const response = await axios.delete(
            `/reservations/${activityId}/${reservationId}/increments/${incrementId}`
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

export async function createReseravationsGroup({ activityId, data }) {
    try {
        const response = await axios.post(
            `/reservations-groups/${activityId}`,
            data || {}
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function getReservationGroups({ activityId, reservationId, params }) {
    try {
        const response = await axios.get(
            `/reservations-groups/${activityId}`,
            { params: { reservationId, ...params } }
        );

        if (response?.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function getReservationsGroup({ activityId, groupId }) {
    try {
        const response = await axios.get(
            `/reservations-groups/${activityId}/${groupId}`
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function updateReservationGroup({ activityId, groupId, data }) {
    try {
        const response = await axios.post(
            `/reservations-groups/${activityId}/${groupId}`,
            data || {}
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function addReservationToGroup({ activityId, groupId, data }) {
    try {
        const response = await axios.post(
            `/reservations-groups/${activityId}/${groupId}/reservations`,
            data
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function removeReservationFromGroup({ activityId, groupId, reservationId }) {
    try {
        const response = await axios.delete(
            `/reservations-groups/${activityId}/${groupId}/reservations/${reservationId}`
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function createReservationsWithGroup({ activityId, checkin, checkout, placedProducts, data }) {
    try {
        const group = await createReseravationsGroup({ activityId, data });
        await Promise.all(placedProducts.map(async ({ _id }) => {
            await createReservation({
                activityId,
                data: {
                    group: group._id,
                    checkin,
                    checkout,
                    placedProduct: _id
                }
            })
        }));
        return group;
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function createMultipleReservationsFromSchedules({ activityId, data }) {
    try {
        let groupId = data?.group;
        if (!groupId) {
            const newGroup = await createReseravationsGroup({ activityId, data: { name: data?.newGroupName } });
            groupId = newGroup?._id;
        }
        await Promise.all(data?.schedules.map(async (schedule) => {
            await createReservation({
                activityId,
                data: {
                    group: groupId,
                    checkin: schedule[0]?.date,
                    checkout: schedule[schedule.length - 1]?.date,
                    schedules: schedule
                }
            })
        }));
        return groupId;
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function createReservationsAndToGroup({ activityId, checkin, checkout, placedProducts, seasonalProduct, groupId }) {
    try {
        const promises = await Promise.all(placedProducts.map(async ({ _id, seasonalProduct: customSeasonalProduct, placedCabin, checkin: customCheckin, checkout: customCheckout }) => {
            await createReservation({
                activityId,
                data: {
                    group: groupId,
                    checkin: customCheckin || checkin,
                    checkout: customCheckout || checkout,
                    placedProduct: _id,
                    product: seasonalProduct || customSeasonalProduct,
                    placedCabin
                }
            })
        }));
        return promises;
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function confirmMultipleReservations({ activityId, reservations }) {
    try {
        const result = await Promise.all(reservations.map(async ({ _id }) => {
            await confirmReservation({
                activityId,
                reservationId: _id
            })
        }));
        return result;
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function createRentalReservationWithSlots({ activityId, reservation, product, confirm }) {
    try {
        const created = await createReservation({
            activityId,
            data: reservation
        });
        if (!!confirm) {
            await confirmReservation({
                activityId,
                reservationId: created._id
            });
        }
        await addProductToReservation({
            activityId,
            reservationId: created._id,
            data: product
        });

        return created;
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function updateReservationDeliveryTime({ activityId, reservationId, data }) {
    try {
        const response = await axios.patch(`/reservations/${activityId}/${reservationId}/deliverytime`, data);

        if (response?.data) {
            return response.data;
        }
    } catch (error) {
        if (error?.response?.status === 404) {
            return undefined;
        }
        throw error;
    }
}