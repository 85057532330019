import React from 'react';

import { ButtonsRow, MainButton, NormalPopup, Paragraph, TitleContainer } from '@beacharound/beacharound-ui';

export default function PopupError({
    visible,
    setVisible,
    message,
    title,
    onClose
}) {

    function closePopup() {
        if (!!onClose && typeof onClose === "function") {
            onClose();
        }
        setVisible();
    }

    return (
        <NormalPopup
            visible={visible}
            setVisible={closePopup}
            title={title || "Ops... si è verificato un errore"}
        >
            <TitleContainer>
                <Paragraph text={(message && typeof message === 'string') ? message : "Ops... purtroppo si è verificato un errore!"} />
            </TitleContainer>

            <ButtonsRow center>
                <MainButton border text="Ho capito" action={closePopup} />
            </ButtonsRow>
        </NormalPopup>
    );
}