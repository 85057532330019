import { lazy } from 'react';

import MainLayout from '../layouts/MainLayout/MainLayout';

const CouponsList = lazy(() => import('../pages/Beach/Coupons/CouponsList.js'));
const CouponsDetail = lazy(() => import('../pages/Beach/Coupons/CouponsDetail.js'));

export default [{
    path: "",
    component: CouponsList,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Lista"] }
}, {
    path: "/:couponId",
    component: CouponsDetail,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Dettaglio"] }
}].map((route) => ({
    ...route,
    path: `/beaches/:beachId/coupons${route.path}`,
    private: true,
    permissions: { memberships: ["MANAGEMENT"] },
    analytics: {
        name: ["Coupons", ...(route.analytics?.name || [])]
    }
}));