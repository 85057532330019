import { lazy } from "react";
import EmptyLayout from "../layouts/EmptyLayout/EmptyLayout";
const SitemapsGeneral = lazy(() => import("../pages/Sitemaps/General"));

const generalRoutes = [{
    path: "/mappa-del-sito",
    component: SitemapsGeneral,
    layout: EmptyLayout,
    exact: true,
    analytics: {
        name: ["Sitemap"],
    },
}]

export default generalRoutes