import React, { useEffect } from 'react';
// import Cookies from 'js-cookie';
import { useLocation, Route } from 'react-router-dom';

import { MainLayoutContainer, MainLayoutContent } from '@beacharound/beacharound-ui';
import FrontendHeader from '../../components/FrontendHeader';
import MainFooter from '../../components/MainFooter';

export default function FrontendLayout({
    children,
    options
}) {

    const location = useLocation();
    // const bannerPopupClosed = Cookies.get("banner-popup-closed");  

    // const [ bannerFiera, setBannerFiera ] = useState(!bannerPopupClosed)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location]);

    // function closeBanner() {
    //     Cookies.set("banner-popup-closed", true);  
    //     return setBannerFiera()
    // }

    return (
        <Route>
            <MainLayoutContainer>
                <FrontendHeader noBack={options?.noBack}/>

                <MainLayoutContent noPadding={options?.noPadding}>
                    {children}
                </MainLayoutContent>

                <MainFooter noPadding={options?.noPadding}/>

                {/* <BannerPopup
                    visible={bannerFiera}
                    setVisible={closeBanner}
                    image={'/images/frontend/fiera-sun-beach-outdoor-style-beacharound.jpg'}
                    title="Ci troverai in fiera SUN Beach & Outdoor Style"
                    buttonText="Scopri l'evento"
                    buttonLink="https://www.sunexpo.it/"
                /> */}

            </MainLayoutContainer>
        </Route>
    )
}
