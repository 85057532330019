import React from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { LoadingContainer } from '@beacharound/beacharound-ui';

import { getActiveMemberships } from '../../api/memberships';
import { MembershipsContext } from '../../context/memberships';

export default function CheckPermissions({ permissions, children }) {

    const { beachId, hotelId } = useParams();

    const history = useHistory();
    const activityId = beachId || hotelId;
    const activityType = hotelId ? 'hotels' : 'beaches';

    const { data: memberships, isLoading } = useQuery(["memberships", activityId], () =>
        getActiveMemberships({ activityId: activityId }),
        {
            enabled: !!activityId,
            onError: (error) => {
                if (error?.response?.status === 500) {
                    history.push(`/user/login`);
                }
            }
        }
    );

    const hasMembership = !permissions?.memberships || permissions?.memberships?.some((pack) =>
        ["ACTIVE", "TRIAL"].includes(memberships?.[pack])
    );

    function isBooking() {
        return ["ACTIVE", "TRIAL"].includes(memberships?.["BOOKING"]) && !["ACTIVE", "TRIAL"].includes(memberships?.["MANAGEMENT"]);
    }

    function isManagement() {
        return ["ACTIVE", "TRIAL"].includes(memberships?.["MANAGEMENT"]);
    }

    if (!hasMembership) {
        return (
            <LoadingContainer isLoading={isLoading}>
                <Redirect to={`/${activityType}/${beachId}/membership-update`} />
            </LoadingContainer>
        );
    }

    return (
        <MembershipsContext.Provider value={!isLoading && { memberships, isBooking: isBooking(), isManagement: isManagement() }}>
            {children}
        </MembershipsContext.Provider>
    );
}