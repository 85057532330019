import format from "date-fns/format";
import { create as createXml } from "xmlbuilder2";
import { getTransactionsMethodString } from '../../../helpers/reservations';

export function getPrinterStatusXml() {

    const xml = createXml({ version: "1.0", encoding: "UTF-8" });
    const envelope = xml.ele({
        "s:Envelope": {
            "@xmlns:s": "http://schemas.xmlsoap.org/soap/envelope/"
        }
    });
    const body = envelope.ele("s:Body");
    body.ele({
        "epos-print": {
            "@xmlns": "http://www.epson-pos.com/schemas/2011/03/epos-print"
        }
    });
    return xml.toString();
}

export function getReservationProductsXml({ data }) {

    const xml = createXml({ version: "1.0", encoding: "UTF-8" });
    const envelope = xml.ele({
        "s:Envelope": {
            "@xmlns:s": "http://schemas.xmlsoap.org/soap/envelope/"
        }
    });
    const body = envelope.ele("s:Body");
    const print = body.ele("printerNonFiscal")
    const nonFiscal = print.ele("beginNonFiscal");
    const { products, prices, price } = data;

    for (const product of products) {
        let name = product.name;
        let subname;

        if (["voucher", "normal", "seasonal"].includes(product.type)) {
            subname = product.mainItem?.name || product.name;

            if (product.relatedProducts) {
                subname = `${subname} con ${product.relatedProducts.map(({ name, quantity }) => `${quantity} ${name}, `)}`
            }
        }
        nonFiscal.ele({
            "printNormal": {
                "@description": name
            }
        });
        if (subname) {
            nonFiscal.ele({
                "printNormal": {
                    "@description": name,
                    "@data": subname
                }
            });
        }
        if (product.type !== "voucher") {
            nonFiscal.ele({
                "printNormal": {
                    "@description": name,
                    "@data": `prezzo: ${product.price?.toFixed(2)} €`
                }
            });
        }
        nonFiscal.ele({
            "printNormal": {
                "@description": ""
            }
        });
    }

    if (prices?.coupons > 0) {
        nonFiscal.ele({
            "printNormal": {
                "@description": "Sconto applicato"
            }
        });
        nonFiscal.ele({
            "printNormal": {
                "@description": "Sconto applicato",
                "@data": `- ${prices?.coupons?.toFixed(2)} €`
            }
        });
        nonFiscal.ele({
            "printNormal": {
                "@description": ""
            }
        });
    }

    if (price) {
        nonFiscal.ele({
            "printNormal": {
                "@description": prices?.custom ? "Prezzo personalizzato" : "Prezzo prenotazione",
                "@font": 4
            }
        });
        nonFiscal.ele({
            "printNormal": {
                "@description": prices?.custom ? "Prezzo personalizzato" : "Prezzo prenotazione",
                "@data": `${price?.toFixed(2)} €`,
                "@font": 4
            }
        });
    }

    nonFiscal.ele("endNonFiscal");

    return xml.toString();
}

export function getOrderReceiptXml({ receipt }) {

    const xml = createXml({ version: "1.0", encoding: "UTF-8" });
    const envelope = xml.ele({
        "s:Envelope": {
            "@xmlns:s": "http://schemas.xmlsoap.org/soap/envelope/"
        }
    });
    const body = envelope.ele("s:Body");
    const print = body.ele({
        "epos-print": {
            "@xmlns": "http://www.epson-pos.com/schemas/2011/03/epos-print"
        }
    });

    const { products, foodNote, foodPlacedProduct, foodPaymentType, foodStatus, amount } = receipt;

    if (foodPlacedProduct?.number) {
        print.ele({
            "text": {
                "@font": "font_a",
                "@height": 2,
                "@align": "center"
            }
        }).txt(`Posto: ${foodPlacedProduct?.number}&#10;`);
        print.ele("text").txt(`&#10;`);
    }

    let fixedProducts = products?.reduce((acc, item) => {
        const addedProductIndex = acc.findIndex(({ product }) => product === item.product);
        if (addedProductIndex >= 0) {
            acc[addedProductIndex] = {
                ...acc[addedProductIndex],
                quantity: acc[addedProductIndex].quantity + item.quantity
            }
        } else {
            acc = [
                ...acc,
                item
            ]
        }
        return acc;
    }, [])

    for (const product of fixedProducts) {
        let name = product.name;
        let quantity = product.quantity;
        print.ele({
            "text": {
                "@font": "font_a",
                "@align": "left",
                "@height": 1
            }
        }).txt(`${name}&#10;`);
        if (quantity) {
            print.ele({
                "text": {
                    "@align": "left",
                    "@font": "font_b"
                }
            }).txt(`quantità: ${quantity}&#10;`);
        }
    }
    if (foodNote) {
        print.ele({
            "text": {
                "@align": "left"
            }
        }).txt(`&#10;`);
        print.ele({
            "text": {
                "@font": "font_a",
                "@height": 2
            }
        }).txt("RICHIESTA&#10;");
        print.ele({
            "text": {
                "@font": "font_a",
                "@height": 1
            }
        }).txt(`${foodNote}&#10;`);
    }

    if (foodStatus !== "PAID" && foodPaymentType) {
        print.ele("text").txt(`&#10;`);
        let paymentTypeString = getTransactionsMethodString(foodPaymentType);
        print.ele({
            "text": {
                "@font": "font_a",
                "@height": 2,
                "@align": "center"
            }
        }).txt("DA PAGARE&#10;");
        print.ele({
            "text": {
                "@font": "font_a",
                "@height": 1,
                "@align": "center"
            }
        }).txt(`${paymentTypeString}&#10;`);
    }

    if (foodStatus === "PAID") {
        print.ele("text").txt(`&#10;`);
        print.ele({
            "text": {
                "@font": "font_a",
                "@height": 2,
                "@align": "center"
            }
        }).txt(`PAGATO&#10;`);
        print.ele("text").txt(`&#10;`);
    }

    if (!!amount) {
        print.ele("text").txt(`&#10;`);
        print.ele({
            "text": {
                "@font": "font_a",
                "@height": 2,
                "@align": "center"
            }
        }).txt(`IMPORTO: ${amount.toFixed(2)}`);
        print.ele("text").txt(`&#10;`);
    }

    print.ele({
        "cut": {
            "@type": "feed"
        }
    });

    return xml.toString();
}

export function getNonFiscalReceiptXml({ activityName, receipt }) {
    const xml = createXml({ version: "1.0", encoding: "UTF-8" });
    const envelope = xml.ele({
        "s:Envelope": {
            "@xmlns:s": "http://schemas.xmlsoap.org/soap/envelope/"
        }
    });
    const body = envelope.ele("s:Body");
    const print = body.ele({
        "epos-print": {
            "@xmlns": "http://www.epson-pos.com/schemas/2011/03/epos-print"
        }
    });
    const { products, amount, reservations, transactionType } = receipt;

    const textline = (text, align) => print.ele({
        "text": {
            "@font": "font_b",
            "@align": align || "left",
            "@height": "2",
            "@width": "2"
        }
    }).txt(`${text || " "}&#10;`);
    const textlineSmall = (text, align, breakLineBefore) => print.ele({
        "text": {
            "@font": "font_a",
            "@align": align || "left",
            "@height": "1",
            "@width": "1"
        }
    }).txt(`${breakLineBefore ? " &#10;" : ""}${text || " "}&#10;`);

    // print.ele({
    //     logo: {
    //         '@key1': '48',
    //         '@key2': '48',
    //         '@align': 'center'
    //     }
    // }); // logo NV salvato in memoria

    textlineSmall(`${format(new Date(), "dd/MM/yyyy")}`);

    let unpaid = 0;
    if (!!reservations?.length) {
        if (["food"].includes(reservations?.[0]?.type)) {
            textline("Ordine");
        } else {
            textline(`Prenotazion${reservations?.length > 1 ? "i" : "e"}`);
        }
        if (!!activityName) {
            textline();
            textlineSmall(activityName);
        }

        for (const reservation of reservations) {
            unpaid += reservation?.unpaid;

            textline(reservations?.length > 1 ? "--------------------" : "");
            if (reservation?.placedProduct?.number) textline(`Posto: ${reservation?.placedProduct?.number}`);

            textline("Check-in: " + format(new Date(reservation.checkin), 'dd/MM/yyyy'));
            textline("Check-out:" + format(new Date(reservation.checkout), 'dd/MM/yyyy'));

            if (!!reservation?.customers?.length) {
                textlineSmall(`Client${reservation?.customers?.length > 1 ? "i" : "e"}:`, null, true);
                textline(reservation?.customers?.map(c => `${c?.lastName} ${c?.firstName}`, "")?.join(", "))
            };
            textlineSmall("Prodotti:", null, true);
            const reservationProducts = products?.filter(prod => prod?.reservation === reservation?.reservation);
            for (const product of reservationProducts) {
                const name = product.description || product.name;
                const quantity = product.quantity;
                const price = product.amount;
                textline(`${quantity}x ${name}: ${price.toFixed(2)}€`);
            }
        }
    } else {
        textline("Prodotti");
        textline();
        for (const product of products) {
            const name = product.description || product.name;
            const quantity = product.quantity;
            const price = product.amount;
            textline(`${quantity}x ${name}: ${price.toFixed(2)}€`);
        }
    }

    if (amount) {
        textline("--------------------");

        let amountString = "";
        if (!!transactionType && transactionType === "DOWN") {
            const totalPrice = reservations?.reduce((acc, res) => acc + res?.price, 0);
            const totalPriceString = `TOTALE:${totalPrice?.toFixed(2)}€`;
            textline(`TOTALE:${" ".repeat(20 - totalPriceString?.length)}${totalPrice?.toFixed(2)}€`);
            amountString = `CAPARRA:${amount?.toFixed(2)}€`;
            textline(`CAPARRA:${" ".repeat(20 - amountString?.length)}${amount?.toFixed(2)}€`);
        } else {
            amountString = `TOTALE:${amount?.toFixed(2)}€`;
            textline(`TOTALE:${" ".repeat(20 - amountString?.length)}${amount?.toFixed(2)}€`);
        }
        if (!!reservations?.length && !!transactionType && transactionType === "DOWN") {
            const unpaidString = `SALDO:${unpaid?.toFixed(2)}€`;
            textline(`SALDO:${" ".repeat(20 - unpaidString?.length)}${unpaid?.toFixed(2)}€`);
        }
    }

    textline();

    print.ele({
        "cut": {
            "@type": "feed"
        }
    });

    return xml.toString();
}