import React from 'react';

import { MainContainer, MainTitle, TitleContainer, SubTitle, ColumnContainer, Column, Paragraph, LoadingContainer, HeadSeo, Breadcrumbs, FullImageContainer, SectionTitle, Divider, Video, SimpleImage } from '@beacharound/beacharound-ui';
import { isMobile } from '@beacharound/beacharound-ui-helpers';

import { useQuery } from 'react-query';
import { getArticle } from '../../api/blog';
import { Link } from 'react-router-dom';

export default function BlogDetail({match, location}) {

    const { tutorialSlug } = match.params;
    let videoHeight = 390;

    if (isMobile()) {
        videoHeight = 220;
    }

    const { data, isLoading } = useQuery(["blog", tutorialSlug],
        () => getArticle({ slug: tutorialSlug, params: { params: {}} })
    )

    const stepSeo = data?.steps?.map((step) => {
        return {
            "@type": "HowToStep",
            "name": step.name,
            "url": `${window.location.href}#${step.index}`, 
            "itemListElement": [{
                "@type": "HowToDirection",
                "text": step.description
            }],
            "image": {
                "@type": "ImageObject",
                "url": step.cover
            }
        }
    })

    return (

        <LoadingContainer isLoading={isLoading}>

            <FullImageContainer image={data?.cover} overlay></FullImageContainer>

            <MainContainer>
                <HeadSeo
                    title={[data?.name]}
                    description={data?.description || data?.subtitle}
                    image={data?.cover}
                    type="HowTo"
                    mainEntityOfPage
                    additions={{
                        author: 'Beacharound',
                        publisher: {
                            "@type": "Organization",
                            "name": "Beacharound",
                            "logo": {
                                "@type": "ImageObject",
                                "url": `${process.env.REACT_APP_IMAGES_PATH_ROOT}/logo.png`
                            }
                        },
                        datePublished: data?.publishedAt,
                        dateModified: data?.updatedAt,
                        name: data?.name,
                        step: stepSeo,
                        totalTime: `PT${data?.duration}M`,
                        supply: data?.supply && data.supply?.map((item) => {
                            return {
                                "@type": "HowToSupply",
                                "name": item.name
                            }
                        }),
                        tool: data?.tools && data.tools?.map((item) => {
                            return {
                                "@type": "HowToTool",
                                "name": item.name
                            }
                        })
                    }}
                />

                <TitleContainer>
                    <Breadcrumbs fullUrl={match.url} names={['tutorial', data?.name]} linkComponent={Link}/>
                </TitleContainer>
                <TitleContainer marginBottom>
                    <MainTitle text={data?.name} component="h1"/>
                    <SubTitle text={data?.subtitle} component="h3"/>
                    {data?.duration && <SubTitle text={`Durata: ${data?.duration} min`} color="grey"/> }
                </TitleContainer>
                
                {data?.video?.url &&
                    <Video url={data?.video?.url} height={videoHeight}/>
                }

                <TitleContainer marginBottom>
                    <Paragraph text={data?.description}/>
                </TitleContainer>

                <Divider/>

                {data?.steps?.map((step, index) => {
                    return (
                        <ColumnContainer lowMargin key={index} htmlId={step.index}>
                            <Column>
                                <TitleContainer>
                                    <SectionTitle text={`${step.index + 1}. ${step?.name}`} component="h2"/>
                                    <SubTitle text={step?.subtitle} component="h4"/>
                                </TitleContainer>

                                <SimpleImage image={step?.cover} noMargin/>

                                <TitleContainer>
                                    <Paragraph text={step?.description}/>
                                </TitleContainer>

                                {(index+1) !== data.steps.length && <Divider/> }
                            </Column>
                        </ColumnContainer>
                    )
                })}
            </MainContainer>
        </LoadingContainer>
    )
}