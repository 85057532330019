import { lazy } from 'react';
import MainLayout from '../layouts/MainLayout/MainLayout';

// Catalogo
const BeachCatalog = lazy(() => import('../pages/Beach/Catalog/BeachCatalog'));

const UmbrellasList = lazy(() => import('../pages/Beach/Catalog/Umbrellas/UmbrellasList'));
const UmbrellasDetail = lazy(() => import('../pages/Beach/Catalog/Umbrellas/UmbrellasDetail'));
const UmbrellasAdd = lazy(() => import('../pages/Beach/Catalog/Umbrellas/UmbrellasAdd'));

const BundlesAdd = lazy(() => import('../pages/Beach/Catalog/Bundles/BundlesAdd'));
const BundlesDetail = lazy(() => import('../pages/Beach/Catalog/Bundles/BundlesDetail'));
const BundlesList = lazy(() => import('../pages/Beach/Catalog/Bundles/BundlesList'));

const PitchesList = lazy(() => import('../pages/Beach/Catalog/Pitches/PitchesList'));
const PitchesAdd = lazy(() => import('../pages/Beach/Catalog/Pitches/PitchesAdd'));
const PitchesDetail = lazy(() => import('../pages/Beach/Catalog/Pitches/PitchesDetail'));

const DeckChairsList = lazy(() => import('../pages/Beach/Catalog/Deckchairs/DeckchairsList'));
const DeckchairsDetail = lazy(() => import('../pages/Beach/Catalog/Deckchairs/DeckchairsDetail'));
const DeckchairsAdd = lazy(() => import('../pages/Beach/Catalog/Deckchairs/DeckchairsAdd'));

const ParkingsList = lazy(() => import('../pages/Beach/Catalog/Parkings/ParkingsList'));
const ParkingsDetail = lazy(() => import('../pages/Beach/Catalog/Parkings/ParkingsDetail'));
const ParkingsAdd = lazy(() => import('../pages/Beach/Catalog/Parkings/ParkingsAdd'));

const ServicesList = lazy(() => import('../pages/Beach/Catalog/Services/ServicesList'));
const ServicesDetail = lazy(() => import('../pages/Beach/Catalog/Services/ServicesDetail'));
const ServicesAdd = lazy(() => import('../pages/Beach/Catalog/Services/ServicesAdd'));
const SupplementsList = lazy(() => import('../pages/Beach/Catalog/Supplements/SupplementsList'));
const SupplementsDetail = lazy(() => import('../pages/Beach/Catalog/Supplements/SupplementsDetail'));
const SupplementsAdd = lazy(() => import('../pages/Beach/Catalog/Supplements/SupplementsAdd'));
const AnimalsList = lazy(() => import('../pages/Beach/Catalog/Animals/AnimalsList'));
const AnimalsDetail = lazy(() => import('../pages/Beach/Catalog/Animals/AnimalsDetail'));
const AnimalsAdd = lazy(() => import('../pages/Beach/Catalog/Animals/AnimalsAdd'));
const SeasonalsDetail = lazy(() => import('../pages/Beach/Catalog/Seasonals/SeasonalsDetail'));
const SeasonalsAdd = lazy(() => import('../pages/Beach/Catalog/Seasonals/SeasonalsAdd'));
const SeasonalsList = lazy(() => import('../pages/Beach/Catalog/Seasonals/SeasonalsList'));
const CabinsAdd = lazy(() => import('../pages/Beach/Catalog/Cabins/CabinsAdd'));
const CabinsDetail = lazy(() => import('../pages/Beach/Catalog/Cabins/CabinsDetail'));
const CabinsList = lazy(() => import('../pages/Beach/Catalog/Cabins/CabinsList'));
const AvailabilitiesAdd = lazy(() => import('../pages/Beach/Catalog/Availabilities/AvailabilitiesAdd'));
const AvailabilitiesDetail = lazy(() => import('../pages/Beach/Catalog/Availabilities/AvailabilitiesDetail'));
const AvailabilitiesList = lazy(() => import('../pages/Beach/Catalog/Availabilities/AvailabilitiesList'));
const PricelistsList = lazy(() => import('../pages/Beach/Catalog/Pricelists/PricelistsList'));
const PricelistsListAdd = lazy(() => import('../pages/Beach/Catalog/Pricelists/PricelistsAdd'));
const PricelistsListDetail = lazy(() => import('../pages/Beach/Catalog/Pricelists/PricelistsDetail'));

export default [{
    path: '/deckchairs/add',
    component: DeckchairsAdd,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Lettini", "Creazione"] }
}, {
    path: '/deckchairs/:productId',
    component: DeckchairsDetail,
    layout: MainLayout,
    analytics: { name: ["Lettini", "Dettaglio"] }
}, {
    path: '/umbrellas/add',
    component: UmbrellasAdd,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Ombrelloni", "Creazione"] }
}, {
    path: '/umbrellas/:productId',
    component: UmbrellasDetail,
    layout: MainLayout,
    analytics: { name: ["Ombrelloni", "Dettaglio"] }
}, {
    path: '/pricelists/add',
    component: PricelistsListAdd,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Listini", "Creazione"] }
}, {
    path: '/pricelists/:pricelistId',
    component: PricelistsListDetail,
    layout: MainLayout,
    analytics: { name: ["Listini", "Dettaglio"] }
}, {
    path: `/bundles/add`,
    component: BundlesAdd,
    layout: MainLayout,
    analytics: { name: ["Pacchetti standard", "Creazione"] }
}, {
    path: `/bundles/:productId`,
    component: BundlesDetail,
    layout: MainLayout,
    analytics: { name: ["Pacchetti standard", "Dettaglio"] }
}, {
    path: '/availabilities/add',
    component: AvailabilitiesAdd,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Disponibiltà", "Creazione"] }
}, {
    path: '/availabilities/:productId',
    component: AvailabilitiesDetail,
    layout: MainLayout,
    analytics: { name: ["Disponibiltà", "Dettaglio"] }
}, {
    path: '/cabins/add',
    component: CabinsAdd,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Cabine", "Creazione"] }
}, {
    path: '/cabins/:productId',
    component: CabinsDetail,
    layout: MainLayout,
    analytics: { name: ["Cabine", "Dettaglio"] }
}, {
    path: '/seasonals/add',
    component: SeasonalsAdd,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Pacchetti stagionali", "Creazione"] }
}, {
    path: '/seasonals/:productId',
    component: SeasonalsDetail,
    layout: MainLayout,
    analytics: { name: ["Pacchetti stagionali", "Dettaglio"] }
}, {
    path: '/pitches/add',
    component: PitchesAdd,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Piazzole", "Creazione"] }
}, {
    path: '/pitches/:productId',
    component: PitchesDetail,
    layout: MainLayout,
    analytics: { name: ["Piazzole", "Dettaglio"] }
}, {
    path: '/parkings/add',
    component: ParkingsAdd,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Parcheggi", "Creazione"] }
}, {
    path: '/parkings/:productId',
    component: ParkingsDetail,
    layout: MainLayout,
    analytics: { name: ["Parcheggi", "Dettaglio"] }
}, {
    path: '/services/add',
    component: ServicesAdd,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Servizi", "Creazione"] }
}, {
    path: '/services/:productId',
    component: ServicesDetail,
    layout: MainLayout,
    analytics: { name: ["Servizi", "Dettaglio"] }
}, {
    path: '/supplements/add',
    component: SupplementsAdd,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Supplementi", "Creazione"] }
}, {
    path: '/supplements/:productId',
    component: SupplementsDetail,
    layout: MainLayout,
    analytics: { name: ["Supplementi", "Dettaglio"] }
}, {
    path: '/animals/add',
    component: AnimalsAdd,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Animali", "Creazione"] }
}, {
    path: '/animals/:productId',
    component: AnimalsDetail,
    layout: MainLayout,
    analytics: { name: ["Animali", "Dettaglio"] }
}, {
    path: '',
    component: BeachCatalog,
    layout: MainLayout,
    routes: [{
        path: `/deckchairs`,
        component: DeckChairsList,
        exact: true,
        analytics: { name: ["Lettini"] }
    }, {
        path: `/availabilities`,
        component: AvailabilitiesList,
        exact: true,
        analytics: { name: ["Disponibilita"] }
    }, {
        path: `/umbrellas`,
        component: UmbrellasList,
        exact: true,
        analytics: { name: ["Ombrelloni"] }
    }, {
        path: `/pricelists`,
        component: PricelistsList,
        exact: true,
        analytics: { name: ["Listini"] }
    }, {
        path: `/cabins`,
        component: CabinsList,
        exact: true,
        analytics: { name: ["Cabine"] }
    }, {
        path: `/bundles`,
        component: BundlesList,
        exact: true,
        analytics: { name: ["Pacchetti standard"] }
    }, {
        path: `/seasonals`,
        component: SeasonalsList,
        exact: true,
        analytics: { name: ["Pacchetti stagionali"] }
    }, {
        path: `/pitches`,
        component: PitchesList,
        exact: true,
        analytics: { name: ["Piazzole"] }
    }, {
        path: `/parkings`,
        component: ParkingsList,
        exact: true,
        analytics: { name: ["Parcheggi"] }
    }, {
        path: `/services`,
        component: ServicesList,
        exact: true,
        analytics: { name: ["Servizi"] }
    }, {
        path: `/supplements`,
        component: SupplementsList,
        exact: true,
        analytics: { name: ["Supplementi"] }
    }, {
        path: `/animals`,
        component: AnimalsList,
        exact: true,
        analytics: { name: ["Animali"] }
    }].map(route => {
        return {
            ...route,
            path: `/beaches/:beachId/catalog${route.path}`,
            private: true,
            permissions: { memberships: ["MANAGEMENT", "BOOKING"] },
            analytics: { name: ["Spiagge", "Catalogo", ...(route.analytics?.name || [])] }
        }
    })
}].map(route => {
    return {
        ...route,
        path: `/beaches/:beachId/catalog${route.path}`,
        private: true,
        permissions: { memberships: ["MANAGEMENT", "BOOKING"] },
        analytics: { name: ["Spiagge", "Catalogo", ...(route.analytics?.name || [])] }
    }
})

