import { lazy } from 'react';
import MainLayout from '../layouts/MainLayout/MainLayout';

const Account = lazy(() => import('../pages/Shared/Settings/Account/Account'));
const Aperture = lazy(() => import('../pages/Shared/Settings/Aperture/Aperture'));
const BeachBuilder = lazy(() => import('../pages/Builder/BeachBuilder'));
const BeachBuilderBackground = lazy(() => import('../pages/Builder/BeachBuilderBackground'));
const BeachReviews = lazy(() => import('../pages/Shared/Settings/Reviews/Reviews'));
const BeachReviewsDetail = lazy(() => import('../pages/Shared/Settings/Reviews/ReviewsDetail'));
const BillingCreate = lazy(() => import('../pages/Shared/Settings/Billing/BillingCreate'));
const BillingDetail = lazy(() => import('../pages/Shared/Settings/Billing/BillingDetail'));
const BillingList = lazy(() => import('../pages/Shared/Settings/Billing/BillingList'));
const Connect = lazy(() => import('../pages/Shared/Settings/Stripe/Connect'));
const Contacts = lazy(() => import('../pages/Shared/Settings/Contacts/Contacts'));
const Info = lazy(() => import('../pages/Shared/Settings/Info/Info'));
const MapDashboard = lazy(() => import('../pages/Shared/Settings/Map/MapDashboard'));
const MapSectors = lazy(() => import('../pages/Shared/Settings/Map/MapSectors'));
const MapQrcodes = lazy(() => import('../pages/Shared/Settings/Map/MapQrcodes'));
const MapSectorsBuilder = lazy(() => import('../pages/Shared/Settings/Map/MapSectorsBuilder'));
const MapSettings = lazy(() => import('../pages/Shared/Settings/Map/MapSettings'));
const MembershipsList = lazy(() => import('../pages/Shared/Settings/Memberships/MembershipsList'));
const OrganizationsDetail = lazy(() => import('../pages/Shared/Settings/Organizations/OrganizationsDetail'));
const OrganizationsList = lazy(() => import('../pages/Shared/Settings/Organizations/OrganizationsList'));
const PrintersCreate = lazy(() => import('../pages/Shared/Settings/Printers/PrintersCreate'));
const PrintersDetail = lazy(() => import('../pages/Shared/Settings/Printers/PrintersDetail'));
const PrintersList = lazy(() => import('../pages/Shared/Settings/Printers/PrintersList'));
const Profile = lazy(() => import('../pages/Shared/Settings/Profile/Profile'));
const RulesList = lazy(() => import('../pages/Shared/Settings/Rules/RulesList'));
const Seasonality = lazy(() => import('../pages/Shared/Settings/Seasonality/Seasonality'));
const Services = lazy(() => import('../pages/Shared/Settings/Services/Services'));
const SettingsDetail = lazy(() => import('../pages/Shared/Settings/SettingsDetail'));
const DeactivationsDetail = lazy(() => import('../pages/Shared/Settings/Deactivations/Deactivations'));
const SettingsList = lazy(() => import('../pages/Shared/Settings/SettingsList'));
const Share = lazy(() => import('../pages/Shared/Settings/Share/Share'));
const Transactions = lazy(() => import('../pages/Shared/Settings/Transactions/Transactions'));
const UsersDetail = lazy(() => import('../pages/Shared/Settings/Users/UsersDetail'));
const UsersList = lazy(() => import('../pages/Shared/Settings/Users/UsersList'));
const PromotionsList = lazy(() => import('../pages/Shared/Settings/Promotions/PromotionsList'));
const PromotionsCreate = lazy(() => import('../pages/Shared/Settings/Promotions/PromotionsCreate'));
const PromotionsDetail = lazy(() => import('../pages/Shared/Settings/Promotions/PromotionsDetail'));
const SurchargesCreate = lazy(() => import('../pages/Shared/Settings/Surcharges/SurchargesCreate'));
const SurchargesDetail = lazy(() => import('../pages/Shared/Settings/Surcharges/SurchargesDetail'));
const SurchargesList = lazy(() => import('../pages/Shared/Settings/Surcharges/SurchargesList'));
const WhatsappList = lazy(() => import('../pages/Shared/Settings/Whatsapp/WhatsappList.js'));
const MapsList = lazy(() => import('../pages/Shared/Settings/Map/MapsList'));
const NotificationsList = lazy(() => import('../pages/Shared/Settings/Notifications/NotificationsList.js'));
const SendersContent = lazy(() => import('../pages/Shared/Settings/SendersContent/SendersContent.js'));
const DownPaymentsDetail = lazy(() => import('../pages/Shared/Settings/DownPayments/DownPayments.js'));
const Rating = lazy(() => import('../pages/Shared/Settings/Rating/Rating.js'));
const PrenotaSpiagge = lazy(() => import('pages/Shared/Settings/PrenotaSpiagge/PrenotaSpiagge'));

export default [{
    path: "/billing/create",
    component: BillingCreate,
    layout: MainLayout,
    exact: true,
    permissions: { memberships: ["MANAGEMENT", "BOOKING"], roles: ["admin"] },
    analytics: { name: ["Profili di fatturazione", "Creazione"] }
}, {
    path: "/billing/:profileId",
    component: BillingDetail,
    layout: MainLayout,
    permissions: { memberships: ["MANAGEMENT", "BOOKING"], roles: ["admin"] },
    analytics: { name: ["Profili di fatturazione", "Dettaglio"] }
}, {
    path: "/reviews/:reviewId",
    component: BeachReviewsDetail,
    layout: MainLayout,
    permissions: { roles: ["admin"] },
    analytics: { name: ["Recensioni", "Dettaglio"] },
}, {
    path: "/maps",
    component: MapsList,
    exact: true,
    permissions: { roles: ["admin"] },
    analytics: { name: ["Mappe"] }
}, {
    path: '/maps/create',
    component: MapSettings,
    exact: true,
    permissions: { memberships: ["MANAGEMENT"], roles: ["admin"] },
    analytics: { name: ["Mappa", "Configurazioni"] }
}, {
    path: "/map/:beachMapId",
    component: MapDashboard,
    exact: true,
    permissions: { memberships: ["MANAGEMENT"], roles: ["admin"] },
    analytics: { name: ["Mappa", "Impostazioni"] }
}, {
    path: '/map/:beachMapId/settings',
    component: MapSettings,
    exact: true,
    permissions: { memberships: ["MANAGEMENT"], roles: ["admin"] },
    analytics: { name: ["Mappa", "Aggiornamento"] }
}, {
    path: "/map/:beachMapId/builder",
    component: BeachBuilder,
    exact: true,
    permissions: { memberships: ["MANAGEMENT"], roles: ["admin"] },
    analytics: { name: ["Mappa", "Builder"] }
}, {
    path: "/map/:beachMapId/builder/background",
    component: BeachBuilderBackground,
    exact: true,
    permissions: { memberships: ["MANAGEMENT"], roles: ["admin"] },
    analytics: { name: ["Mappa", "Builder", "Sfondo"] }
}, {
    path: '/map/:beachMapId/sectors',
    component: MapSectors,
    exact: true,
    permissions: { memberships: ["MANAGEMENT"], roles: ["admin"] },
    analytics: { name: ["Mappa", "Settori"] }
}, {
    path: "/map/:beachMapId/sectors/:sectorId",
    component: MapSectorsBuilder,
    layout: MainLayout,
    exact: true,
    permissions: { memberships: ["MANAGEMENT"], roles: ["admin"] },
    analytics: { name: ["Mappa", "Settori", "Dettaglio"] }
}, {
    path: '/organizations/:organizationId',
    component: OrganizationsDetail,
    permissions: { memberships: ["MANAGEMENT"], roles: ["admin"] },
    analytics: { name: ["Organizzazioni", "Dettaglio"] }
}, {
    path: "/printers/create",
    component: PrintersCreate,
    layout: MainLayout,
    exact: true,
    permissions: { memberships: ["MANAGEMENT"], roles: ["admin"] },
    analytics: { name: ["Stampanti", "Creazione"] }
}, {
    path: "/printers/:printerId",
    component: PrintersDetail,
    layout: MainLayout,
    exact: true,
    permissions: { memberships: ["MANAGEMENT"], roles: ["admin"] },
    analytics: { name: ["Stampanti", "Dettaglio"] }
}, {
    path: "/promotions/create",
    component: PromotionsCreate,
    layout: MainLayout,
    exact: true,
    permissions: { memberships: ["MANAGEMENT"], roles: ["admin"] },
    analytics: { name: ["Promozioni", "Creazione"] }
}, {
    path: "/promotions/:promotionId",
    component: PromotionsDetail,
    layout: MainLayout,
    exact: true,
    permissions: { memberships: ["MANAGEMENT"], roles: ["admin"] },
    analytics: { name: ["Promozioni", "Dettaglio"] }
}, {
    path: "/surcharges/create",
    component: SurchargesCreate,
    layout: MainLayout,
    exact: true,
    permissions: { memberships: ["MANAGEMENT"], roles: ["admin"] },
    analytics: { name: ["Incrementi", "Creazione"] }
}, {
    path: "/surcharges/:surchargeId",
    component: SurchargesDetail,
    layout: MainLayout,
    exact: true,
    permissions: { memberships: ["MANAGEMENT"], roles: ["admin"] },
    analytics: { name: ["Incrementi", "Dettaglio"] }
}, {
    path: "/",
    component: SettingsList,
    exact: true,
    permissions: { roles: ["admin"] },
    analytics: { name: ["Impostazioni"] }
}, {
    path: "/",
    component: SettingsDetail,
    permissions: { roles: ["admin"] },
    layout: MainLayout,
    routes: [{
        path: '/map',
        component: MapDashboard,
        exact: true,
        permissions: { memberships: ["MANAGEMENT"], roles: ["admin"] },
        analytics: { name: ["Mappa"] }
    }, {
        path: '/memberships',
        component: MembershipsList,
        exact: true,
        analytics: { name: ["Memberships"] }
    }, {
        path: '/seasonality',
        component: Seasonality,
        exact: true,
        permissions: { memberships: ["MANAGEMENT", "BOOKING"], roles: ["admin"] },
        analytics: { name: ["Stagionalità"] }
    }, {
        path: '/deactivations',
        component: DeactivationsDetail,
        exact: true,
        permissions: { memberships: ["MANAGEMENT", "BOOKING"], roles: ["admin"] },
        analytics: { name: ["Disattivazioni online"] }
    }, {
        path: '/downPayments',
        component: DownPaymentsDetail,
        exact: true,
        permissions: { memberships: ["MANAGEMENT", "BOOKING"], roles: ["admin"] },
        analytics: { name: ["Acconti online"] }
    }, {
        path: '/map/:beachMapId/qrcodes',
        component: MapQrcodes,
        exact: true,
        permissions: { memberships: ["MANAGEMENT"], roles: ["admin"] },
        analytics: { name: ["Mappa", "Qrcodes"] }
    }, {
        path: '/info',
        component: Info,
        analytics: { name: ["Info"] },
        permissions: { memberships: ["BASIC", "MANAGEMENT", "BOOKING"], roles: ["admin"] }
    }, {
        path: '/services',
        component: Services,
        analytics: { name: ["Servizi"] },
        permissions: { memberships: ["BASIC", "MANAGEMENT", "BOOKING"], roles: ["admin"] }
    }, {
        path: '/rules',
        component: RulesList,
        analytics: { name: ["Regole"] },
        permissions: { memberships: ["MANAGEMENT"], roles: ["admin"] }
    }, {
        path: '/reviews',
        component: BeachReviews,
        analytics: { name: ["Recensioni"] },
        permissions: { roles: ["admin"] },
    }, {
        path: '/users',
        component: UsersList,
        exact: true,
        permissions: { memberships: ["MANAGEMENT"], roles: ["admin"] },
        analytics: { name: ["Utenti"] }
    }, {
        path: '/users/:userId',
        component: UsersDetail,
        exact: true,
        permissions: { memberships: ["MANAGEMENT"], roles: ["admin"] },
        analytics: { name: ["Utente", "Dettaglio"] }
    }, {
        path: '/billing',
        component: BillingList,
        exact: true,
        permissions: { memberships: ["MANAGEMENT", "BOOKING"], roles: ["admin"] },
        analytics: { name: ["Profili fatturazione"] }
    }, {
        path: '/transactions',
        component: Transactions,
        exact: true,
        permissions: { memberships: ["MANAGEMENT", "BOOKING"], roles: ["admin"] },
        analytics: { name: ["Transazioni"] }
    }, {
        path: '/stripe/connect',
        component: Connect,
        exact: true,
        permissions: { memberships: ["MANAGEMENT", "BOOKING"], roles: ["admin"] },
        analytics: { name: ["Stripe connect"] }
    }, {
        path: '/contacts',
        component: Contacts,
        exact: true,
        analytics: { name: ["Contatti"] }
    }, {
        path: '/account',
        component: Account,
        analytics: { name: ["Account"] }
    }, {
        path: '/share',
        component: Share,
        analytics: { name: ["Share"] }
    }, {
        path: '/organizations',
        component: OrganizationsList,
        analytics: { name: ["Organizzazioni"] },
        permissions: { memberships: ["MANAGEMENT", "BOOKING"], roles: ["admin"] },
    }, {
        path: '/aperture',
        component: Aperture,
        analytics: { name: ["Aperture"] }
    }, {
        path: '/profile',
        component: Profile,
        analytics: { name: ["Profilo"] }
    }, {
        path: '/printers',
        component: PrintersList,
        analytics: { name: ["Stampanti"] },
        permissions: { memberships: ["MANAGEMENT"], roles: ["admin"] }
    }, {
        path: '/promotions',
        component: PromotionsList,
        analytics: { name: ["Promozioni"] },
        permissions: { memberships: ["MANAGEMENT", "BOOKING"], roles: ["admin"] }
    }, {
        path: '/surcharges',
        component: SurchargesList,
        analytics: { name: ["Incrementi"] },
        permissions: { memberships: ["MANAGEMENT", "BOOKING"], roles: ["admin"] }
    }, {
        path: '/sendersContent',
        component: SendersContent,
        analytics: { name: ["Comunicazioni"] },
        permissions: { memberships: ["MANAGEMENT", "BOOKING"], roles: ["admin"] }
    }, {
        path: '/whatsapp',
        component: WhatsappList,
        analytics: { name: ["Whatsapp"] },
        permissions: { memberships: ["MANAGEMENT", "BOOKING"], roles: ["admin"] }
    }, {
        path: '/notificationsSettings',
        component: NotificationsList,
        analytics: { name: ["Notifiche"] },
        permissions: { memberships: ["MANAGEMENT", "BOOKING"], roles: ["admin"] }
    }, {
        path: '/rating',
        component: Rating,
        analytics: { name: ["Rating"] }
    }, {
        path: '/prenota-spiagge',
        component: PrenotaSpiagge,
        analytics: { name: ["Prenota spiagge"] }
    }].map(route => ({
        ...route,
        path: `/beaches/:beachId/settings${route.path}`,
        private: true,
        analytics: { name: ["Spiagge", "Impostazioni", ...(route.analytics?.name || [])] }
    })),
}].map(route => ({
    ...route,
    path: `/beaches/:beachId/settings${route.path}`,
    private: true,
    analytics: { name: ["Spiagge", "Impostazioni", ...(route.analytics?.name || [])] }
}));
