import { lazy } from 'react';
import EmptyLayout from '../layouts/EmptyLayout/EmptyLayout';

const NewBeachSearch = lazy(() => import('../pages/Beach/New/Steps/NewBeachSearch'));
const NewBeachInfo = lazy(() => import('../pages/Beach/New/Steps/NewBeachInfo'));
const NewBeachPack = lazy(() => import('../pages/Beach/New/Steps/NewBeachPack'));
const NewBeachServices = lazy(() => import('../pages/Beach/New/Steps/NewBeachServices'));
const NewBeachAccessibility = lazy(() => import('../pages/Beach/New/Steps/NewBeachAccessibility'));
const NewBeachImages = lazy(() => import('../pages/Beach/New/Steps/NewBeachImages'));
const NewBeachConditions = lazy(() => import('../pages/Beach/New/Steps/NewBeachConditions'));
const NewBeachPayment = lazy(() => import('../pages/Beach/New/Steps/NewBeachPayment'));
const NewBeachBillings = lazy(() => import('../pages/Beach/New/Steps/NewBeachBillings'));
const NewBeachSummary = lazy(() => import('../pages/Beach/New/Steps/NewBeachSummary'));
const NewBeachFirstDeckchair = lazy(() => import('../pages/Beach/New/Steps/NewBeachFirstDeckchair'));
const NewBeachFirstUmbrella = lazy(() => import('../pages/Beach/New/Steps/NewBeachFirstUmbrella'));

const NewBeach = lazy(() => import('../pages/Beach/New/NewBeach'));
const NewBeachDescription = lazy(() => import('../pages/Beach/New/Steps/NewBeachDescription'));

export default [
    {
        path: '',
        component: NewBeach,
        layout: EmptyLayout,
        layoutOptions: { emptyHeader: true },
        routes: [
            {
                path: '/new/beach/search',
                name: 'search',
                component: NewBeachSearch,
                analytics: { name: ["Ricerca"] }
            },
            {
                path: '/new/beach/info',
                name: 'info',
                component: NewBeachInfo,
                analytics: { name: ["Info"] }
            },
            ...[
            {
                path: '/pack',
                name: 'pack',
                component: NewBeachPack,
                analytics: { name: ["Pacchetti"] }
            },{
                path: '/services',
                name: 'services',
                component: NewBeachServices,
                analytics: { name: ["Servizi"] }
            },{
                path: '/payment',
                name: 'payment',
                component: NewBeachPayment,
                analytics: { name: ["Pagamento"] }
            },{
                path: '/description',
                name: 'description',
                component: NewBeachDescription,
                analytics: { name: ["Descrizione"] }
            },{
                path: '/accessibility',
                name: 'accessibility',
                component: NewBeachAccessibility,
                analytics: { name: ["Accessibilità"] }
            },{
                path: '/images',
                name: 'images',
                component: NewBeachImages,
                analytics: { name: ["Immagini"] }
            },{
                path: '/conditions',
                name: 'conditions',
                component: NewBeachConditions,
                analytics: { name: ["Condizioni"] }
            },{
                path: '/billing',
                name: 'billing',
                component: NewBeachBillings,
                analytics: { name: ["Fatturazione"] }
            },{
                path: '/summary',
                name: 'summary',
                component: NewBeachSummary,
                analytics: { name: ["Riepilogo"] }
            },{
                path: '/firstDeckchair',
                name: 'firstDeckchair',
                component: NewBeachFirstDeckchair,
                analytics: { name: ["Primo lettino"] }
            },{
                path: '/firstUmbrella',
                name: 'firstUmbrella',
                component: NewBeachFirstUmbrella,
                analytics: { name: ["Primo ombrellone"] }
            }
        ].map(route => {
            return {
                ...route,
                path: `/new/beach/:beachId${route.path}`,
                analytics: { name: ["Spiagge", "Creazione", ...(route.analytics?.name || [])] }
            }
        })]
    }
].map(route => {
    return {
        ...route,
        path: `/new/beach/:beachId${route.path}`,
        analytics: { name: ["Spiagge", "Creazione", ...(route.analytics?.name || [])] }
    }
})
