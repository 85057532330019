import FrontendLayout from '../layouts/FrontendLayout/FrontendLayout';
import Tutorial from '../pages/Tutorial/Tutorial';
import TutorialDetail from '../pages/Tutorial/TutorialDetail';

export default [
    {
        path: '/tutorial',
        component: Tutorial,
        layout: FrontendLayout,
        exact: true,
        private: true,
        analytics: { name: ["Tutorial"] }
    },
    {
        path: '/tutorial/:tutorialSlug',
        component: TutorialDetail,
        layout: FrontendLayout,
        layoutOptions: { noPadding: true },
        exact: true,
        private: true,
        analytics: { name: ["Dettaglio tutorial"] }
    }
]