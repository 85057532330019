import { lazy } from 'react';

import EmptyLayout from '../layouts/EmptyLayout/EmptyLayout';

const NewActivityType = lazy(() => import('../pages/Shared/New/NewActivityType'));

export default [
    {
        path: '/activityType',
        component: NewActivityType,
        layout: EmptyLayout,
        layoutOptions: { emptyHeader: true },
        analytics: { name: ["Creazione","Tipologia attività"] }
    }
].map(route => {
    return {
        ...route,
        path: `/new${route.path}`
    }
})
