import { lazy } from 'react';

import MainLayout from '../layouts/MainLayout/MainLayout';

const TransactionsScheduledList = lazy(() => import('../pages/Beach/TransactionsScheduled/TransactionsScheduledList.js'));
const TransactionScheduledDetail = lazy(() => import('../pages/Beach/TransactionsScheduled/TransactionScheduledDetail.js'));

export default [{
    path: "",
    component: TransactionsScheduledList,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Lista Scadenziario"] }
}, {
    path: "/:transactionScheduledId",
    component: TransactionScheduledDetail,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Dettaglio Scadenza"] }
}].map((route) => ({
    ...route,
    path: `/beaches/:beachId/transactionsScheduled${route.path}`,
    private: true,
    permissions: { memberships: ["MANAGEMENT", "BOOKING"] },
    analytics: {
        name: ["Scadenziario", ...(route.analytics?.name || [])]
    }
}));