import React, { Fragment } from 'react';

import PrintersTilesListItem from './PrintersTilesListItem';
import { usePrinters } from '../../context/printers';
import { useActivities } from '../../context/activities';

export default function PrintersList({ printerAction, lastPrinterId, type, connectedOnly }) {

    const { activeActivity } = useActivities();
    const { printers: allPrinters, connectedPrinters, connectedFiscalPrinters } = usePrinters();
    let printers = allPrinters;
    if(connectedOnly && type === "fiscal") {
        printers = connectedFiscalPrinters;
    }
    if(connectedOnly && !type) {
        printers = connectedPrinters;
    }

    return (
        <Fragment>
            {printers?.map((printer, index) => {
                return (
                    <PrintersTilesListItem
                        key={index}
                        activityId={activeActivity?._id}
                        lastPrinterId={lastPrinterId}
                        printer={printer}
                        printerAction={printerAction}
                    />
                )
            })}
        </Fragment>
    )
}
