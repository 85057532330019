import React from 'react';

import { NormalPopup } from '@beacharound/beacharound-ui';

import PrintersList from './PrintersList';

export default function PrintersListPopup({
    visible,
    setVisible,
    lastPrinterId,
    onPrinterSelect,
    type,
    connectedOnly
}) {

    return (

        <NormalPopup
            visible={visible}
            setVisible={setVisible}
            title="Seleziona una stampante"
        >
            <PrintersList
                lastPrinterId={lastPrinterId}
                printerAction={onPrinterSelect}
                type={type}
                connectedOnly={connectedOnly}
            />
        </NormalPopup>
    );
}
