import axios from 'axios';
import Cookies from 'js-cookie';

import { refreshUser } from '../../user';
import { returnError } from '../error';

const userAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API_V2_URL}`,
    headers: {
        'content-type': 'application/json'
    },
    // withCredentials: true
});

userAxios.interceptors.request.use(async (config) => {
    let token = Cookies.get('user-authorization');
    let refreshToken = Cookies.get('user-refreshToken');
    let userId = Cookies.get('user-id');

    if(!token && !!refreshToken && !!userId) {
        const data = await refreshUser({_id: userId, refreshToken});
        console.log("REFRESH TOKEN");
        token = data.token;
    }

    if(!!token) {
        config.headers.authorization = `Bearer ${token}`
    }
    // config.headers["beacharound-activity"] = Cookies.get("beacharound-activity");
    return config;
});

userAxios.interceptors.response.use(response => response, error => {
    return returnError(error);
});

// userAxios.defaults.paramsSerializer = p => {
//     const params = Object.keys(p).reduce((acc, key) => {
//         let value = p[key];
//         if(value && Array.isArray(value)) {
//             value = JSON.stringify(value);
//             console.log(value);
//         }
//         return {
//             ...acc,
//             [key]: value
//         }
//     }, {})
//     return qs.stringify(params)
// }

export default userAxios;