import { lazy } from 'react';
import MainLayout from '../layouts/MainLayout/MainLayout';

// Catalogo
const BeachFood = lazy(() => import('../pages/Beach/Food/BeachFood'));

const FoodList = lazy(() => import('../pages/Beach/Food/Food/FoodList'));
const FoodDetail = lazy(() => import('../pages/Beach/Food/Food/FoodDetail'));
const FoodAdd = lazy(() => import('../pages/Beach/Food/Food/FoodAdd'));

const DrinksList = lazy(() => import('../pages/Beach/Food/Drinks/DrinksList'));
const DrinksDetail = lazy(() => import('../pages/Beach/Food/Drinks/DrinksDetail'));
const DrinksAdd = lazy(() => import('../pages/Beach/Food/Drinks/DrinksAdd'));

const CategoriesList = lazy(() => import('../pages/Beach/Food/Categories/CategoriesList'));
const CategoriesDetail = lazy(() => import('../pages/Beach/Food/Categories/CategoriesDetail'));
const CategoriesAdd = lazy(() => import('../pages/Beach/Food/Categories/CategoriesAdd'));

const AutoList = lazy(() => import('../pages/Beach/Food/Auto/AutoList'));
const AutoDetail = lazy(() => import('../pages/Beach/Food/Auto/AutoDetail'));
const AutoAdd = lazy(() => import('../pages/Beach/Food/Auto/AutoAdd'));

const AllergensList = lazy(() => import('../pages/Beach/Food/Allergens/AllergensList'));

const ServicesList = lazy(() => import('../pages/Beach/Food/Services/ServicesList'));
const ServicesDetail = lazy(() => import('../pages/Beach/Food/Services/ServicesDetail'));
const ServicesAdd = lazy(() => import('../pages/Beach/Food/Services/ServicesAdd'));

const OrdersList = lazy(() => import('../pages/Beach/Food/Orders/OrdersList'))
const OrdersDetail = lazy(() => import('../pages/Beach/Food/Orders/OrdersDetail'))
const OrdersDetailInfo = lazy(() => import('../pages/Beach/Food/Orders/Detail/OrdersDetailInfo'))
const BeachReservationsDetailTransactions = lazy(() => import('../pages/Beach/Reservations/Detail/BeachReservationsDetailTransactions'));
const BeachReservationsDetailBeacharoundPayments = lazy(() => import('../pages/Beach/Reservations/Detail/BeachReservationsDetailBeacharoundPayments'));

const Settings = lazy(() => import('../pages/Beach/Food/Settings/Settings'));

export default [{
    path: '/reservations/:reservationId',
    component: OrdersDetail,
    layout: MainLayout,
    analytics: { name: ["Ordini", "Dettaglio"] },
    routes: [{
        path: ``,
        component: OrdersDetailInfo,
        exact: true,
        analytics: { name: ["Info"] }
    },{
        path: `/transactions`,
        exact: true,
        component: BeachReservationsDetailTransactions,
        analytics: { name: ["Transazioni"] }
    },{
        path: `/payments`,
        exact: true,
        component: BeachReservationsDetailBeacharoundPayments,
        analytics: { name: ["Pagamenti"] }
    }].map(route => {
        return {
            ...route,
            path: `/beaches/:beachId/food/reservations/:reservationId${route.path}`,
            private: true,
            permissions: { memberships: ["MANAGEMENT", "BOOKING"] },
            analytics: { name: ["Spiagge", "Food", ...(route.analytics?.name || [])] }
        }
    })
},{
    path: '/menu-food/add',
    component: FoodAdd,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Cibo","Creazione"] }
},{
    path: '/menu-food/:productId',
    component: FoodDetail,
    layout: MainLayout,
    analytics: { name: ["Cibo", "Dettaglio"] }
},{
    path: '/menu-drinks/add',
    component: DrinksAdd,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Drinks","Creazione"] }
},{
    path: '/menu-drinks/:productId',
    component: DrinksDetail,
    layout: MainLayout,
    analytics: { name: ["Drinks", "Dettaglio"] }
},{
    path: '/categories/add',
    component: CategoriesAdd,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Categories","Creazione"] }
},{
    path: '/categories/:categoryId',
    component: CategoriesDetail,
    layout: MainLayout,
    analytics: { name: ["Categories", "Dettaglio"] }
},{
    path: '/auto/add',
    component: AutoAdd,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Prodotti automatici","Creazione"] }
},{
    path: '/auto/:productId',
    component: AutoDetail,
    layout: MainLayout,
    analytics: { name: ["Prodotti automatici", "Dettaglio"] }
},{
    path: '/services/add',
    component: ServicesAdd,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Servizi food","Creazione"] }
},{
    path: '/services/:productId',
    component: ServicesDetail,
    layout: MainLayout,
    analytics: { name: ["Servizi food", "Dettaglio"] }
},{
    path: '',
    component: BeachFood,
    layout: MainLayout,
    routes: [{
        path: `/reservations`,
        component: OrdersList,
        exact: true,
        analytics: { name: ["Ordini"] }
    }, {
        path: `/menu-food`,
        component: FoodList,
        exact: true,
        analytics: { name: ["Cibo"] }
    }, {
        path: `/menu-drinks`,
        component: DrinksList,
        exact: true,
        analytics: { name: ["Drinks"] }
    }, {
        path: `/categories`,
        component: CategoriesList,
        exact: true,
        analytics: { name: ["Categories"] }
    }, {
        path: `/auto`,
        component: AutoList,
        exact: true,
        analytics: { name: ["Prodotti automatici"] }
    }, {
        path: `/services`,
        component: ServicesList,
        exact: true,
        analytics: { name: ["Servizi food"] }
    }, {
        path: `/allergens`,
        component: AllergensList,
        exact: true,
        analytics: { name: ["Allergeni"] }
    }, {
        path: `/settings`,
        component: Settings,
        exact: true,
        analytics: { name: ["Impostazioni"] }
    }].map(route => {
        return {
            ...route,
            path: `/beaches/:beachId/food${route.path}`,
            private: true,
            permissions: { memberships: ["MANAGEMENT", "BOOKING"] },
            analytics: { name: ["Spiagge", "Food", ...(route.analytics?.name || [])] }
        }
    })
}].map(route => {
    return {
        ...route,
        path: `/beaches/:beachId/food${route.path}`,
        private: true,
        permissions: { memberships: ["MANAGEMENT", "BOOKING"] },
        analytics: { name: ["Spiagge", "Food", ...(route.analytics?.name || [])] }
    }
})

