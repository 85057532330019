import axiosV2 from './_defaults/v2/user';

export async function createMemberships({ data, activityId, activityType }) {

    try {

        const response = await axiosV2.post(
            `/business-memberships/${activityId}`,
            data,
            {
                params: {
                    activityType,
                },
            }
        );

        if (response.data) {
            return response.data;
        }

        return response;
    } catch(error) {
        console.log(error.response);
        throw error;
    }
}

export async function getMemberships({ activityId }) {
    try {
        const response = await axiosV2.get(`/business-memberships/${activityId}`);

        if (response.data) {
            return response.data;
        }

        return response;
    } catch (error) {
        throw error;
    }
}

export async function getMembershipsPacks({ params }) {
    try {
        const response = await axiosV2.get("/business-memberships/packs", {
            params,
        });
        if (response.data) {
            return response.data;
        }
        return response;
    } catch (error) {
        throw error;
    }
}

export async function acceptMembershipsConsents({ activityId, memberships }) {
    try {
        const response = await axiosV2.post(
            `/business-memberships/${activityId}/accept`,
            {
                memberships,
            }
        );
        if (response.data) {
            return response.data;
        }
        return response;
    } catch (error) {
        throw error;
    }
}

export async function getActiveMemberships({ activityId }) {
    try {
        const response = await axiosV2.get(`/business-memberships/${activityId}/active`);
        if (response.data) {
            return response.data;
        }
        return response;
    } catch (error) {
        throw error;
    }
}