import React from 'react';
import { TourProvider } from '@reactour/tour';

const styles = {
	close: base => ({ ...base, left: 'auto', right: 20, top: 16 }),
	popover: base => ({
		...base,
		'--reactour-accent': '#1aa0ac',
		borderRadius: 5,
		padding: '2rem 4rem 2rem 3rem'
	}),
	maskArea: base => ({ ...base, rx: 5, padding: '1rem' }),
	badge: base => ({ ...base, backgroundColor: '#1aa0ac', height: 'auto', fontFamily: 'inherit' }),
	dot: (current) => ({ ...current, color: '#1aa0ac' })
};

const TutorialProvider = ({ children, steps, isDemo = false }) => {
	if (isDemo) {
		return (
			<TourProvider
				isDemo={isDemo}
				steps={steps}
				styles={styles}
				scrollSmooth
			>
				{children}
			</TourProvider>
		);
	}
	return children;
}

export default TutorialProvider;