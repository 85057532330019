import React, { useState, Fragment } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useQuery } from "react-query";

import { Header, MobileMenu } from '@beacharound/beacharound-ui';

import { getUser } from '../api/user';

export default function FrontendHeader({ empty, noBack }) {

    const [menuOpen, setMenuOpen] = useState(false)
    const history = useHistory();
    const { pathname } = useLocation();

    const { data: user } = useQuery("user", getUser);

    let menuList = [{
        text: 'Home',
        url: '/',
        active: pathname === '/'
    }, {
        text: 'Spiagge',
        url: '/spiagge-beacharound'
    }, {
        text: 'Gestionale spiaggia',
        url: '/gestionale-spiaggia'
    }, {
        text: 'Hotel',
        url: '/hotel-beacharound'
    }, {
        text: 'Blog',
        url: '/blog'
    // }, {
    //     text: 'Tutorial',
    //     url: '/tutorial'
    }, {
        text: 'Contatti',
        url: '/contatta-beacharound'
    }]

    if (user) {
        menuList = [
            ...menuList,
            {
                text: 'Vai alla dashboard',
                url: '/dashboard',
                highlight: true
            }
        ]
    } else {
        menuList = [
            ...menuList,
            {
                text: 'Accedi',
                url: '/user/login'
            },
            {
                text: 'Prova gratis',
                url: '/user/signup?cta_text_trial=trial',
                highlight: true
            }
        ]
    }
    
    const menuListParsed = menuList?.map(route => {
        return {
            ...route,
            url: route.absoluteUrl || `${route.url}`,
            active: route.active !== undefined ? route.active : pathname.includes(route.url) 
        }
    })

    return (

        <Fragment>
            <Header
                menuList={menuListParsed}
                empty={empty}
                linkComponent={Link}
                logoUrl={"/"}
                openMenu={() => setMenuOpen(true)}
                backAction={() => history.goBack()}
                noBack={noBack}
            />
            {!empty &&
                <MobileMenu
                    linkComponent={Link}
                    open={menuOpen}
                    list={menuListParsed}
                    closeMenu={() => setMenuOpen(false)}
                />
            }
        </Fragment>
    )
}
