import React, { useState } from 'react';
import { CheckboxInput, MainForm, SubTitle } from '@beacharound/beacharound-ui';

const StepContent = ({ text, title = null, storageKey = null, children }) => {
  const [show, setShow] = useState(true)

  const toggleShow = () => {
    localStorage.setItem(storageKey, `${!show}`);
    setShow(!show);
  }

  return (
    <MainForm>
      {!!title && <SubTitle text={title} color="black" />}
      <div style={{ marginTop: !!title ? '1rem' : '0', marginBottom: !!storageKey ? '1.5rem' : '1rem' }}>
        {!!text && <p>{text}</p>}
        {(!!children && !text) && children}
      </div>
      {!!storageKey && <CheckboxInput label="Non mostrare più" onChangeInput={toggleShow} />}
    </MainForm>
  );
}

export default StepContent;