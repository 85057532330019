import { lazy } from 'react';

import MainLayout from '../layouts/MainLayout/MainLayout';

const BeachHotelsList = lazy(() => import('../pages/Beach/Hotels/BeachHotelsList'));
const BeachHotelsReservationsList = lazy(() => import('../pages/Beach/Hotels/BeachHotelsReservationsList'));
const BeachHotelsReportsList = lazy(() => import('../pages/Beach/Hotels/BeachHotelsReportsList'));
const BeachHotelsReportsEmptyForFullList = lazy(() => import('../pages/Beach/Hotels/BeachHotelsReportsEmptyForFullList'));

const BeachHotelsAdd = lazy(() => import('../pages/Beach/Hotels/BeachHotelsAdd'));
const BeachHotelsDetail = lazy(() => import('../pages/Beach/Hotels/BeachHotelsDetail'));

const BeachHotelsDetailInfo = lazy(() => import('../pages/Beach/Hotels/Detail/BeachHotelsDetailInfo'));
const BeachHotelsDetailReservations = lazy(() => import('../pages/Beach/Hotels/Detail/BeachHotelsDetailReservations'));
const BeachHotelsDetailReservationsVoucher = lazy(() => import('../pages/Beach/Hotels/Detail/BeachHotelsDetailReservationsVoucher'));
const BeachHotelsDetailVouchersList = lazy(() => import('../pages/Beach/Hotels/Detail/Vouchers/BeachHotelsDetailVouchersList'));
const BeachHotelsDetailVouchersAdd = lazy(() => import('../pages/Beach/Hotels/Detail/Vouchers/BeachHotelsDetailVouchersAdd'));
const BeachHotelsDetailVouchersDetail = lazy(() => import('../pages/Beach/Hotels/Detail/Vouchers/BeachHotelsDetailVouchersDetail'));
const BeachHotelsDetailUsedVouchers = lazy(() => import('../pages/Beach/Hotels/Detail/BeachHotelsDetailUsedVouchers'));
const BeachHotelsDetailReportsList = lazy(() => import('../pages/Beach/Hotels/Detail/Reports/BeachHotelsDetailReportsList'));
const BeachHotelsDetailReportsDetail = lazy(() => import('../pages/Beach/Hotels/Detail/Reports/BeachHotelsDetailReportsDetail'));
const BeachHotelsDetailReportsEmptyForFullList = lazy(() => import('../pages/Beach/Hotels/Detail/ReportsEmptyForFull/BeachHotelsDetailReportsList'));
const BeachHotelsDetailReportsEmptyForFullDetail = lazy(() => import('../pages/Beach/Hotels/Detail/ReportsEmptyForFull/BeachHotelsDetailReportsDetail'));
const BeachHotelsDetailCatalogues = lazy(() => import('../pages/Beach/Hotels/Detail/Catalogues/BeachHotelsDetailCatalogues.js'));
const BeachHotelCatalog = lazy(() => import('../pages/Beach/Hotels/Detail/Catalogues/Detail/BeachHotelCatalog.js'));
const BeachHotelCatalogInfo = lazy(() => import('../pages/Beach/Hotels/Detail/Catalogues/Detail/BeachHotelCatalogInfo'));
const BeachHotelCatalogProductsList = lazy(() => import('../pages/Beach/Hotels/Detail/Catalogues/Detail/Products/List.js'));
const BeachHotelCatalogProductsAdd = lazy(() => import('../pages/Beach/Hotels/Detail/Catalogues/Detail/Products/Add.js'));
const BeachHotelCatalogProductsDetail = lazy(() => import('../pages/Beach/Hotels/Detail/Catalogues/Detail/Products/Detail.js'));

export default [{
    path: "/reservations",
    component: BeachHotelsReservationsList,
    layout: MainLayout,
    permissions: { memberships: ["BOOKING", "MANAGEMENT"] },
    analytics: { name: ["Prenotazioni"] }
}, {
    path: "/reports",
    component: BeachHotelsReportsList,
    layout: MainLayout,
    permissions: { memberships: ["BOOKING", "MANAGEMENT"] },
    analytics: { name: ["Reports"] }
}, {
    path: "/reportsemptyforfull",
    component: BeachHotelsReportsEmptyForFullList,
    layout: MainLayout,
    permissions: { memberships: ["BOOKING", "MANAGEMENT"] },
    analytics: { name: ["Report vuoto per pieno"] }
}, {
    path: "/add",
    component: BeachHotelsAdd,
    layout: MainLayout,
    permissions: { memberships: ["BOOKING", "MANAGEMENT"] }
}, {
    path: `/:hotelId/vouchers/add`,
    exact: true,
    component: BeachHotelsDetailVouchersAdd,
    analytics: { name: ["Voucher", "Creazione"] }
}, {
    path: `/:hotelId/vouchers/:voucherId`,
    exact: true,
    component: BeachHotelsDetailVouchersDetail,
    analytics: { name: ["Voucher", "Dettaglio"] }
}, {
    path: `/:hotelId/reports/:reportId`,
    exact: true,
    component: BeachHotelsDetailReportsDetail,
    analytics: { name: ["Report", "Dettaglio"] }
}, {
    path: `/:hotelId/reportsemptyforfull/:reportId`,
    exact: true,
    component: BeachHotelsDetailReportsEmptyForFullDetail,
    analytics: { name: ["Report vuoto per pieno", "Dettaglio"] }
}, {
    path: `/:hotelId/catalogues/:catalogId/:productType/add`,
    exact: true,
    component: BeachHotelCatalogProductsAdd,
    analytics: { name: ["Prodotto", "Aggiunta"] }
}, {
    path: `/:hotelId/catalogues/:catalogId/:productType/:productId`,
    exact: true,
    component: BeachHotelCatalogProductsDetail,
    analytics: { name: ["Prodotto", "Dettaglio"] }
}, {
    path: `/:hotelId/catalogues/:catalogId`,
    component: BeachHotelCatalog,
    analytics: { name: ["Listino"] },
    routes: [{
        path: ``,
        exact: true,
        component: BeachHotelCatalogInfo,
    }, {
        path: `/:productType`,
        exact: true,
        component: BeachHotelCatalogProductsList,
        analytics: { name: ["Listino", "Prodotti"] }
    }].map((route) => ({
        ...route,
        path: `/beaches/:beachId/hotels/:hotelId/catalogues/:catalogId${route.path}`,
        private: true,
        analytics: { name: ["Catalogo", "Dettaglio", ...(route.analytics?.name || [])] },
        permissions: { memberships: ["MANAGEMENT", "BOOKING"] },
    }))
}, {
    path: "/:hotelId",
    component: BeachHotelsDetail,
    layout: MainLayout,
    permissions: { memberships: ["BOOKING", "MANAGEMENT"] },
    routes: [{
        path: ``,
        exact: true,
        component: BeachHotelsDetailInfo,
    }, {
        path: `/reservations`,
        exact: true,
        component: BeachHotelsDetailReservations,
        analytics: { name: ["Prenotazioni"] }
    }, {
        path: `/reservations-voucher`,
        exact: true,
        component: BeachHotelsDetailReservationsVoucher,
        analytics: { name: ["Prenotazioni con voucher"] }
    }, {
        path: `/catalogues`,
        exact: true,
        component: BeachHotelsDetailCatalogues,
        analytics: { name: ["Listini"] }
    }, {
        path: `/vouchers`,
        exact: true,
        component: BeachHotelsDetailVouchersList,
        analytics: { name: ["Voucher"] }
    }, {
        path: `/used-vouchers`,
        exact: true,
        component: BeachHotelsDetailUsedVouchers,
        analytics: { name: ["Voucher utilizzati"] }
    }, {
        path: `/reports`,
        exact: true,
        component: BeachHotelsDetailReportsList,
        analytics: { name: ["Report"] }
    }, {
        path: `/reportsemptyforfull`,
        exact: true,
        component: BeachHotelsDetailReportsEmptyForFullList,
        analytics: { name: ["Report vuoto per pieno"] }
    }].map((route) => ({
        ...route,
        path: `/beaches/:beachId/hotels/:hotelId${route.path}`,
        private: true,
        analytics: { name: ["Dettaglio", ...(route.analytics?.name || [])] },
        permissions: { memberships: ["MANAGEMENT", "BOOKING"] },
    }))
}, {
    path: "/",
    component: BeachHotelsList,
    layout: MainLayout,
}].map((route) => ({
    ...route,
    path: `/beaches/:beachId/hotels${route.path}`,
    private: true,
    permissions: { memberships: ["MANAGEMENT", "BOOKING"] },
    analytics: {
        name: ["Spiagge", "Hotel convenzionati", ...(route.analytics?.name || [])],
    },
}));