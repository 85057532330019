const features = [{
    title: 'Mappa spiaggia',
    subtitle: "Crea la mappa virtuale del tuo stabilimento balneare con l'innovativo sistema drag and drop!",
    paragraphs: [{
        title: "Crea la mappa della tua spiaggia",
        text: "Trascina tutti gli elementi all'interno della griglia in modo semplice, intuitivo e divertente!<br/>La tua spiaggia può essere personalizzata e modificata direttamente da te in pochissimi secondi. Hai bisogno di assistenza?<br/>Il nostro team può personalizzare appositamente la mappa del tuo stabilimento al posto tuo! ",
        image: "/images/frontend/beaches/mappa-spiaggia.png"
    }, {
        title: "Modifica le prenotazioni dalla mappa",
        text: "La mappa è un comodo strumento per tenere sempre monitorato lo stato delle prenotazioni e ti offre la possibilità di gestirle giorno per giorno con precisione.</br>Potrai modificarle in qualunque momento per soddisfare le esigenze dei tuoi clienti.</br>Selezionando un prodotto all'interno della mappa sarà possibile controllare la prenotazione nel dettaglio: potrai aggiungere e togliere lettini, cabine, servizi; potrai modificare le date di check-in e check-out, variarne il prezzo, inviare un riepilogo al tuo cliente, e molto altro. Basta un click!",
        image: "/images/frontend/beaches/mappa-prenotazione.png"
    }, {
        title: " Configurare la tua spiaggia non è mai stato così semplice!",
        text: "Il builder di Beacharound Business è stato concepito per creare e modificare con facilità e in qualsiasi momento la mappa del tuo stabilimento balneare.</br>Dal builder è possibile configurare la propria spiaggia sotto ogni aspetto: dalla dimensione del lido alle decorazioni, le aree verdi, le passerelle, eventuali piscine, e molto altro!</br>Per posizionare gli elementi sulla mappa, basterà trascinare il prodotto desiderato nella posizione che preferisci. Una volta posizionato il prodotto sarà possibile replicarlo velocemente trascinandolo nuovamente nelle celle desiderate, e così via per tutti gli elementi.",
        image: "/images/frontend/beaches/builder-posiziona.png"
    }],
    faq: [{
        question: "È possibile tenere sotto controllo dalla mappa lo stato dei pagamenti dei clienti?",
        answer: "Sì, puoi controllare lo stato dei pagamenti verificando i clienti che hanno già pagato, dato una caparra o che ancora devono effettuare il saldo."
    }, {
        question: "È possibile tenere sotto controllo i check-in e i check-out dei clienti?",
        answer: "Sì, sulla mappa sono sempre segnalati gli arrivi e le partenze dei clienti tramite un'icona apposita."
    }, {
        question: "Come modifico la mappa?",
        answer: "Puoi modificare la mappa in qualsiasi momento dal builder spiaggia.",
        link: "builder"
    }, {
        question: "È possibile ricercare velocemente una prenotazione sulla mappa?",
        answer: "Sì, è possibile ricercare le prenotazioni attraverso la ricerca intelligente",
        link: 'ricerca'
    }, {
        question: "La mia spiaggia ha una conformazione particolare, è possibile replicarla?",
        answer: "Certo. Con Beacharound Business puoi personalizzare la mappa a tuo piacimento. Per richieste particolari il nostro team sarà sempre a tua disposizione."
    }, {
        question: "Posso cambiare la numerazione dei prodotti/ombrelloni a mio piacimento ?",
        answer: "Sì, tramite il builder puoi personalizzare la numerazione di tutti i tuoi prodotti."
    }, {
        question: "La mia spiaggia non ha la sabbia, si può cambiare lo sfondo?",
        answer: "È possibile cambiare lo sfondo selezionando la tipologia più adatta."
    }, {
        question: "Devo costruire la mappa della spiaggia in autonomia oppure potete farlo per me?",
        answer: "Puoi creare la mappa in autonomia e modificarla in qualsiasi momento. Se preferisci, il nostro team può crearla e personalizzarla a tuo piacimento."
    }]
}, {
    title: 'Planner',
    subtitle: "Gestire le prenotazioni non è mai stato così semplice!",
    paragraphs: [{
        title: "L'organizzazione che fa la differenza",
        text: "Il planner di Beacharound Business è stato realizzato per consentirti di risparmiare tempo nel gestire le prenotazioni quotidianamente!</br>Con il planner spiaggia interattivo puoi gestire le prenotazioni con una prospettiva temporale più ampia e quindi vedere a colpo d'occhio il loro stato nel tempo.</br>Trovare facilmente posto per i tuoi clienti sarà per te immediato. Il planner ti permetterà di ottimizzare l'organizzazione delle prenotazioni e massimizzare la capienza della tua spiaggia.</br>Dal planner è possibile gestire le prenotazioni, aggiungerne di nuove e modificare quelle esistenti.</br>Il planner può essere organizzato per file o per settori, con la possibilità di filtrare le prenotazioni a piacimento.",
        image: "/images/frontend/beaches/planner-spiaggia.png",
    }],
    faq: [{
        question: "Posso effettuare degli spostamenti (cambio di ombrellone) direttamente dal planner?",
        answer: "Certo. Il planner interattivo di Beacharound Business consente una gestione facilitata degli spostamenti."
    }, {
        question: "È possibile controllare lo stato dei pagamenti dal planner?",
        answer: "Sì, sul planner le prenotazioni assumono un colore diverso in base allo stato dei pagamenti."
    }, {
        question: "Posso modificare date di check-in e check-out dal planner?",
        answer: "Sì, potrai prolungare o ridurre una prenotazione cambiandone le date con un semplice click."
    }, {
        question: "Posso creare prenotazioni stagionali sul planner?",
        answer: "Ovviamente! Potrai inserire tutti i tipi di prenotazioni, anche quelle stagionali, direttamente dal planner."
    }, {
        question: "Posso creare modifiche ad una prenotazione?",
        answer: "Dal planner di Beacharound Business si possono effettuare modifiche di ogni genere: dall'aggiunta di prodotti, al cambio di postazione. Tutto in pochi istanti."
    }]
}, {
    title: 'Programmazione automatica',
    subtitle: "Massimizza la capienza della tua spiaggia!",
    paragraphs: [{
        title: "Risparmia tempo con la programmazione automatica",
        text: "L’ombrellone desiderato dal tuo cliente non è disponibile per tutte le date richieste? Niente paura!</br>Beacharound Business programmerà lo spostamento giorno per giorno sugli ombrelloni liberi più vicini, in completa autonomia!</br>Risparmia tempo nella ricerca di postazioni libere e massimizza la capienza della tua spiaggia con un sistema organizzato e semplicissimo da utilizzare.</br>Se lo spostamento deve soddisfare desideri particolari o preferisci soluzioni differenti da quella proposta dal tuo gestionale, potrai sempre impostarlo manualmente.",
        image: "/images/frontend/beaches/programmazione-automatica.png"
    }, {
        title: "Ricerca disponibilità per le date richieste",
        text: "Difficoltà nel trovare una postazione libera per le date richieste dal tuo cliente? Inserisci check-in e check-out e trova in pochi istanti l’ombrellone libero per tutte le date corrispondenti. Il sistema è stato studiato per permetterti di trovare la postazione libera per tutta la durata del soggiorno del tuo cliente. Una soluzione perfetta quando vuoi evitare spostamenti di ombrellone e risparmiare tempo!",
        image: "/images/frontend/beaches/programmazione-disponibilita.png"
    }],
    faq: [{
        question: "Se la prenotazione viene spostata su altri prodotti, il prezzo resta invariato?",
        answer: "No, il sistema ricalcola automaticamente il prezzo basandosi sul listino del catalogo. Il prezzo può comunque essere modificato manualmente in qualsiasi momento."
    }, {
        question: "Posso sapere in anticipo se un ombrellone sarà occupato?",
        answer: "Sì, puoi farlo consultando il planner o selezionando la postazione interessata sulla mappa. Nel dettaglio di un prodotto non prenotato viene segnalata una eventuale prenotazione imminente."
    }]
}, {
    title: 'Spostamenti',
    subtitle: "Gestisci gli spostamenti con un click!",
    paragraphs: [{
        title: "Gomma e matita saranno solo un brutto ricordo",
        text: "È possibile effettuare spostamenti in qualsiasi momento per soddisfare le esigenze del cliente durante una o più giornate della prenotazione. Qualora si sposti il cliente in un prodotto differente, il sistema ti notificherà la possibilità di variare o mantenere il prezzo corrente.</br>Per effettuare uno spostamento basterà cliccare sulla postazione interessata e tramite il pulsante \"Cosa vuoi fare?\", selezionare \"Spostamento\".</br>Verrà mostrata una mappa con la posizione della prenotazione a seconda del giorno. Da qui potrai scegliere le date, le postazioni in cui spostare il cliente e variare il prezzo se necessario!",
        image: "/images/frontend/beaches/spostamenti-mappa.png"
    }],
    faq: [{
        question: "Posso vedere gli spostamenti in una singola prenotazione?",
        answer: "Sì, è possibile farlo dal dettaglio della prenotazione, nella sezione \"Programmazione\", o dal planner."
    }, {
        question: "Per spostare una prenotazione devo cancellarla e rifarla?",
        answer: "No, puoi semplicemente effettuare uno spostamento nei giorni desiderati."
    }, {
        question: "Posso effettuare un cambio di ombrellone anche su una prenotazione in corso?",
        answer: "Sì, puoi effettuare un cambio di postazione anche su una prenotazione già in corso."
    }]
}, {
    title: 'Prenotazioni e pagamenti online',
    subtitle: "Raggiungi migliaia di possibili clienti e sfrutta il 100% della tua spiaggia!",
    paragraphs: [{
        title: "Permetti ai clienti di prenotare il proprio ombrellone comodamente da casa",
        text: "Le prenotazioni online sono una soluzione perfetta per riempire eventuali postazioni invendute, soprattutto in bassa stagione, ed aumentare il rendimento dello stabilimento.</br>Puoi ricevere prenotazioni online dove e quando desideri: sulla porzione di spiaggia che selezioni, sulle singole postazioni che decidi di attivare oppure su tutta la spiaggia, nei periodi che prestabilisci.</br>Con le prenotazioni online puoi incassare subito e senza perdite di tempo. Attiva le prenotazioni online e aumenta il tuo fatturato!",
        image: "/images/frontend/beaches/prenotazioni-online.png"
    }, {
        title: "Ricevi pagamenti online immediati e sicuri!",
        text: "Con le prenotazioni online di Beacharound Business puoi incassare subito i pagamenti evitando di dover registrare e far pagare in loco il cliente. Tutte le transazioni delle prenotazioni online che riceverai verranno automaticamente caricate sul tuo conto. Grazie a questo procedimento avrai un flusso di pagamenti garantiti per tutta la stagione!",
        image: "/images/frontend/beaches/pagamenti-online.png"
    }],
    faq: [{
        question: "Quando e come ricevo i soldi dei pagamenti online?",
        answer: "Beacharound Business offre la possibilità di farti ricevere i soldi delle prenotazioni online nell'esatto momento in cui il cliente pagherà la prenotazione."
    }, {
        question: "Posso decidere io quando ricevere le prenotazioni e su quali ombrelloni?",
        answer: "Sì, puoi scegliere sia il periodo specifico in cui vuoi ricevere prenotazioni online, sia gli ombrelloni su cui vuoi attivarle."
    }, {
        question: "Come possono pagare i clienti che prenotano un ombrellone online?",
        answer: "I clienti pagano attraverso i principali circuiti di carte di credito e prepagate (Mastercard, Visa, American Express, PostePay, ecc.)."
    }, {
        question: "Come funzionano i rimborsi?",
        answer: "Beacharound Business non gestisce i rimborsi. Il bagnino può gestirli a sua totale discrezione, tramite buoni o rimborsi in loco."
    }, {
        question: "Come mi registro?",
        answer: "Ti basterà attivare il profilo di fatturazione, andare in \"Impostazioni/Payout automatici\" e seguire  tutti i passaggi.</br>Se riscontri difficoltà puoi chiedere aiuto all’assistenza, ti guideremo nel processo di registrazione."
    }]
}, {
    title: 'Ordinazione cibo',
    subtitle: "Permetti ai clienti di ordinare comodamente il cibo da sotto l’ombrellone",
    paragraphs: [{
        title: "Offri un servizio completo ed efficiente ai tuoi clienti",
        text: "Beacharound Business fornisce un sistema di ordinazione per bar/ristoranti utile per gestire gli ordini dei clienti direttamente dall'ombrellone. I tuoi clienti avranno a disposizione un menù digitale che permetterà loro di ordinare ciò che desiderano evitando code al bar.</br>Massimizza i tuoi guadagni e sfrutta le postazioni sotto l'ombrellone come se fossero tavoli extra.</br>Aumenta il fatturato del tuo bar/ristorante e offri un miglior servizio ai tuoi clienti.",
        image: "/images/frontend/beaches/ordinazione-cibo.png"
    }],
    faq: [{
        question: "Come vengono gestite le ordinazioni?",
        answer: "Il cliente potrà scegliere i prodotti dal menù del lido, pagare e inviare l'ordinazione. Gli ordini verrano quindi notificati agli operatori con un riepilogo degli stessi."
    }, {
        question: "Posso sfruttare le postazioni/ombrelloni come se fossero tavoli in più?",
        answer: "Certamente. Per aumentare il fatturato della tua attività potrai ricevere più ordini anche dai clienti che decidono di ordinare pranzo o aperitivi direttamente dal loro ombrellone."
    }]
}, {
    title: 'Anagrafiche e registro presenze',
    subtitle: "Visualizza i dettagli dei tuoi clienti!",
    paragraphs: [{
        title: "Non dimenticarti di nessun cliente",
        text: "L’area \"Clienti\" permette di visualizzare i dettagli dei tuoi clienti e di inserire, modificare o cancellare in qualsiasi momento un’anagrafica registrata.</br>Tutti i clienti, da quelli inseriti da te in fase di prenotazione a quelli che prenotano online (nel caso tu abbia attivato le prenotazioni online), vengono registrati in questa sezione.</br>Le anagrafiche potranno esserti utili per poter informare i tuoi clienti di eventi, offerte o iniziative dello stabilimento.</br>Puoi ricercare uno specifico cliente e vedere tutte le sue prenotazioni nella sezione \"dettaglio\".</br>L’area clienti sarà sempre aggiornata e potrà esserti utile come strumento di prevenzione e tracciamento anti Covid-19.",
        image: "/images/frontend/beaches/anagrafiche-clienti.png"
    }],
    faq: [{
        question: "Come faccio a trovare il cliente che mi interessa?",
        answer: "Nella sezione \"Clienti\" è presente una barra di ricerca per trovare velocemente il cliente che cerchi e le sue prenotazioni. Viceversa, è possibile trovare le prenotazioni effettuando una ricerca tramite il nome del cliente."
    }, {
        question: "A cosa serve questa funzione?",
        answer: "Le anagrafiche clienti e il registro presenze sono uno strumento molto utile per salvare i recapiti dei clienti. Potrai inoltre sfruttare questi contatti per inviare loro promozioni o comunicazioni."
    }]
}, {
    title: 'Statistiche avanzate',
    subtitle: "Analizza il rendimento del tuo business!",
    paragraphs: [{
        title: "Tutti i dati sempre a tua disposizione",
        text: "Analizza il rendimento del tuo business grazie alle statistiche di vendita! Tieni sotto controllo informazioni come il tuo fatturato e il valore della produzione. Monitora i prodotti più prenotati e i servizi preferiti dai tuoi clienti nel corso del tempo.</br>Tutti i dati possono essere filtrati per periodo: ultimo anno, ultimo semestre, ultimo mese, ultimi sette giorni, e così via!",
        image: "/images/frontend/beaches/statistiche-spiaggia.png"
    }],
    faq: [{
        question: "Posso scaricare questi dati?",
        answer: "Sì, puoi scaricare un pdf dei grafici tramite l'apposito pulsante."
    }, {
        question: "A cosa servono le statistiche?",
        answer: "Le statistiche di vendita sono molto utili per valutare nel dettaglio il rendimento dello stabilimento."
    }]
}, {
    title: 'Catalogo e listino prezzi',
    subtitle: "Scegli tra un’infinità di prodotti disponibili e personalizzabili. Puoi combinarne di tutti i colori!",
    paragraphs: [{
        title: "Crea e gestisci il catalogo della tua spiaggia",
        text: "Crea i prodotti della tua spiaggia dalla sezione “catalogo” del gestionale. Scegli la tipologia di prodotto e aggiungi tutte le informazioni necessarie!</br>Dal catalogo potrai inoltre impostare i parcheggi - se presenti - e i servizi di spiaggia.",
        image: "/images/frontend/beaches/catalogo-prodotti.png"
    }, {
        title: "Gestisci e personalizza il tuo listino prezzi",
        text: "Aggiungi al tuo listino variazioni di prezzo per i periodi che desideri, come le festività o i weekend. Puoi creare delle combinazioni con i prodotti del tuo catalogo per facilitare i calcoli dei prezzi in fase di prenotazione!",
        image: "/images/frontend/beaches/listino-prezzi.png"
    }],
    faq: [{
        question: "I clienti possono vedere i prodotti del mio listino?",
        answer: "Sì, puoi pubblicare i prodotti che vuoi mostrare sulla pagina del lido con l'apposito tasto nel dettaglio del prodotto."
    }, {
        question: "Il prezzo dei miei ombrelloni varia a seconda della fila, posso personalizzare i prezzi?",
        answer: "Se gli ombrelloni hanno prezzi diversi a seconda della fila basterà creare un ombrellone con il numero di fila ed assegnargli il prezzo. Una volta fatto, basterà posizionarlo nel builder nella fila opportuna."
    }, {
        question: "Posso avere prezzi diversi su un prodotto per prenotazioni online e non?",
        answer: "Sì, il catalogo di Beacharound Business ti permette di creare listini doppi per ogni prodotto. In questo modo puoi decidere quanto un prodotto può costare a seconda del tipo di prenotazione."
    }, {
        question: "È possibile fare degli sconti ai clienti?",
        answer: "Certo, potrai farlo in fase di prenotazione."
    }]
}, {
    title: 'Magazzino e disponibilità',
    subtitle: "Magazzino sempre aggiornato con Beacharound Business!",
    paragraphs: [{
        title: "Tieni sotto controllo la disponibilità dei tuoi prodotti",
        text: "Grazie a questa funzione puoi tenere sotto controllo le disponibilità di tutti i tuoi prodotti. Clicca l'apposito pulsante in mappa per verificare giornalmente la disponibilità dei prodotti in catalogo: vengono indicate giorno per giorno!</br>Questo strumento aiuta ad evitare problemi legati alla gestione della disponibilità di lettini e sdraio soprattutto in alta stagione.",
        image: "/images/frontend/beaches/disponibilita-prodotti.png"
    }],
    faq: [{
        question: "Posso modificare la disponibilità durante la stagione?",
        answer: "Le disponibilità dei prodotti possono essere modificate in qualunque momento."
    }, {
        question: "Il calcolo della disponibilità tiene conto anche dei prodotti prenotati online?",
        answer: "Certamente. Tutte le quantità dei prodotti prenotati vengono scalate dalla disponibilità di magazzino."
    }]
}, {
    title: 'Calendario arrivi e partenze',
    subtitle: "Monitora arrivi e partenze direttamente dalla tua mappa!",
    paragraphs: [{
        title: "Spiaggia sempre in ordine? Organizzala in anticipo",
        text: "Organizza in anticipo la tua spiaggia grazie ad un calendario arrivi e partenze sempre aggiornato.</br>Puoi monitorare giorno per giorno i check-in e i check-out dei tuoi clienti e predisporre anticipatamente gli ombrelloni evitando code alla reception!",
        image: "/images/frontend/beaches/calendario-arrivi-partenze.png"
    }],
    faq: [{
        question: "Posso fare una prenotazione su ombrelloni che hanno clienti in partenza?",
        answer: "Sì, il sistema di arrivi e partenze consente di gestire le postazioni che hanno clienti in partenza."
    }, {
        question: "Gli arrivi e le partenze sono scaricabili?",
        answer: "Sì, cliccando l’apposito tasto puoi scaricare i dati degli arrivi e delle partenze del giorno corrente."
    }]
}, {
    title: 'Stampa mappa',
    subtitle: "Stampa la mappa della tua spiaggia giorno per giorno!",
    paragraphs: [{
        title: "La tua mappa sempre a portata di mano",
        text: "Clicca il tasto \"Stampa\" per stampare la mappa della tua spiaggia ed avere a disposizione le postazioni numerate con date di check- in e check-out, nome e cognome del cliente e prodotti prenotati. Per ottenere una stampa omogenea consigliamo di impostare il documento in orizzontale, con una scala minore per le spiagge molto grandi.",
        image: "/images/frontend/beaches/stampa-mappa.png"
    }],
    faq: [{
        question: "Posso utilizzare la stampa per preparare la spiaggia?",
        answer: "Sì, la stampa contiene tutte le informazioni necessarie per preparare la spiaggia."
    }, {
        question: "Si può stampare la mappa per più giorni?",
        answer: "Per avere una panoramica su più giorni puoi stampare direttamente il planner."
    }]
}, {
    title: 'Esportazione dati',
    subtitle: "Esporta i dati con un click!",
    paragraphs: [{
        title: "Scarica i dati per averli sempre a disposizione",
        text: "I dati del gestionale sono scaricabili in formato tabellare (.csv). Potrai scaricare i dati per fare le tue valutazioni sul rendimento dello stabilimento o per tenerli come storico.",
        image: "/images/frontend/beaches/esportazione-dati.png"
    }],
    faq: [{
        question: "Posso scaricarli in autonomia o devo rivolgermi all'assistenza?",
        answer: "Puoi esportare i dati in totale autonomia premendo il tasto con la freccia in giù a fianco delle tabelle."
    }, {
        question: "Che programma devo usare per aprire questi file?",
        answer: "Consigliamo di aprire questi file con Microsoft Excel o programmi analoghi."
    }]
}, {
    title: 'Ricerca intelligente',
    subtitle: "Trova le prenotazioni che cerchi!",
    paragraphs: [{
        title: "Sfrutta la ricerca intelligente della mappa",
        text: "La ricerca intelligente di Beacharound Business ti permette di trovare tutto quello che cerchi all'interno del gestionale, dalle prenotazioni ai clienti, con un click! Alla ricerca sono abbinati dei filtri che ti permetteranno di trovare rapidamente tutte le informazioni che cerchi. Evita fastidiose file all’ingresso, non dovrai far altro che cercare la prenotazione direttamente in mappa e far accomodare il cliente.",
        image: "/images/frontend/beaches/ricerca-intelligente.png"
    }],
    faq: [{
        question: "Posso cercare le prenotazioni direttamente in mappa?",
        answer: "Sì, puoi cercare le prenotazioni dalla barra di ricerca per nome, cognome e/o email."
    }, {
        question: "Posso cercare una prenotazione di cui conosco solo le date?",
        answer: "Sì, puoi filtrare le prenotazioni anche per date di check-in e check-out."
    }, {
        question: "È possibile trovare una prenotazione sapendo solo il nome o il cognome del cliente?",
        answer: "Sì, la ricerca intelligente di Beacharound Business è in grado di trovare le prenotazioni anche tramite il filtro nome e/o cognome."
    }]
}, {
    title: 'Paga con Beacharound',
    subtitle: "Richiedi acconti e pagamenti anticipati con Beacharound Business!",
    paragraphs: [{
        title: "Dí addio allo stress, incassa in anticipo",
        text: "Richiedi ai tuoi clienti acconti e pagamenti anticipati con un click. Invia la richiesta di pagamento direttamente dal tuo gestionale e incassa in anticipo: il cliente non dovrà far altro che inserire i dati della sua carta e pagare, tutto in totale sicurezza e trasparenza.</br>Evita il problema dei clienti fantasma e dí addio allo stress!",
        image: "/images/frontend/beaches/pagamenti-beacharound.png"
    }],
    faq: [{
        question: "Posso richiedere un pagamento online per qualsiasi tipo di prenotazione?",
        answer: "Sì, il pagamento online può essere richiesto a tutti i clienti."
    }, {
        question: "Se un cliente prenota al telefono ma vuole pagare online, posso inviargli la richiesta di pagamento?",
        answer: "Sì, anche se la prenotazione viene inserita manualmente dal gestionale è possibile far pagare online."
    }, {
        question: "Posso richiedere degli acconti ai miei clienti?",
        answer: "Certamente. Il pagamento con Beacharound Business consente di richiedere acconti ai tuoi clienti. Le prenotazioni in attesa del saldo verranno sempre segnalate."
    }]
}, {
    title: 'Condivisione web e social',
    subtitle: "Sfrutta tutti i canali a tua disposizione!",
    paragraphs: [{
        title: "Ricevi prenotazioni dai canali social e dal tuo sito web",
        text: "Genera con un click i link da inserire nel tuo sito web e nelle tue pagine social e permetti a tutti i tuoi clienti di prenotare online il proprio ombrellone anche dai tuoi canali diretti! Sfrutta questo strumento per condurre i tuoi visitatori direttamente alla pagina del tuo stabilimento su Beacharound e ricevi più prenotazioni!",
        image: "/images/frontend/beaches/condivisione-social.png"
    }],
    faq: [{
        question: "Dove posso mettere questi link?",
        answer: "I link generati funzionano su qualsiasi pagina social o sito web."
    }, {
        question: "A cosa serve questa funzione?",
        answer: "È uno strumento utile per condurre i tuoi followers o visitatori direttamente alla pagina dello stabilimento su Beacharound e ricevere prenotazioni."
    }]
},
{
    title: 'Compatibile con stampanti fiscali',
    subtitle: "Stampa direttamente i tuoi documenti fiscali senza dover trascrivere manualmente le transazioni!",
},
{
    title: 'Servizi di spiaggia e voucher alberghieri',
    subtitle: "Guadagna con i tuoi servizi e gestisci i voucher alberghieri evitando perdite di tempo!",
    paragraphs: [{
        title: "Gestisci i tuoi servizi extra direttamente dal catalogo",
        text: "Noleggi pedalò o attrezzature sportive? Con Beacharound Business puoi gestire i servizi offerti dalla tua spiaggia direttamente dal catalogo prodotti!</br>I servizi offerti dal tuo stabilimento potranno essere inseriti in fase di prenotazione con prezzi orari, giornalieri o una tantum.</br>Non solo attrezzature sportive!</br>Hai un’area riservata ad auto, moto o biciclette? Beacharound Business ha pensato a tutto!</br>Potrai inserire la tua disponibilità direttamente dal catalogo. I tuoi clienti potranno aggiungere il parcheggio alla loro prenotazione!",
        image: "/images/frontend/beaches/servizi-spiaggia.png"
    }, {
        title: "Hotel convenzionati? Gestisci le prenotazioni con un click",
        text: "Dalle prenotazioni di Beacharound Business puoi gestire i voucher alberghieri: puoi indicare quali postazioni hanno convenzioni con gli hotel e riservarle, impedendone così la prenotazione online, qualora fosse stata attivata. Puoi aggiungere i dati relativi all’hotel dove alloggia il cliente anche in fase di prenotazione!",
        image: "/images/frontend/beaches/voucher-alberghieri.png"
    }],
    faq: [{
        question: "Si possono mettere prezzi orari o giornalieri ai servizi?",
        answer: "Sì, è possibilie assegnare un prezzo giornaliero, orario o <i>una tantum</i> a servizi e parcheggi"
    }, {
        question: "Posso cambiare il prezzo dei servizi in alta stagione?",
        answer: "Certo. Anche nei servizi è possibile aggiungere variazioni di prezzo per periodo."
    }]
}].map(item => {

    const rawSlug = item.title.replace(/\s/g, "-").replace('à', 'a').replace(',', '').toLocaleLowerCase();
    const slug = `gestionale-spiaggia-${rawSlug}`;

    return {
        ...item,
        slug,
        icon: `/images/frontend/beaches/icons/${rawSlug}-beacharound.png`,
        seo: {
            title: `${item.title}`,
            description: `${item.subtitle}`
        }
    }
});

export default features;