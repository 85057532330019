import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { MainContainer, MainTitle, TitleContainer, ColumnContainer, Column, Card, LoadingContainer, HeadSeo, Breadcrumbs } from '@beacharound/beacharound-ui';

import { getArticles } from '../../api/blog';
import { useQuery } from 'react-query';

export default function Tutorial({match}) {

    const { data: tutorials, isLoading } = useQuery(["blog"], () =>
        getArticles({params: {params: {type: 'business', section: 'tutorial'}}})
    )

    return (
        <Fragment>

            <HeadSeo
                isBusiness
                title={["Tutorial Beacharound Business"]}
                description="Scopri come usare al meglio gli strumenti Beacharound Business."
                type="Blog"
            />

            <MainContainer large lowMargin>
                <TitleContainer>
                    <Breadcrumbs fullUrl={match.url} names={['tutorial']} linkComponent={Link}/>
                </TitleContainer>
                <TitleContainer>
                    <MainTitle text="I Tutorial di Beacharound" large component="h1"/>
                </TitleContainer>
            </MainContainer>

            <MainContainer large>
                <LoadingContainer isLoading={isLoading}>
                    <ColumnContainer>
                        {tutorials?.map((item, index) => {

                            return (
                                <Column column={2} key={index}>
                                    <Card
                                        noBorder
                                        title={item.name}
                                        subtitle={item.subtitle}
                                        image={item.cover}
                                        component={Link}
                                        to={`${match.url}/${item.slug}`}
                                        highImage
                                    />  
                                </Column> 
                            )
                        })}
                    </ColumnContainer>
                </LoadingContainer>
            </MainContainer>

        </Fragment>
    )
}