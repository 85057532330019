import axios from './_defaults/v2/user';

export async function getNotificationsCount({ activityId }) {
    const response = await axios.get(`/notifications/${activityId}/count`);

    if (response.data) {
        return response.data
    }

    return response
}

export async function getNotifications({ activityId, params }) {
    try {
        const response = await axios.get(`/notifications/${activityId}`, {
            params
        });

        return response?.data;
    } catch (error) {
        if (error.response?.status === 404) {
            return []
        }
        return error;
    }
}

export async function viewNotifications({ activityId, data }) {
    try {
        const response = await axios.post(`/notifications/${activityId}/view`, data);

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        throw error;
    }
}

// Settings
export async function getNotificationsSettigs({ activityId, params }) {
    try {
        const response = await axios.get(`/notifications/${activityId}/settings`, {
            params
        });

        return response?.data;

    } catch (error) {
        if (error.response?.status === 404) {
            return [];
        }
        throw error;
    }
}

export async function createNotificationsSetting({ activityId, data }) {
    try {
        const response = await axios.post(`/notifications/${activityId}/settings`, data);
        return response?.data;

    } catch (error) {
        throw error;
    }
}

export async function removeNotificationsSetting({ activityId, data }) {
    try {
        const response = await axios({
            method: "DELETE",
            url: `/notifications/${activityId}/settings`,
            data
        });
        return response?.data;

    } catch (error) {
        throw error;
    }
}