export const version = "1";
export const title = `CONDIZIONI GENERALI PER LA CONCESSIONE IN USO DELLA LICENZA BEACHAROUND BUSINESS`;

export const condition = `1. OGGETTO
<br />
Il presente contratto ha per oggetto la concessione in uso di:
Applicativo “Beacharound Business” per stabilimenti balneari, attraverso il quale il Gestore della struttura balneare può pubblicizzare i servizi offerti, inserire direttamente le prenotazioni ricevute e ricevere le prenotazioni effettuate dal sito www.beacharound.com , 
Servizio di Gestione preventivi tramite Beacharound.com per hotel e strutture similari, attraverso il quale il Gestore della struttura alberghiera può pubblicizzare i servizi offerti e ricevere richieste di preventivi dagli utenti registrati sul sito www.beacharound.com. 
Con la sottoscrizione del presente contratto, il Gestore dichiara di essere il legittimo utilizzatore in base ad un titolo giuridico valido ed efficace della struttura balneare o alberghiera indicata. 
Beacharound s.r.l. si riserva ogni opportuna verifica presso le competenti Autorità, segnalando eventuali dichiarazioni sospette e/o mendaci, con riserva di sospendere immediatamente il servizio.
<br />
<br />
2. PROPRIETA’
<br />
L’applicativo “BEACHAROUND BUSINESS” è di esclusiva proprietà di Beacharound s.r.l., con sede a Cattolica (RN) – via Renzi n. 7 (P. IVA: 04513860405). 
Con la sottoscrizione del presente contratto, il Gestore è autorizzato esclusivamente all’utilizzo temporaneo e secondo le presenti condizioni d’uso, senza che tale utilizzo determini in alcun modo la cessione della proprietà dell’applicativo stesso al Gestore.
<br />
<br />
3. OBBLIGO DI REGISTRAZIONE
<br />
L’utilizzo del servizio acquistato richiede la successiva registrazione al sito www.business.beacharound.com, previa ricezione della mail con le indicazioni per concludere la relativa procedura, secondo le “Condizioni generali di registrazione e di adesione ai pacchetti di Beacharound.com”.
<br />
<br />
4. CORRISPETTIVO E MODALITA’ DI PAGAMENTO
<br />
Il Gestore si impegna e si obbliga a versare il corrispettivo pattuito in un’unica soluzione, secondo le modalità indicate nel frontespizio del presente contratto ovvero nell’apposita procedura di acquisto presente sul sito www.business.beacharound.com . 
Il servizio potrà essere utilizzato solo a seguito della registrazione del pagamento da parte di Beacharound s.r.l.-
Il pagamento del servizio acquistato non riguarda le singole prenotazioni effettuate e/o pagate attraverso www.beacharound.com, per le quali si deve fare riferimento all’art. 3 “GESTIONE DELLE PRENOTAZIONI E DEI PAGAMENTI ATTRAVERSO BEACHAROUND.COM” delle “Condizioni generali di registrazione e di adesione ai pacchetti di Beacharound.com”. 
<br />
<br />
5. DURATA 
<br />
La concessione in uso dell’applicativo avrà la durata indicata nel frontespizio del presente contratto. 
Alla scadenza contrattuale non è previsto alcun rinnovo automatico. 
<br />
<br />
6. DIVIETO DI CESSIONE DEL CONTRATTO
<br />
Il presente contratto non può essere ceduto a terzi. 
<br />
<br />
7. LEGGE APPLICABILE E FORO COMPETENTE
<br />
Il contratto relativo all’acquisto dell’applicativo è interamente regolato dalle presenti condizioni e dalla Legge italiana.
In caso di controversie relative all’interpretazione, all’applicazione e all’esecuzione del presente contratto, è competente in via esclusiva il Foro di Rimini. 
<br />
<br />
8. CONTATTI
<br />
Per qualsiasi informazione, sarà possibile contattare il Servizio di assistenza al seguente indirizzo mail:info@beacharound.com e/o consultare le F.A.Q. presenti sul sito www.business.beacharound.com .`

export const checks = [{
    name: "management_contract",
    label: "L'utente dichiara di aver preso visione e di accettare le condizioni generali di concessione in uso della licenza Beacharound Business."
}, {
    name: "management_assignment",
    label: "Ai sensi degli artt. 1341 e 1342 c.c., si approvano specificamente e per iscritto le seguenti clausole: art. 6. (Divieto di cessione del contratto), art. 7. (Legge applicabile e Foro competente)."
}]