import { lazy } from 'react';

import MainLayout from '../layouts/MainLayout/MainLayout';

const TransactionsList = lazy(() => import('../pages/Beach/Transactions/TransactionsList.js'));
const TransactionDetail = lazy(() => import('../pages/Beach/Transactions/TransactionDetail.js'));

export default [
    {
        path: "",
        component: TransactionsList,
        layout: MainLayout,
        exact: true,
        analytics: { name: ["Lista"] },
    },
    {
        path: "/:transactionId",
        component: TransactionDetail,
        layout: MainLayout,
        exact: true,
        analytics: { name: ["Dettaglio Transazione"] }
    }].map((route) => ({
        ...route,
        path: `/beaches/:beachId/transactions${route.path}`,
        private: true,
        permissions: { memberships: ["MANAGEMENT", "BOOKING"] },
        analytics: {
            name: ["Transazioni", ...(route.analytics?.name || [])]
        }
    }));