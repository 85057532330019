import { lazy } from 'react';

import MainLayout from '../layouts/MainLayout/MainLayout';

const PrintersDashboard = lazy(() => import('../pages/Beach/Printers/PrintersDashboard'));
const ReceiptsFiscalList = lazy(() => import('../pages/Beach/Printers/Receipts/ReceiptsFiscalList'));
const ReceiptsNonFiscalList = lazy(() => import('../pages/Beach/Printers/Receipts/ReceiptsNonFiscalList'));
const TransaciontsList = lazy(() => import('../pages/Beach/Printers/Receipts/TransaciontsList'));
const ReceiptsDetail = lazy(() => import('../pages/Beach/Printers/Receipts/ReceiptsDetail'));
const ReceiptsCreation = lazy(() => import('../pages/Beach/Printers/Receipts/ReceiptsCreation'));

export default [
    {
        path: `/creation/:receiptId`,
        exact: true,
        component: ReceiptsCreation,
        analytics: { name: ["Scontrini", "Creazione"] }
    },{
        path: `/receipts/:receiptId`,
        exact: true,
        component: ReceiptsDetail,
        analytics: { name: ["Scontrini", "Dettaglio"] }
    },{
        path: "",
        component: PrintersDashboard,
        layout: MainLayout,
        routes: [
            {
                path: `/fiscal`,
                exact: true,
                component: ReceiptsFiscalList,
                analytics: { name: ["Fiscali"] }
            },{
                path: `/non-fiscal`,
                exact: true,
                component: ReceiptsNonFiscalList,
                analytics: { name: ["Non fiscali"] }
            },{
                path: `/transactions`,
                exact: true,
                component: TransaciontsList,
                analytics: { name: ["Transazioni"] }
            }
        ].map((route) => {
            return {
                ...route,
                path: `/beaches/:beachId/printers${route.path}`,
                private: true,
                analytics: { name: ["Scontrini", ...(route.analytics?.name || [])] },
                permissions: { memberships: ["MANAGEMENT"] },
            };
        })
}].map((route) => {
    return {
        ...route,
        path: `/beaches/:beachId/printers${route.path}`,
        private: true,
        permissions: { memberships: ["MANAGEMENT"] },
        analytics: {
            name: ["Stampanti", ...(route.analytics?.name || [])],
        },
    };
});