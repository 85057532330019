import axios from './_defaults/v2/user';
import { v4 as uuidv4 } from 'uuid';
import { epsonPrintReport } from './printers/epson';

export async function createPrinter({data, activityId}) {
    const response = await axios.post(`/printers/${activityId}`, {...data});

    if (response.data) {
        return response.data
    }

    return response
}

export async function getPrinters({activityId, params}) {
    try {
        const response = await axios.get(`/printers/${activityId}`, {
            params
        });

        return response?.data;
    } catch(error) {
        if(error.response?.status === 404) {
            return []
        }
        return error;
    }
}

export async function getPrinter({ activityId, printerId }) {
    try {
        const response = await axios.get(`/printers/${activityId}/${printerId}`);

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function updatePrinter({activityId, printerId, data}) {
    try {
        const response = await axios.post(`/printers/${activityId}/${printerId}`, data);
        return response?.data;
    } catch(error) {
        throw error;
    }
}

export async function deletePrinter({ activityId, printerId }) {
    try {
        const response = await axios.delete(`/printers/${activityId}/${printerId}`);

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        throw error;
    }
}

export async function addMainDeviceToPrinter({ activityId, printerId }) {
    try {

        let deviceId = localStorage.getItem("baMainDeviceId");

        if(!deviceId) {
            // Creiamo un id random per questo dispositivo
            // lo salviamo nel local storage
            // sucessivamente lo carichiamo sulla stampante
            deviceId = uuidv4();
            localStorage.setItem("baMainDeviceId", deviceId);
        }

        const response = await axios.post(`/printers/${activityId}/${printerId}/main-device`, {mainDevice: deviceId});

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        throw error;
    }
}

export async function removeMainDeviceToPrinter({ activityId, printerId }) {
    try {
        const response = await axios.delete(`/printers/${activityId}/${printerId}/main-device`);

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        throw error;
    }
}

export async function createPrinterReport({activityId, printerId}) {
    try {
        const response = await axios.post(`/printers/${activityId}/${printerId}/reports`, {
            date: new Date().toISOString()
        });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        throw error;
    }
}

export async function updatePrinterReport({activityId, printerId, reportId, data}) {
    try {
        const response = await axios.post(`/printers/${activityId}/${printerId}/reports/${reportId}`, data);

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        throw error;
    }
}

export async function printPrinterReport({activityId, printer}) {
    try {
        const report = await createPrinterReport({activityId, printerId: printer._id});
        try {
            await epsonPrintReport({report, printerUrl: printer.url, type: "Z"});
            await updatePrinterReport({activityId, printerId: printer._id, reportId: report._id, data: {status: "PRINT"}})
        } catch (error) {
            await updatePrinterReport({activityId, printerId: printer._id, reportId: report._id, data: {status: "FAILED"}})
            throw error;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
}