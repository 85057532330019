import { uploadImage } from './images';
import axios from './_defaults/user';

export async function createHotel({data}) {
    const response = await axios.post("/business-hotels", data);

    if (response.data) {
        return response.data
    }

    return response
}

export async function updateHotel({ data, elementId }) {
    const response = await axios.post(`/business-hotels/${elementId}`, data);

    if (response.data) {
        return response.data
    }

    return response
}

export async function getHotels({params}) {

    try {
        const response = await axios.get(`/business-hotels`, { params });

        if (response.data) {
            return response.data;
        }
        
    } catch(error) {

        if (error?.response?.status === 404) {
            return [];
        }

        throw error
    }
}

export async function getHotel({elementId}) {

    try {
        const response = await axios.get(`/business-hotels/${elementId}`);

        if (response.data) {
            return response.data
        }
    } catch(error) {
        console.log(error.response)
        throw error;
    }
    
}

export async function getPublicHotels({params}) {

    try {
        const response = await axios.get(`/business-hotel`, {params});

        if (response.data) {
            return response.data
        }
    } catch(error) {
        
        if(error.response.status === 404) return [];
        console.log(error.response);
    }
}

export async function getPutUrl({ elementId, file }) {
    const response = await axios.post(`/business-hotels/${elementId}/images`, {
        contentType: file.type,
        extension: file.name?.split(".").pop(),
    });

    if (response.data) {
        return response.data;
    }

    return response;
}

export async function uploadCover({ elementId, file }) {

    try {
        
        const { url, key } = await getPutUrl({ elementId, file });
        await uploadImage({ url, file });
        await updateHotel({
            elementId,
            data: {
                cover: {
                    url: key,
                },
            },
        });
        
    } catch (error) {
        console.log(error.response);
        throw error;
    }
}

export async function uploadGalleryImage({ elementId, file, index }) {
    try {
        const { url, key } = await getPutUrl({
            elementId,
            file,
            isGallery: true,
            galleryIndex: index,
        });

        await uploadImage({ url, file });

        const response = await updateHotel({
            elementId,
            data: {
                $addToSet: {
                    gallery: {
                        url: key,
                        index,
                    },
                },
            },
        });

        if (response.data) {
            return response.data;
        }

        return response;
    } catch (error) {
        throw error;
    }
}
