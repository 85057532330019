import { formatDate } from '@beacharound/beacharound-ui-helpers';
import { addMinutes, eachDayOfInterval, format, isBefore } from 'date-fns';

export function getStatusString(status) {
    if (!status) return "";

    switch (status) {
        case "PENDING":
            return "Da rispondere";
        case "ACCEPTED":
            return "Preventivo inviato";
        case "DECLINED":
            return "Richiesta rifiutata";
        case "CONFIRMED":
            return "Confermato";
        case "REJECTED":
            return "Rifiutato dall'utente";
        case "PAID":
            return "Pagato dall'utente";
        default:
            return "";
    }
}

export function getTransactionsStatusString({ status, paidAt, method }) {
    if (!status) return "";

    let dateString = paidAt ? `il ${formatDate(new Date(paidAt), "dd MMM yyyy")}` : "";
    let paymentString = method === "voucher" ? "Voucher segnalato" : "Pagato";

    switch (status) {
        case "PENDING":
            return "In attesa di pagamento";
        case "ACCEPTED":
            return `${paymentString} ${dateString}`;
        case "TRANSFERPENDING":
            return `${paymentString} ${dateString}`;
        case "TRANSFERED":
            return `${paymentString} ${dateString}`;
        case "REFOUNDED":
            return "Pagamento restituito";
        case "DECLINED":
            return "Pagamento rifiutato";
        case "CANCELED":
            return "Pagamento rifiutato";
        default:
            return "";
    }
}

export function getTransactionsShortStatusString(status) {
    if (!status) return "";

    switch (status) {
        case "PENDING":
            return "In attesa";
        case "ACCEPTED":
            return "Ricevuta"
        case "TRANSFERPENDING":
            return "Ricevuta"
        case "TRANSFERED":
            return "Trasferita"
        case "REFOUNDED":
            return "Restituita";
        case "DECLINED":
            return "Rifiutata";
        case "CANCELED":
            return "Rifiutata";
        default:
            return "";
    }
}

export function getTransactionsTypeString(status) {
    if (!status) return "";

    switch (status) {
        case "TOTAL":
            return "Saldo";
        case "DOWN":
            return "Caparra";
        case "REFOUND":
            return "Rimborso";
        default:
            return "";
    }
}

export function getTransactionsMethodString(method) {
    if (!method) return "";

    switch (method) {
        case "card":
            return "Carta / POS";
        case "cash":
            return "Contanti";
        case "transfer":
            return "Bonifico";
        case "check":
            return "Assegno";
        default:
            return "";
    }
}

export function getBeachesStatusString(status, options = {}) {
    if (!status) return "";

    const { isOnlineBeacharound, isPublicBeach, isFree, isPending, isExpired } = options;

    if (!isPublicBeach && isFree && status !== "CANCELED") {
        return "Prenotazione gratuita"
    }

    if (isExpired) {
        return "Scaduta"
    }

    if (isPending) {
        return "In attesa di conferma"
    }

    switch (status) {
        case "PENDING":
            return "In attesa";
        case "ACCEPTED":
            if (isPublicBeach) {
                return "Accesso non eseguito"
            }
            return "In attesa di pagamento";
        case "DECLINED":
            return "Rifiutato";
        case "CONFIRMED":
            return "Confermato";
        case "REJECTED":
            return "Rifiutato dall'utente";
        case "PAID":
            if (isOnlineBeacharound) {
                return "Prenotato online - pagato"
            }
            return "Pagato";
        case "ACCESSED":
            if (isOnlineBeacharound) {
                return "Prenotato online - accesso eseguito"
            }
            return "Accesso eseguito";
        case "PARTIALPAID":
            if (isOnlineBeacharound) {
                return "Prenotato online - Saldo mancante"
            }
            return "Saldo mancante";
        case "OVERPAID":
            if (isOnlineBeacharound) {
                return "Prenotato online - Importo da restituire"
            }
            return "Importo da restituire";
        case "CANCELED":
            if (isOnlineBeacharound) {
                return "Prenotato online - Annullata"
            }
            return "Annullata";
        default:
            return "-";
    }
}

export function getBeachesStatusColor(status, options = {}) {
    if (!status) return "";

    const { isFree, isExpired } = options;

    if (isFree) {
        return "grey"
    }

    if (isExpired) {
        return "red"
    }

    switch (status) {
        case "PENDING":
            return "red";
        case "ACCEPTED":
            return "red";
        case "DECLINED":
            return "red";
        case "CONFIRMED":
            return "red";
        case "REJECTED":
            return "red";
        case "PAID":
            return "green";
        case "ACCESSED":
            return "green";
        case "OVERPAID":
            return "yellow";
        case "PARTIALPAID":
            return "yellow";
        default:
            return "";
    }
}


export function getStatusColor(status) {
    if (!status) return "";

    switch (status) {
        case "PENDING":
            return "red";
        case "ACCEPTED":
            return "green";
        case "DECLINED":
            return "grey1";
        case "CONFIRMED":
            return "blue";
        case "REJECTED":
            return "grey1";
        case "PAID":
            return "green";
        default:
            return "-";
    }
}

export function getStatusHistoryString(status) {
    if (!status) return "";

    switch (status) {
        case "CHECKINGOUT":
            return "L'utente iniziato a creare la prenotazione";
        case "PENDING":
            return "L'utente ha inviato la richiesta di prenotazione";
        case "ACCEPTED":
            return "La struttura ha accettato la prenotazione";
        case "DECLINED":
            return "La struttura ha rifiutato la prenotazione";
        case "CONFIRMED":
            return "L'utente ha confermato il nuovo prezzo di prenotazione";
        case "REJECTED":
            return "L'utente ha rifiutato il nuovo prezzo di prenotazione";
        case "PAID":
            return "L'utente ha pagato la prenotazione";
        default:
            return "";
    }
}

export function getBeachesStatusHistoryString(status, options = {}) {
    if (!status) return "";

    const { isOnlineBeacharound, isPublicBeach } = options;

    switch (status) {
        case "ACCEPTED":
            if (isPublicBeach) {
                return "Prenotazione è stata creata"
            }
            return "La prenotazione è stata confermata";
        case "PAID":
            if (isOnlineBeacharound) {
                return "La prenotazione è stata pagata online"
            }
            return "La prenotazione è stata pagata";
        case "ACCESSED":
            return "È stato registrato l'accesso in spiaggia";
        case "PARTIALPAID":
            return "È stata registrata una transazione parziale per questa prenotazione";
        case "OVERPAID":
            return "È stata registrata una modifica alla prenotazione con importo maggiore del valore della prenotazione";
        case "CANCELED":
            return "La prenotazione è stata annullata";
        default:
            return "-";
    }
}

export function getPeopleString(details) {
    if (!details || !Array.isArray(details)) return "";
    return details
        .filter(({ key }) => ["adults", "children", "babies"].includes(key))
        .map(({ key, value }) => {
            let name;
            switch (key) {
                case "adults":
                    name = "Adulti";
                    break;
                case "children":
                    name = "Bambini";
                    break;
                case "babies":
                    name = "Neonati";
                    break;
                default:
                    name = "";
            }
            return `${value} ${name}`;
        })
        .join(", ");
}

export function getDateAndProductPlaced(date, item) {

    const formattedDate = date ? formatDate(new Date(date), 'dd/MM/yy') : '-';

    if (item?.firstPlacedProduct?.customNumber) {
        return `${formattedDate} - <strong>${item?.firstPlacedProduct?.customNumber}</strong>`
    }

    if (item?.firstPlacedProduct?.index) {
        return `${formattedDate} - <strong>${item?.firstPlacedProduct?.index}</strong>`
    }
    return formattedDate;
}

export function getBookingInfo(reservation) {
    if (!reservation) {
        return "";
    }
    let priceInfo = `prezzo ${reservation?.unpaid?.toFixed(2) || "-"} €`
    let dateInfo = `Dal ${formatDate(new Date(reservation?.checkin), "dd/MM")} al ${formatDate(new Date(reservation?.checkout), "dd/MM")}`;
    if (["morning", "afternoon"].includes(reservation?.durationType)) {
        dateInfo = `${reservation?.durationType === "morning" ? "La mattina" : "Il pomeriggio"} del ${formatDate(new Date(reservation?.checkin), "dd/MM")}`
    }
    return `${dateInfo} - ${priceInfo}`;
}

export function getDetailsKeyString(reservation, key) {
    if (!reservation || !reservation?.details) {
        return "";
    }
    const value = reservation?.details?.find(({ key: detailKey }) => key === detailKey)?.value;

    if (!value) {
        return "";
    }

    if (key === "persons") {
        return `${value} ${value > 1 ? "persone" : "persona"}`
    };

    return value;
}

export const reservationMainDetails = [
    {
        label: "Animali",
        text: "Prenotazione con animali",
        key: "animals",
        icon: "paw",
        faIcon: true
    }, {
        label: "Fumatori",
        text: "Prenotazione con fumatori",
        key: "smokers",
        icon: "smoking",
        faIcon: true
    }, {
        label: "Bambini",
        text: "Prenotazione con bambini",
        key: "children",
        icon: "baby",
        faIcon: true
    }, {
        label: "Disabili",
        text: "Prenotazione con diversamente abili",
        key: "disabled",
        icon: "wheelchair",
        faIcon: true
    }, {
        label: "Lucchetto",
        text: "Prenotazione con lucchetto",
        key: "lock",
        icon: "lock",
        faIcon: true
    }, {
        label: "Domanda",
        text: "Prenotazione con punto di domanda",
        key: "question",
        icon: "question",
        faIcon: true
    }, {
        label: "Check",
        text: "Prenotazione con check",
        key: "check",
        icon: "check",
        faIcon: true
    }, {
        label: "X",
        text: "Prenotazione con x",
        key: "cross",
        icon: "xmark",
        faIcon: true
    }, {
        label: "Maschera",
        text: "Prenotazione con maschera",
        key: "sub-mask",
        icon: "mask",
        faIcon: true,
        type: ["rental"]
    }, {
        label: "Giacchio",
        text: "Prenotazione con ghiaccio",
        key: "ice",
        icon: "snowflake",
        faIcon: true,
        type: ["rental"]
    }, {
        label: "Bluetooth",
        text: "Prenotazione con bluetooth",
        key: "bluetooth",
        icon: "bluetooth-b",
        faIcon: true,
        faStyle: "brands",
        type: ["rental"]
    }, {
        label: "Sicurezza",
        text: "Prenotazione con sicurezza",
        key: "life-ring",
        icon: "life-ring",
        faIcon: true,
        type: ["rental"]
    }
]

export function getRepeatedReservationsDates({
    daysOfTheWeek,
    checkin,
    checkout
}) {

    const dates = eachDayOfInterval({
        start: new Date(checkin),
        end: new Date(checkout)
    });

    let parsedDates = dates.map(date => {
        const day = date.getDay();
        if (daysOfTheWeek.includes(day)) {
            return {
                date,
                day
            }
        }
        return undefined;
    }).filter(date => !!date)

    const daysGroup = parsedDates?.reduce((acc, { date, day }) => {
        const lastSubArray = acc[acc.length - 1];
        if (
            !lastSubArray ||
            (
                lastSubArray[lastSubArray.length - 1]?.day !== day - 1 &&
                lastSubArray[lastSubArray.length - 1]?.day !== day + 6
            )
        ) {
            acc = [...acc, []]
        }
        acc[acc.length - 1] = [
            ...acc[acc.length - 1],
            { date, day }
        ]
        return acc
    }, []);

    return daysGroup.map(days => ({
        checkin: format(days[0]?.date, 'yyyy-MM-dd'),
        checkout: format(days[days.length - 1]?.date, 'yyyy-MM-dd')
    }));
}

export function checkExpiration(reservation, { timeout } = {}) {
    if (!reservation?.createdAt) {
        return;
    }
    if (!reservation?.pending) {
        return;
    }
    let timeoutToUse = timeout || 30;
    return isBefore(addMinutes(new Date(reservation?.createdAt), timeoutToUse), new Date());
}

export function getReservationInterval({ checkin, checkout }) {
    const invalidateDates = eachDayOfInterval({ start: new Date(checkin), end: new Date(checkout) });
    return invalidateDates;
}