
import React from 'react';
import StepContent from '../../components/Tutorial/StepContent';
import storageKey from '../../data/tutorial/storage-keys';

const red = '#ce544f';
const green = '#81c468';
const yellow = '#ffcc30';

export const welcome = [
	{
		selector: '#root > div > div.main-layout-body > div > div.title-container.title-container-w-image',
		content: <StepContent title="Benvenuto!" text="Benvenuto su Beacharound Business! Inizia subito ad utilizzare tutte le funzionalità del tuo gestionale!" />
	},
	{
		selector: '#root > div > header > div.header-body > div > div > nav > ul > li:nth-child(1) > a',
		content: <StepContent text="Questa è la Dashboard, la pagina in cui ti trovi ora. Puoi vedere lo stato di completamento del profilo, gli articoli del blog di Beacharound e i video tutorial." />
	},
	{
		selector: '#root > div > header > div.header-body > div > div > nav > ul > li:nth-child(2) > a',
		content: <StepContent ><p>Controlla tutte le postazioni e gestisci le prenotazioni grazie alla mappa digitale della tua spiaggia.</p></StepContent>
	},
	{
		selector: '#root > div > header > div.header-body > div > div > nav > ul > li:nth-child(3) > a',
		content: <StepContent text="Questa sezione ti consente di controllare gli ingressi in spiaggia tramite lettura di QR code di tutti i clienti che hanno prenotato online." />
	},
	{
		selector: '#root > div > header > div.header-body > div > div > nav > ul > li:nth-child(4) > a',
		content: <StepContent text="Accedi all'elenco di tutte le prenotazioni e visualizza tutti i dati di cui hai bisogno tramite filtri e ricerca intelligente." />
	},
	{
		selector: '#root > div > header > div.header-body > div > div > nav > ul > li:nth-child(5) > a',
		content: <StepContent text="Qui trovi i dati di tutti i clienti della tua spiaggia. Puoi vedere anche le prenotazioni a loro associate e trovarli tramite la barra di ricerca." />
	},
	{
		selector: '#root > div > header > div.header-body > div > div > nav > ul > li:nth-child(6) > a',
		content: <StepContent text="Le statistiche mostrano i dati delle vendite dei tuoi prodotti e ne calcolano il rendimento nel tempo. Puoi valutare quali prodotti sono più apprezzati e quali rimangono invenduti durante la stagione." />
	},
	{
		selector: '#root > div > header > div.header-body > div > div > nav > ul > li:nth-child(7) > a',
		content: <StepContent><p>Il catalogo è la pagina dove inserisci i tuoi prodotti (lettini, ombrelloni, servizi, ecc...) e crei il tuo listino prezzi.</p></StepContent>
	},
	{
		selector: '#root > div > header > div.header-body > div > div > nav > ul > li:nth-child(8) > a',
		content: <StepContent text="Configura tutto quello che riguarda il gestionale: personalizzazione della mappa, attivazione delle prenotazioni online, annuncio, regolamento della spiaggia, profilo di fatturazione e molto altro." />
	},
	{
		selector: '#root > div > header > div.header-body > div > div > nav > ul > li:nth-child(2) > a',
		content: <StepContent storageKey={storageKey.welcome} text="Inizia a creare prenotazioni dalla mappa." />
	}
];

const reservationTypeSelector = {
	"unpaid": "div:nth-child(1) > div:nth-child(2) > div:nth-child(2) > div.chip-state.chip-state-red",
	"partialpaid": "div:nth-child(1) > div:nth-child(2) > div:nth-child(2) > div.chip-state.chip-state-yellow",
	"paid": "div:nth-child(1) > div:nth-child(2) > div:nth-child(2) > div.chip-state.chip-state-green"
};

export const map = [
	{
		selector: '#root > div > div.main-layout-body > div > div.map-dates-container > div.map-calendar-top-container > div.react-datepicker-wrapper > div > button',
		highlightedSelectors: [
			'#root > div > div.main-layout-body > div > div.map-dates-container > div.map-calendar-top-container > div.react-datepicker-wrapper > div > button',
			'#name',
		],
		padding: 50,
		content: <StepContent title="Calendario"><p>Da qui è possibile:<br /><br />• selezionare una data tramite il calendario<br /><br />• cercare prenotazioni nella barra di ricerca<br /><br /><strong>La data selezionata viene utilizzata come data di check-in durante la creazione di una nuova prenotazione.</strong></p></StepContent>
	},
	{
		selector: '#root > div > div.main-layout-body > div > div.map-dates-container > div.map-calendar-top-container > div.map-dates-buttons',
		content: <StepContent title="Informazioni generali"><p>Qui puoi:<br /><br />• aprire il planner in una nuova scheda<br /><br />• verificare le disponibilità dei prodotti in magazzino<br /><br />• stampare la mappa<br /><br />• controllare arrivi/partenze e spostamenti<br /><br />Sotto la mappa troverai una legenda che spiega lo stato delle prenotazioni.</p></StepContent>
	},
	{
		selector: `div.map-column > div > ${reservationTypeSelector['unpaid']}`,
		content: <StepContent title="Stato delle prenotazioni"><p><span style={{ color: red }}>Rosso:</span> prenotazioni non ancora pagate.</p></StepContent>
	},
	{
		selector: `div.map-column > div > ${reservationTypeSelector['partialpaid']}`,
		content: <StepContent title="Stato delle prenotazioni"><p><span style={{ color: yellow }}>Giallo:</span> acconti: prenotazioni parzialmente pagate.</p></StepContent>
	},
	{
		selector: `div.map-column > div > ${reservationTypeSelector['paid']}`,
		content: <StepContent title="Stato delle prenotazioni"><p><span style={{ color: green }}>Verde:</span> prenotazioni interamente pagate.</p></StepContent>
	},
	{
		selector: 'div.map-column > div.map-cell:not(div.map-cell.map-cell-furniture) > div.map-product.map-builder-product',
		content: <StepContent title="Postazioni libere"><p><strong>Clicca la postazione per creare una prenotazione.</strong><br /><br />Sulle postazioni che non mostrano lo stato è possibile effettuare una prenotazione.</p></StepContent>
	},
	{
		selector: 'div.side-container.side-container-open > div > div.column-container',
		content: <StepContent title="Prenotazioni"><p>Sulle postazioni libere puoi:<br /><br />• Creare una nuova prenotazione passo dopo passo<br /><br />• Creare una nuova prenotazione con passaggi in una sola schermata<br /><br />• Disattivare la possibilità di ricevere prenotazioni online per il periodo indicato</p></StepContent>
	},
	{
		selector: 'div.side-container.side-container-open > div > div.column-container > div > div:nth-child(2) > a',
		content: <StepContent title="Crea una prenotazione" storageKey={storageKey.map} text="Clicca sul pulsante per creare la tua prima prenotazione." />
	}
];

export const planner = [
	{
		selector: 'div.main-planner-grid-products > div:nth-child(1)',
		highlightedSelectors: [
			'div.main-planner-grid-products > div:nth-child(1)',
			'div.main-planner-grid-header > div:nth-child(1)',
			'div.main-planner-grid-header > div:nth-child(2)',
			'div.main-planner-grid-header > div:nth-child(3)',
			'div.main-planner-grid-header > div:nth-child(4)',
			'div.main-planner-grid-header > div:nth-child(5)',
			'div.main-planner-grid-header > div:nth-child(6)',
			'div.main-planner-grid-header > div:nth-child(7)',
			'div.main-planner-grid-header > div:nth-child(8)',
			'div.main-planner-grid-header > div:nth-child(9)',
			'div.main-planner-grid-header > div:nth-child(10)',
		],
		content: <StepContent title="Informazioni generali" text="Nel planner trovi tutti i prodotti ordinati per file e il loro stato nelle date di apertura dello stabilimento." />
	},
	{
		selector: 'div.main-planner-grid-products > div:nth-child(1)',
		highlightedSelectors: [
			'div.main-planner-grid-products > div:nth-child(1)',
			'div.main-planner-grid-header > div:nth-child(1)',
			'div.main-planner-grid-header > div:nth-child(2)',
			'div.main-planner-grid-header > div:nth-child(3)',
			'div.main-planner-grid-header > div:nth-child(4)',
			'div.main-planner-grid-header > div:nth-child(5)',
			'div.main-planner-grid-header > div:nth-child(6)',
			'div.main-planner-grid-header > div:nth-child(7)',
		],
		content: <StepContent title="Stato delle prenotazioni"><p>Come avviene per la mappa, il colore indica lo stato del pagamento della prenotazione:<br /><br /><span style={{ color: red }}>Rosso:</span> prenotazioni non pagate.<br /><br /><span style={{ color: yellow }}>Giallo:</span> acconti: prenotazioni pagate parzialmente<br /><br /><span style={{ color: green }}>Verde:</span> interamente pagate.</p></StepContent>
	},
	{
		selector: 'div.main-planner-grid-header',
		highlightedSelectors: [
			'div.main-planner-grid-header > div:nth-child(1)',
			'div.main-planner-grid-header > div:nth-child(2)',
			'div.main-planner-grid-header > div:nth-child(3)',
			'div.main-planner-grid-header > div:nth-child(4)',
			'div.main-planner-grid-header > div:nth-child(5)',
			'div.main-planner-grid-header > div:nth-child(6)',
			'div.main-planner-grid-header > div:nth-child(7)',
			'div.main-planner-grid-header > div:nth-child(8)',
			'div.main-planner-grid-header > div:nth-child(9)',
			'div.main-planner-grid-header > div:nth-child(10)',
			'div.main-planner-grid-products > div:nth-child(1)'
		],
		padding: 75,
		content: <StepContent title="Menù rapido"><p><strong>Clicca una prenotazione qualsiasi per aprire il menù.</strong><br /><br />Interagisci con le prenotazioni per vederne il dettaglio, effettuare spostamenti, pagamenti e aggiungere lettini.</p></StepContent>
	},
	{
		selector: 'div.main-planner-grid-products > div:nth-child(1)',
		highlightedSelectors: [
			'div.main-planner-grid-products > div:nth-child(1)',
			'div.main-planner-grid-header > div:nth-child(1)',
			'div.main-planner-grid-header > div:nth-child(2)',
			'div.main-planner-grid-header > div:nth-child(3)',
			'div.main-planner-grid-header > div:nth-child(4)',
			'div.main-planner-grid-header > div:nth-child(5)',
			'div.main-planner-grid-header > div:nth-child(6)',
			'div.main-planner-grid-header > div:nth-child(7)',
			'div.main-planner-grid-header > div:nth-child(8)',
			'div.main-planner-grid-header > div:nth-child(9)',
			'div.main-planner-grid-header > div:nth-child(10)',
		],
		content: <StepContent title="Modifica check-in/check-out"><p><strong>Sposta il cursore del mouse sopra questa prenotazione.</strong><br /><br />Cliccando sulle frecce che appariranno lateralmente si potrà modificare check-in e check-out della prenotazione.</p></StepContent>,
		padding: 100
	},
	{
		selector: 'div.main-planner-grid-header',
		highlightedSelectors: [
			'div.main-planner-grid-header > div:nth-child(1)',
			'div.main-planner-grid-header > div:nth-child(2)',
			'div.main-planner-grid-header > div:nth-child(3)',
			'div.main-planner-grid-header > div:nth-child(4)',
			'div.main-planner-grid-header > div:nth-child(5)',
			'div.main-planner-grid-header > div:nth-child(6)',
			'div.main-planner-grid-header > div:nth-child(7)',
			'div.main-planner-grid-header > div:nth-child(8)',
			'div.main-planner-grid-header > div:nth-child(9)',
			'div.main-planner-grid-header > div:nth-child(10)',
			'div.main-planner-grid-products > div:nth-child(1)'
		],
		padding: 75,
		content: <StepContent title="Nuova prenotazione" storageKey={storageKey.planner} text={<p><strong>Clicca su uno spazio vuoto per iniziare una prenotazione. Seleziona le date e conferma.</strong><br /></p>} />
	}
];