import { lazy } from 'react';

import MainLayout from '../layouts/MainLayout/MainLayout';

const ReservationsGroupsList = lazy(() => import('pages/Beach/Groups/ReservationsGroupsList'));
const ReservationsGroupDetail = lazy(() => import('../pages/Beach/Groups/ReservationsGroupDetail'));
const BeachGroupsPayment = lazy(() => import('../pages/Beach/Reservations/BeachGroupsPayment'));
const BeachGroupsSplitPayment = lazy(() => import('../pages/Beach/Reservations/BeachGroupsSplitPayment'));
const BeachMapGroupAdd = lazy(() => import('../pages/Map/BeachMapGroupAdd'));

export default [{
    path: "/:groupId",
    component: ReservationsGroupDetail,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Dettaglio"] },
}, {
    path: "/:groupId/payment",
    component: BeachGroupsPayment,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Pagamento"] },
}, {
    path: "/:groupId/splitPayment",
    component: BeachGroupsSplitPayment,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Pagamento separato"] },
}, {
    path: "/:groupId/map",
    component: BeachMapGroupAdd,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Mappa"] },
}, {
    path: "/",
    component: ReservationsGroupsList,
    layout: MainLayout,
}].map((route) => {
    return {
        ...route,
        path: `/beaches/:beachId/groups${route.path}`,
        private: true,
        permissions: { memberships: ["MANAGEMENT", "BOOKING"] },
        analytics: {
            name: ["Prenotazioni", "Gruppo", ...(route.analytics?.name || [])],
        },
    };
});