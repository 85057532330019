import axios from '../_defaults/v2/user';

import { updateBeachesMap } from './maps';

export async function getPlacedProduct({activityId, beachMapId, placedProductId, date}) {
    try {
        const result = await axios.get(`/placedproducts/${activityId}/${placedProductId}`, {
            params: {
                date,
                map: beachMapId
            }
        });
        return result?.data;
    } catch(error) {
        console.log(error.result);
        throw error;
    }
}

export async function placePlacedProducts({activityId, beachMapId, products}) {
    try {
        const result = await axios.post(`/placedproducts/${activityId}`, {products, map: beachMapId});
        return result?.data;
    } catch(error) {
        console.log(error.result);
        throw error;
    }
}

export async function removePlacedProducts({activityId, beachMapId, products}) {
    try {
        const result = await axios( {
            method: "DELETE",
            url: `/placedproducts/${activityId}`,
            params: {
                map: beachMapId
            },
            data: {
                products
            }
        });
        return result?.data;
    } catch(error) {
        console.log(error.response);
        throw error;
    }
}

export async function updatePlacedProductsAndMap({activityId, beachMapId, products, map}) {
    try {
        await updateBeachesMap({activityId, beachMapId, data: map});
        const result = await axios.post(`/placedproducts/${activityId}`, {products, map: beachMapId});
        return result?.data;
    } catch(error) {
        console.log(error.result);
        throw error;
    }
}

export async function updatePlacedProduct({activityId, beachMapId, placedProductId, data}) {
    try {
        const result = await axios.post(`/placedproducts/${activityId}/${placedProductId}`, {...data, map: beachMapId});
        return result?.data;
    } catch(error) {
        console.log(error.result);
        throw error;
    }
}

export async function checkPlacedProductsBeforeUpdate({activityId, beachMapId, products}) {
    try {
        const result = await axios.post(`/placedproducts/${activityId}/check`, {products, map: beachMapId});
        return result?.data;
    } catch(error) {
        console.log(error.result);
        throw error;
    }
}

export async function getPlacedProductSchedule({activityId, placedProductId}) {
    try {
        const result = await axios.get(`/schedules/${activityId}/placedProducts/${placedProductId}`);
        return result?.data;
    } catch(error) {
        console.log(error.result);
        throw error;
    }
}