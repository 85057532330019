import React from 'react';
import { Link } from 'react-router-dom'
import { MainContainer, TitleContainer, MainButton, MainTitle, Paragraph, ButtonsRow, Footer, MainLayoutContent, MainLayoutContainer } from '@beacharound/beacharound-ui';
import MainHeaderBusiness from '../components/MainHeader';
import { getUser } from "../api/user";
import { useQuery } from 'react-query';

export default function NotFoundPage() {

    const { data: user } = useQuery('user', getUser);

    return (

        <MainLayoutContainer>
            <MainHeaderBusiness />

            <MainLayoutContent >

                <MainContainer>
                    <TitleContainer center>
                        <MainTitle text="Pagina non trovata" component="h1" center />
                        <Paragraph text="Ci dispiace.. la pagina che stai cercando non esiste" center />
                    </TitleContainer>

                    <ButtonsRow center>
                        <MainButton text="Torna alla dashboard" component={Link} to={user ? "/dashboard" : "/"} />
                    </ButtonsRow>
                </MainContainer>
            </MainLayoutContent>

            <Footer />
        </MainLayoutContainer>

    )
}