import axios from './_defaults/v2/user';
import { epsonPrintFiscalReceipt, epsonPrintFiscalVoid, epsonPrintNonFiscalReceipt, epsonPrintOrderReceipt } from './printers/epson';
import { getPrinter } from './printers';

export async function createReceipt({ data, activityId }) {
    const response = await axios.post(`/receipts/${activityId}`, { ...data });

    if (response.data) {
        return response.data
    }

    return response
}

export async function getReceipts({ activityId, params }) {
    try {
        const response = await axios.get(`/receipts/${activityId}`, {
            params
        });

        return response?.data;
    } catch (error) {
        if (error.response?.status === 404) {
            return []
        }
        return error;
    }
}

export async function getReceiptsStatistics({ activityId, params }) {
    try {
        const response = await axios.get(`/receipts/${activityId}/statistics`, { params });
        return response?.data;
    } catch (error) {
        if (error.response?.status === 404) {
            return []
        }
        return error;
    }
}

export async function getReceipt({ activityId, receiptId }) {
    try {
        const response = await axios.get(`/receipts/${activityId}/${receiptId}`);

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        console.log(error.response);
        throw error;
    }
}

export async function updateReceipt({ activityId, receiptId, data }) {
    try {
        const response = await axios.post(`/receipts/${activityId}/${receiptId}`, data);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export async function removeReceipt({ activityId, receiptId }) {
    try {
        const response = await axios.delete(`/receipts/${activityId}/${receiptId}`);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export async function addAdjustementToReceipt({ activityId, receiptId, data }) {
    try {
        const response = await axios.post(`/receipts/${activityId}/${receiptId}/adjustement`, data);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export async function removeAdjustementFromReceipt({ activityId, receiptId, productId }) {
    try {
        const response = await axios.delete(`/receipts/${activityId}/${receiptId}/adjustement`);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export async function addProductToReceipt({ activityId, receiptId, data }) {
    try {
        const response = await axios.post(`/receipts/${activityId}/${receiptId}/products`, data);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export async function removeProductFromReceipt({ activityId, receiptId, productId }) {
    try {
        const response = await axios.delete(`/receipts/${activityId}/${receiptId}/products/${productId}`);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export async function getReceiptsOrdersToPrint({ activityId, params }) {
    try {
        const response = await axios.get(`/receipts/${activityId}/orders/to-print`, { params });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error.response?.status === 404) return [];
        throw error;
    }
}

export async function printReceiptOrder({ activityId, orderId, printer }) {
    try {
        const created = await createReceipt({ activityId, data: { reservation: orderId, type: "ORDER" } });
        const receipt = await getReceipt({ activityId, receiptId: created._id });
        try {
            const response = await epsonPrintOrderReceipt({ receipt, printer, printerUrl: printer.url });
            await updateReceipt({ activityId, receiptId: receipt._id, data: { status: "PRINT", printer: printer._id, response } })
        } catch (error) {
            await updateReceipt({ activityId, receiptId: receipt._id, data: { status: "FAILED", printer: printer._id } })
            throw error;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export async function printReceiptNonFiscal({ activityId, activityName, reservationId, transactionId, receiptId, printer }) {
    try {
        const created = !receiptId && await createReceipt({ activityId, data: { reservation: reservationId, transaction: transactionId, type: "NONFISCAL" } });
        const receipt = await getReceipt({ activityId, receiptId: receiptId || created._id });
        try {
            const response = await epsonPrintNonFiscalReceipt({ activityName, receipt, printer, printerUrl: printer.url });
            await updateReceipt({ activityId, receiptId: receipt._id, data: { status: "PRINT", printer: printer._id, response } })
        } catch (error) {
            await updateReceipt({ activityId, receiptId: receipt._id, data: { status: "FAILED", printer: printer._id } })
            throw error;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export async function printReceiptFiscal({ activityId, reservationId, transactionId, receiptId, printer, singleTransaction }) {
    try {
        const created = !receiptId && await createReceipt({ activityId, data: { reservation: reservationId, transaction: transactionId, type: "FISCAL", singleTransaction } });
        const receipt = await getReceipt({ activityId, receiptId: receiptId || created._id });
        try {
            const response = await epsonPrintFiscalReceipt({ receipt, printer, printerUrl: printer.url });
            await updateReceipt({ activityId, receiptId: receipt._id, data: { status: "PRINT", printer: printer._id, response } })
        } catch (error) {
            await updateReceipt({ activityId, receiptId: receipt._id, data: { status: "FAILED", printer: printer._id } })
            throw error;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export async function printReceiptFiscalVoid({ activityId, receiptId }) {
    try {
        const receiptToVoid = await getReceipt({ activityId, receiptId });
        const printer = await getPrinter({ activityId, printerId: receiptToVoid?.printer });
        const created = await createReceipt({ activityId, data: { receipt: receiptId, type: "VOID" } });
        const receipt = await getReceipt({ activityId, receiptId: created._id });
        try {
            const response = await epsonPrintFiscalVoid({ receipt: receiptToVoid, printer, printerUrl: printer.url });
            await updateReceipt({ activityId, receiptId: receipt._id, data: { status: "PRINT", printer: printer._id, response } })
        } catch (error) {
            await updateReceipt({ activityId, receiptId: receipt._id, data: { status: "FAILED", printer: printer._id } })
            throw error;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export async function printReceiptsOrdersToPrint({ activityId, printer }) {
    try {
        if (!printer) {
            return;
        }
        const orders = await getReceiptsOrdersToPrint({ activityId, params: { printers: JSON.stringify([printer?._id]) } });
        if (!orders?.length) {
            return;
        }

        for (const order of orders) {
            await printReceiptOrder({ activityId, printer, orderId: order._id });
        }

    } catch (error) {
        console.log(error);
        throw error;
    }
}