import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { Tile } from '@beacharound/beacharound-ui';

import { useQuery } from 'react-query';
import { getPrinterStatus } from '../../api/printers/epson';

export default function PrintersTilesListItem({
    printer,
    activityId,
    match,
    autoWidth,
    lastPrinterId,
    active,
    text,
    printerAction,
    printerActionText,
    hideAction
}) {
    const { _id, url, address } = printer || {};

    const { status, error } = useQuery(["printer", activityId, _id], () => getPrinterStatus({ printer, url }));

    let pretitle = "Verifica in corso...";
    let color = "grey";
    let isReady = status === "success";
    const isDisconnected = status === "error";
    if (isReady) {
        pretitle = "Connessa";
        color = "green";
    }
    if (isDisconnected) {
        pretitle = "Non connessa";
        color = "red";
    }
    if (isDisconnected && error?.code === "EPTR_REC_EMPTY") {
        pretitle = error?.message || "Non connessa";
        color = "red";
    }

    function printerActionHander() {
        return printerAction(printer);
    }

    return (
        <Fragment>
            <Tile
                autoWidth={autoWidth}
                active={active || _id === lastPrinterId}
                highlight={`${pretitle}${isDisconnected && !error?.code && !!address ? ` - <span><a class="tile-link" style="margin-left:5px;position:initial;" href="https://${address}" target="_blank">Connetti</a></span>` : ""}`}
                highlightColor={color}
                title={printer.name}
                text={text || printer.address}
                buttonComponent={!hideAction && !printerAction && Link}
                buttonLink={!hideAction && !printerAction && `${match.url}/${printer._id}`}
                buttonLinkText={!hideAction && (printerAction && isReady ? (printerActionText || "Seleziona") : (printerActionText || "Modifica"))}
                buttonAction={printerAction && isReady && !hideAction && printerActionHander}
                buttonRight
            />
        </Fragment>
    );
}