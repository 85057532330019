import { lazy } from 'react';

import MainLayout from '../layouts/MainLayout/MainLayout';

const BeachQuotesList = lazy(() => import('../pages/Beach/Quotes/BeachQuotesList'));
const OnePageQuote = lazy(() => import('../pages/Beach/OnePageBooking/OnePageQuote'));


export default [{
},{
    path: "/simple",
    component: OnePageQuote,
    layout: MainLayout,
    exact: true,
    permissions: { memberships: ["BOOKING", "MANAGEMENT"] },
    analytics: { name: ["Creazione"] },
},{
    path: "/simple/:reservationId",
    component: OnePageQuote,
    layout: MainLayout,
    exact: true,
    permissions: { memberships: ["BOOKING", "MANAGEMENT"] },
    analytics: { name: ["Dettaglio"] },
},{
    path: "/",
    component: BeachQuotesList,
    layout: MainLayout,
}].map((route) => {
    return {
        ...route,
        path: `/beaches/:beachId/quotes${route.path}`,
        private: true,
        permissions: { memberships: ["MANAGEMENT", "BOOKING"] },
        analytics: {
            name: ["Spiagge", "Preventivi", ...(route.analytics?.name || [])],
        },
    };
});