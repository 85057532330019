import { lazy } from 'react';
import EmptyLayout from '../layouts/EmptyLayout/EmptyLayout';

const NewHotelName = lazy(() => import('../pages/Hotel/New/NewHotelName'));
const NewHotelInfo = lazy(() => import('../pages/Hotel/New/NewHotelInfo'));
const NewHotelPack = lazy(() => import('../pages/Hotel/New/NewHotelPack'));
const NewHotelAddress = lazy(() => import('../pages/Hotel/New/NewHotelAddress'));
const NewHotelRoom = lazy(() => import('../pages/Hotel/New/NewHotelRoom'));
const NewHotelAccessibility = lazy(() => import('../pages/Hotel/New/NewHotelAccessibility'));
const NewHotelImages = lazy(() => import('../pages/Hotel/New/NewHotelImages'));
const NewHotelConditions = lazy(() => import('../pages/Hotel/New/NewHotelConditions'));
const NewHotelBillings = lazy(() => import('../pages/Hotel/New/NewHotelBillings'));
const NewHotelSummary = lazy(() => import('../pages/Hotel/New/NewHotelSummary'));

export default [...[
    {
        path: '/name',
        component: NewHotelName,
        layout: EmptyLayout,
        analytics: { name: ["Nome"] }
    },
    {
        path: '/info',
        component: NewHotelInfo,
        layout: EmptyLayout,
        analytics: { name: ["Info"] }
    }
].map(route => {
    return {
        ...route,
        path: `/new/hotel${route.path}`,
        analytics: { name: ["Hotel", "Creazione", ...(route.analytics?.name || [])] }
    }
}), ...[
    {
        path: '/pack',
        component: NewHotelPack,
        layout: EmptyLayout,
        analytics: { name: ["Pacchetti"] }
    },
    {
        path: '/address',
        component: NewHotelAddress,
        layout: EmptyLayout,
        analytics: { name: ["Indirizzo"] }
    },
    {
        path: '/room',
        component: NewHotelRoom,
        layout: EmptyLayout,
        analytics: { name: ["Camere"] }
    },
    {
        path: '/accessibility',
        component: NewHotelAccessibility,
        layout: EmptyLayout,
        analytics: { name: ["Accessibilità"] }
    },
    {
        path: '/images',
        component: NewHotelImages,
        layout: EmptyLayout,
        analytics: { name: ["Immagini"] }
    },
    {
        path: '/conditions',
        component: NewHotelConditions,
        layout: EmptyLayout,
        analytics: { name: ["Condizioni"] }
    },
    {
        path: '/billing',
        component: NewHotelBillings,
        layout: EmptyLayout,
        analytics: { name: ["Fatturazione"] }
    },
    {
        path: '/summary',
        component: NewHotelSummary,
        layout: EmptyLayout,
        analytics: { name: ["Riepilogo"] }
    }
].map(route => {
    return {
        ...route,
        path: `/new/hotel/:hotelId${route.path}`,
        permissions: { memberships: ["MANAGEMENT"] },
        analytics: { name: ["Hotel", "Creazione", ...(route.analytics?.name || [])] }
    }
})]
