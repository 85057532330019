import frontend from './frontend';
import general from './general';
import user from './user';
import newShared from './new';
import blog from './blog';
import tutorial from './tutorial';

import beach from './beach';
import newBeach from './newBeach';
import beachCatalog from './beachCatalog';
import beachFood from './beachFood';
import beachEvents from './beachEvents';
import beachDeactivations from './beachDeactivations';
import beachCoupons from './beachCoupons';
import beachHotels from './beachHotels';
import beachQuotes from './beachQuotes';
import beachRentals from './beachRentals';
import beachEntrances from './beachEntrances';
import beachSettings from './beachSettings';
import beachReservations from './beachReservations';
import beachPayments from './beachPayments';
import beachGroups from './beachGroups';
import beachPrinters from './beachPrinters';
import beachCustomers from './beachCustomers';
import beachTransactions from './beachTransactions';
import beachTransactionsScheduled from './beachTransactionsScheduled';

import hotel from './hotel';
import newHotel from './newHotel';
import hotelCatalog from './hotelCatalog';
import hotelSettings from './hotelSettings';
import sitemaps from './sitemaps';

import notifications from './notifications'

export default [
    ...frontend,
    ...general,
    ...user,
    ...sitemaps,
    ...newShared,
    ...blog,
    ...tutorial,

    ...beach,
    ...newBeach,
    ...beachCatalog,
    ...beachFood,
    ...beachEvents,
    ...beachRentals,
    ...beachSettings,
    ...beachEntrances,
    ...beachDeactivations,
    ...beachCoupons,
    ...beachReservations,
    ...beachPayments,
    ...beachGroups,
    ...beachQuotes,
    ...beachCustomers,
    ...beachTransactions,
    ...beachTransactionsScheduled,
    ...beachPrinters,
    ...beachHotels,

    ...hotel,
    ...newHotel,
    ...hotelSettings,
    ...hotelCatalog,
    // ...hotelRooms,
    // ...hotelOffers

    ...notifications
].map((route) => ({
    ...route,
    path: `${route.path}`
}));