import axios from 'axios';
import Cookies from 'js-cookie';

import { refreshUser } from '../user';
import { returnError } from './error';

const userAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
        'content-type': 'application/json'
    },
    // withCredentials: true
})

userAxios.interceptors.request.use(async (config) => {
    let token = Cookies.get('user-authorization');
    let refreshToken = Cookies.get('user-refreshToken');
    let userId = Cookies.get('user-id');

    if (!token && !!refreshToken && !!userId) {
        const data = await refreshUser({ _id: userId, refreshToken });
        console.log("REFRESH TOKEN");
        token = data?.token;
    }

    config.headers.authorization = `Bearer ${token}`
    // config.headers["beacharound-activity"] = Cookies.get("beacharound-activity");
    return config;
});

userAxios.interceptors.response.use(response => response, error => {
    return returnError(error);
});

export default userAxios;