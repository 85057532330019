import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from 'react-query/devtools';

import "react-datepicker/dist/react-datepicker.css";

import routes from './routes';
import NotFoundPage from './pages/NotFoundPage';

import MainRoute from './components/Routes/MainRoute';

import { ActivitiesContext } from './context/activities';
import { ConsentsContext } from './context/usersConsents';

// import BeacharoundPackage from "../package.json";
// import BeacharoundUiPackage from "@beacharound/beacharound-ui/package.json";
// import BeacharoundUiHelpersPackage from "@beacharound/beacharound-ui-helpers/package.json";
// import BeacharoundMapPackage from "@beacharound/beacharound-map/package.json";
// import BeacharoundPlannerPackage from "@beacharound/beacharound-planner/package.json";
import { LoadingContainer } from '@beacharound/beacharound-ui';

import { welcome } from './data/tutorial/steps';
import TutorialProvider from './components/Tutorial/TutorialProvider';
import { PrinterContext } from './context/printers';
import { refreshUser } from './api/user';
import { RolesContext } from './context/roles';

const queryCache = new QueryClient({
  defaultConfig: { retry: true }
});

function App() {

  if (process.env.NODE_ENV === 'development') {
    import('@beacharound/beacharound-ui/dist/css/index.css').then(() => console.info('stile caricato', process.env.NODE_ENV));
    import('@beacharound/beacharound-planner/dist/css/index.css').then(() => console.info('stile planner caricato', process.env.NODE_ENV));
    import('@beacharound/beacharound-map/dist/css/index.css').then(() => console.info('stile mappa caricato', process.env.NODE_ENV));
    import('@beacharound/beacharound-planner/dist/css/index.css').then(() => console.info('stile planner caricato', process.env.NODE_ENV));
  }

  // console.log(
  //   `%c proudly made by Opificio Lamantini Anonimi`,
  //   "background-color:#3A80B4; font-weight:bold; color:white;"
  // );
  // console.log(
  //   `%c beacharound: ${BeacharoundPackage?.version}`,
  //   "background-color:#3A80B4; font-weight:bold; color:white;"
  // );
  // console.log(
  //   `%c beacharound-ui: ${BeacharoundUiPackage?.version}`,
  //   "background-color:#3A80B4; font-weight:bold; color:white;"
  // );
  // console.log(
  //   `%c beacharound-ui-helpers: ${BeacharoundUiHelpersPackage?.version}`,
  //   "background-color:#3A80B4; font-weight:bold; color:white;"
  // );
  // console.log(
  //   `%c beacharound-map: ${BeacharoundMapPackage?.version}`,
  //   "background-color:#3A80B4; font-weight:bold; color:white;"
  // );
  // console.log(
  //   `%c beacharound-map: ${BeacharoundPlannerPackage?.version}`,
  //   "background-color:#3A80B4; font-weight:bold; color:white;"
  // );

  const [areConsentsVerified, setAreConsentsVerified] = useState(false);
  const [activeActivity, setActiveActivity] = useState();
  const [activeRole, setActiveRole] = useState();
  const isPublicBeach = activeActivity?.type === "public";
  const isPrivateBeach = activeActivity?.type === "private";
  const isDemo = activeActivity?.demo;
  const manualBooking = activeActivity?.bookingType === "MANUAL";

  function setActivityHandler(data) {
    if (data?.role) {
      setActiveRole(data.role);
    }
    return setActiveActivity(data);
  }

  useEffect(() => {
    refreshUser();
    // Per i bagnini simpatici che tengono acceso il pc continuamente facciamo un refresh ogni 12 ore
    setInterval(refreshUser, 12 * 60 * 60 * 1000);
    // return clearInterval(timer);
  }, []);

  return (
    <QueryClientProvider client={queryCache}>
      <Suspense fallback={<LoadingContainer isLoading />}>
        <ConsentsContext.Provider
          value={{
            areVerified: areConsentsVerified,
            setAreVerified: setAreConsentsVerified,
          }}
        >
          <ActivitiesContext.Provider
            value={{
              setActiveActivity: setActivityHandler,
              activeActivity,
              activityId: activeActivity?._id,
              mapId: activeActivity?.map,
              isPublicBeach,
              isPrivateBeach,
              isDemo,
              manualBooking,
            }}
          >
            <RolesContext.Provider
              value={{
                role: activeRole,
                isAdmin: activeRole === "admin",
                isBagnino: activeRole === "bagnino",
                isSteward: activeRole === "steward",
                isGuest: activeRole === "guest",
                setRole: setActiveRole
              }}
            >
              <PrinterContext>
                <TutorialProvider isDemo={isDemo} steps={welcome}>
                  <Router>
                    <Switch>
                      {routes.map((route, i) => <MainRoute key={i} {...route} />)}
                      <Route path="*" render={() => <NotFoundPage />} />
                    </Switch>
                  </Router>
                </TutorialProvider>
              </PrinterContext>
            </RolesContext.Provider>
          </ActivitiesContext.Provider>
        </ConsentsContext.Provider>
      </Suspense>

      {process.env.NODE_ENV === "development" &&
        <ReactQueryDevtools initialIsOpen={false} />
      }
    </QueryClientProvider>
  );
}

export default App;
