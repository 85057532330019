export const version = "1";
export const title = `CONDIZIONI GENERALI DI REGISTRAZIONE E DI ADESIONE AI PACCHETTI DI BEACHAROUND BUSINESS `;

export const condition = `
1. OGGETTO
<br />
Con la registrazione al sito business.beacharound.com il Gestore di una struttura balneare o alberghiera potrà accedere ai servizi applicativi messi a disposizione da Beacharound.com, secondo le modalità e i termini di seguito riepilogati.
<br />
<br />
2. MODALITA’ E TERMINI DI ADESIONE
<br />
Il Gestore dovrà registrarsi al sito e creare un profilo utente business.
<br />
L’utente registrato potrà selezionare la gestione di spiagge e/o di hotel dall’elenco ovvero inserire manualmente tali dati.
<br />
L’utente dichiara, sotto la propria responsabilità, che la/e spiaggia e/o l’hotel selezionati sono gestiti in base ad una concessione amministrativa o ad altro titolo giuridico valido ed efficace.
<br />
L’utente potrà selezionare uno o più pacchetti tra quelli proposti per la gestione delle strutture selezionate. 
<br />
<br />
3. GESTIONE DELLE PRENOTAZIONI E DEI PAGAMENTI ATTRAVERSO BEACHAROUND
<br />
In caso di selezione di un pacchetto che preveda la possibilità di ricevere prenotazioni attraverso Beacharound.com, il Gestore avrà la possibilità di visualizzarle direttamente dalla propria pagina personale.
<br />
L’utente effettua il pagamento della prenotazione attraverso Beacharound.com.
<br />
Beacharound s.r.l. si impegna e si obbliga a versare al Gestore gli importi incassati entro il giorno quindici del mese successivo a quello della prenotazione e/o del pagamento effettuati dall’utente. 
<br />
Per ciascuna prenotazione ricevuta o pagata attraverso Beacharound.com è dovuta a Beacharound s.r.l. una provvigione dell’importo concordato al momento della selezione del pacchetto prescelto (salvo accordi diversi e successivi intercorsi tra Beacharound s.r.l. e il Gestore), che sarà trattenuta da Beacharound s.r.l. al momento del versamento al Gestore degli importi incassati.
<br />
Si precisa che le richieste di preventivo inoltrate dall’utente al Gestore di hotel o struttura alberghiera non sono soggette ad alcuna provvigione. 
<br />
<br />
4. RECENSIONI E PUBBLICAZIONE DI IMMAGINI DA PARTE DEGLI UTENTI
<br />
Con la registrazione al sito e l’adesione ad uno dei pacchetti indicati al punto 2, il Gestore accetta che l’utente possa recensire e caricare immagini nell’apposito campo, relativamente alla/e struttura/e selezionata/e.  
<br />
<br />
5. OBBLIGHI DEL GESTORE
<br />
Con l’adesione ai pacchetti indicati al punto 2, il Gestore si obbliga a:
<br />
a) esercitare la gestione della pagina per finalità lecite, comunque connesse all’attività svolta.
<br />
b) fornire informazioni veritiere e attinenti all’attività svolta e ai servizi connessi, nonché a pubblicare contenuti di testo e immagini pertinenti e non offensivi del decoro e/o dell’onore altrui;
<br />
c) trattare i dati personali degli utenti in conformità alle disposizioni contenute nel Reg. U.E. 679/2016 e nel D. lgs. 196/2003, in qualità di autonomo titolare del trattamento;
<br />
d) aggiornare la gestione della propria pagina in relazione alla disponibilità dei servizi offerti, ad adempiere alle obbligazioni assunte in relazione alle singole prenotazioni effettuate dagli utenti, ad emettere i relativi documenti fiscali per i servizi acquistati e a fornire agli utenti tutte le informazioni necessarie relativamente ai servizi offerti. 
<br />
e) segnalare tempestivamente a Beacharound s.r.l. eventuali contenuti pubblicati dagli utenti non conformi alla normativa vigente. 
<br />
<br />
6. CLAUSOLA DI ESONERO DA RESPONSABILITA’
<br />
Beacharound s.r.l. declina ogni responsabilità con riferimento: 
<br />
a) alle informazioni fornite all’interno della propria pagina e alle immagini pubblicate dal singolo Gestore, con riserva di eliminare e/o di non rendere visibili eventuali contenuti ritenuti contrari alla normativa vigente e di agire nelle opportune sedi a tutela della propria immagine, in caso di pubblicazioni contrarie alla normativa vigente e alle presenti condizioni;
<br />
b) alle recensioni rilasciate dagli utenti, riservandosi il diritto di segnalare alle competenti Autorità e/o di eliminare eventuali contenuti contrari alla Legge;
<br />
c) ai rapporti contrattuali tra l’utente e il Gestore, con particolare riferimento all’esecuzione della prestazione pattuita e al rispetto dei Regolamenti d’uso e di accesso alle Struttura da parte dell’utente;
<br />
d) all’eventuale interruzione del flusso di prenotazioni dovuto a cause indipendenti dalla propria volontà. 
<br />
<br />
7. RISOLUZIONE DEL CONTRATTO 
<br />
In caso di mancato rispetto degli obblighi assunti dal Gestore con la sottoscrizione del presente contratto, Beacharound s.r.l. si riserva di invocare la risoluzione contrattuale, con sospensione immediata della pagina e con riserva di richiedere gli eventuali danni subiti in conseguenza dell’inadempimento del Gestore. 
<br />
<br />
8. MANUTENZIONE DELL’APPLICATIVO E INTERRUZIONE TEMPORANEA DEL SERVIZIO
<br />
In caso di manutenzione e/o aggiornamento dell’applicativo, Beacharound s.r.l. si riserva il diritto di sospendere temporaneamente il servizio, dandone idonea e tempestiva comunicazione al singolo Gestore, senza che possa esservi opposizione e/o eventuali richieste risarcitorie in relazione alla temporanea interruzione del servizio. 
<br />
<br />
9. LEGGE APPLICABILE E FORO COMPETENTE
<br />
L’adesione ai pacchetti indicati al punto 2 è interamente regolata dalle presenti condizioni e dalla Legge italiana.
<br />
In caso di controversie relative all’interpretazione, all’applicazione e all’esecuzione della presente informativa, è competente in via esclusiva il Foro di Rimini. 
<br />
<br />
10. CONTATTI
<br />
Per qualsiasi informazione, sarà possibile contattare il Servizio di assistenza al seguente indirizzo mail:info@beacharound.com e/o consultare le F.A.Q. presenti sul sito business.beacharound.com .`

export const checks = [{
    name: "terms",
    label: 'Ho preso visione e dichiaro di accettare le <a href="/terms" target="_blank">condizioni di registrazione e di adesione ai pacchetti di Beacharound Business</a>'
}, {
    name: "clauses",
    label: 'Ai sensi degli artt. 1341 e 1342 c.c., si approvano specificamente e per iscritto le seguenti clausole: art. 6. (Clausola di esonero da responsabilità), art. 7. (Risoluzione del contratto), art. 8. (Manutenzione dell’applicativo ed interruzione temporanea del servizio), art. 9. (Legge applicabile e Foro competente).'
}]