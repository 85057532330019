export function getNamesString(item) {
    if(!item) {
        return "";
    }

    if(item.name) {
        return item.name;
    }
    
    return `${item.firstName ? `${item.firstName}` : ""}${item.firstName ? " " : ""}${item.lastName ? `${item.lastName}` : ""}`
}