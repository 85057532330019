import { lazy } from 'react';
import FrontendLayout from '../layouts/FrontendLayout/FrontendLayout';

const Blog = lazy(() => import('../pages/Blog/Blog'));
const BlogType = lazy(() => import('../pages/Blog/BlogType'));
const BlogDetail = lazy(() => import('../pages/Blog/BlogDetail'));

export default [
    {
        path: '/blog',
        component: Blog,
        layout: FrontendLayout,
        exact: true,
        analytics: { name: ["Blog"] }
    },
    {
        path: '/blog/:blogType',
        component: BlogType,
        layout: FrontendLayout,
        exact: true,
        analytics: { name: ["Blog"] }
    },
    {
        path: '/blog/:blogType/:newsSlug',
        component: BlogDetail,
        layout: FrontendLayout,
        exact: true,
        analytics: { name: ["Blog news"] }
    },
]