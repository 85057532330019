import axiosV2 from "./_defaults/v2/user";

export async function createOrganization({ data }) {
    const response = await axiosV2.post("/business-organizations", data);

    if (response.data) {
        return response.data;
    }

    return response;
}

export async function updateOrganization({ data, elementId }) {
    const response = await axiosV2.post(
        `/business-organizations/${elementId}`,
        data
    );

    if (response.data) {
        return response.data;
    }

    return response;
}

export async function getOrganizations({ params }) {
    try {
        const response = await axiosV2.get(`/business-organizations`, { params });

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error?.response?.status === 404) {
            return [];
        }

        throw error;
    }
}

export async function getOrganization({ elementId }) {
    const response = await axiosV2.get(`/business-organizations/${elementId}`);

    if (response.data) {
        return response.data;
    }

    return response;
}

export async function connectActivityToOrganization({ elementId, activityId, activityType }) {
    try {
        const response = await axiosV2.post(
            `/business-organizations/${elementId}/activities`,
            {
                activityId,
                activityType,
            }
        );

        return response?.data;
    } catch (error) {
        console.log(error);
    }
}

export async function disconnectActivityToOrganization({ elementId, activityId, activityType }) {
    try {
        const response = await axiosV2.delete(
            `/business-organizations/${elementId}/activities`,
            {
                params: {
                    activityType,
                    activityId
                }
            }
        );

        return response?.data;
    } catch (error) {
        console.log(error);
    }
}

export async function getUsers({ elementId, params }) {
    try {
        const response = await axiosV2.get(`/business-organizations/${elementId}/users`, { params });
        return response?.data;

    } catch (error) {
        console.log(error);
    }
}

export async function getUser({ elementId, userId, params }) {
    try {
        const response = await axiosV2.get(`/business-organizations/${elementId}/users/${userId}`, { params });
        return response?.data;

    } catch (error) {
        console.log(error);
    }
}

export async function updateOrganizationUserRole({ elementId, userId, role }) {
    try {
        const response = await axiosV2.post(
            `/business-organizations/${elementId}/users/${userId}`, { role }
        );

        return response?.data;
    } catch (error) {
        console.log(error);
    }
}

export async function connectUserToOrganization({ elementId, userId, role }) {
    try {
        const response = await axiosV2.post(
            `/business-organizations/${elementId}/users/${userId}/connect`, { role }
        );

        return response?.data;
    } catch (error) {
        console.log(error);
    }
}

export async function disconnectUserFromOrganization({ elementId, userId, params }) {
    try {
        const response = await axiosV2.delete(
            `/business-organizations/${elementId}/users/${userId}/disconnect`, { params }
        );

        return response?.data;
    } catch (error) {
        console.log(error);
    }
};
