// import { differenceInCalendarDays } from 'date-fns';
import Cookies from 'js-cookie';
import decode from 'jwt-decode';
import { uploadImage } from './images';
import { createOrganization } from './organizations';

import axiosV2 from './_defaults/v2/anonymous';
import userAxiosV2 from './_defaults/v2/user';

const DEFAULT_EXPIRES = 7;

export async function createUser(data) {

    try {
        const response = await axiosV2.post("/business-users/", data);

        if (response.data && response.data.auth) {

            const user = decode(response.data.token);

            // Cookies.set('user-authorization', response.data.token, { expires: differenceInCalendarDays(new Date(), new Date(user.exp)) });
            Cookies.set('user-authorization', response.data.token, { expires: DEFAULT_EXPIRES });
            Cookies.set('user-refreshToken', response.data.refreshToken, { expires: DEFAULT_EXPIRES });
            Cookies.set('user-id', user._id, { expires: DEFAULT_EXPIRES });

            const organization = await createOrganization({
                data: {
                    type: "private",
                    name: `Organizzazione di ${user.profile?.firstName} ${user.profile?.lastName}`,
                    users: [
                        {
                            user: user._id,
                            role: "admin",
                        },
                    ],
                },
            });

            return { ...(user.profile || {}), organization: organization._id };
        }

        return response;
    } catch (error) {
        throw error;
    }
}

export async function activateUser(data) {
    const response = await axiosV2.post("/business-users/activate", data);

    if (response.data && response.data.auth) {

        const user = decode(response.data.token);

        // Cookies.set('user-authorization', response.data.token, { expires: differenceInCalendarDays(new Date(), new Date(user.exp)) })
        Cookies.set('user-authorization', response.data.token, { expires: DEFAULT_EXPIRES });
        Cookies.set('user-refreshToken', response.data.refreshToken, { expires: DEFAULT_EXPIRES });
        Cookies.set('user-id', user._id, { expires: DEFAULT_EXPIRES });

        return user.profile;
    }

    return response;
}

export async function sendActivationCode(data) {
    const response = await axiosV2.post("/business-users/activate/send", data);

    return response;
}

export async function loginUser(data) {
    const response = await axiosV2.post("/business-users/login", data);

    if (response.data && response.data.auth) {

        const user = decode(response.data.token);

        // Cookies.set('user-authorization', response.data.token, { expires: differenceInCalendarDays(new Date(), new Date(user.exp)) });
        Cookies.set('user-authorization', response.data.token, { expires: DEFAULT_EXPIRES });
        Cookies.set('user-refreshToken', response.data.refreshToken, { expires: DEFAULT_EXPIRES });
        Cookies.set('user-id', user._id, { expires: DEFAULT_EXPIRES });

        return user.profile;
    }

    return response;
}

export function getLocalUser() {
    const userId = Cookies.get("user-id");
    return !!userId ? userId : undefined;
}

export function logoutUser() {

    Cookies.remove('user-authorization');
    Cookies.remove('user-refreshToken');
    Cookies.remove('user-id');

    return true;
}

export async function refreshUser() {

    let refreshToken = Cookies.get('user-refreshToken');
    let userId = Cookies.get('user-id');

    if (!refreshToken || !userId) {
        return;
    }

    const response = await axiosV2.post("/business-users/token", { _id: userId, refreshToken });

    if (response.data && response.data.auth) {

        const user = decode(response.data.token);

        // Cookies.set('user-authorization', response.data.token, { expires: differenceInCalendarDays(new Date(), new Date(user.exp)) })
        Cookies.set('user-authorization', response.data.token, { expires: DEFAULT_EXPIRES });
        Cookies.set('user-refreshToken', response.data.refreshToken, { expires: DEFAULT_EXPIRES });
        Cookies.set('user-id', user._id, { expires: DEFAULT_EXPIRES });

        return user.profile;
    }

    return response;
}

export async function updateUser(data) {
    const response = await userAxiosV2.put("/business-users/profile", data);

    if (response?.data) {
        return response.data;
    }
    return response;
}

export async function getUser() {
    const response = await userAxiosV2.get("/business-users/me");

    if (response?.data) {
        return response.data;
    }
    return response;
}

export async function convertUser({ data }) {
    try {
        const response = await axiosV2.post("/business-users/convert", data);

        if (response.data && response.data.auth) {

            const user = decode(response.data.token);

            // Cookies.set('user-authorization', response.data.token, { expires: differenceInCalendarDays(new Date(), new Date(user.exp)) });
            Cookies.set('user-authorization', response.data.token, { expires: DEFAULT_EXPIRES });
            Cookies.set('user-refreshToken', response.data.refreshToken, { expires: DEFAULT_EXPIRES });
            Cookies.set('user-id', user._id, { expires: DEFAULT_EXPIRES });

            await createOrganization({
                data: {
                    type: "private",
                    name: `Organizzazione di ${user.profile?.firstName} ${user.profile?.lastName}`,
                    users: [
                        {
                            user: user._id,
                            role: "admin",
                        },
                    ],
                },
            });

            return user.profile;
        }

    } catch (error) {
        throw error;
    }
}

export async function requestUserToken({ data }) {
    const response = await axiosV2.post("/business-users/password/ask", data);

    if (response.data) {
        return response.data;
    }

    return response;
}

export async function changeUserPassword({ data }) {
    const response = await axiosV2.post("/business-users/password/reset", data);

    if (response.data) {
        return response.data;
    }

    return response;
}

export async function saveUserConsent({ data }) {
    try {
        const response = await userAxiosV2.post("/business-users-consents", data);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export async function getMissingUserConsent({ data }) {
    try {
        const response = await userAxiosV2.post("/users-consents/missing", data);
        return response?.data;
    } catch (error) {
        if (error.response.status === 404) {
            return [];
        };
        throw error;
    }
}

export async function uploadUserImage({ file }) {
    try {
        const { url, key } = await getPutUrl({ file });

        await uploadImage({ url, file });

        await updateUser({
            image: {
                url: key,
            }
        });
    } catch (error) {
        throw error;
    }
}

export async function getPutUrl({ file }) {
    try {
        const response = await userAxiosV2.post(`/users/images`, {
            contentType: file.type,
            extension: file.name.split(".")[1],
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}
