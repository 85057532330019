import { lazy } from 'react';
import MainLayout from '../layouts/MainLayout/MainLayout';

// Catalogo
const HotelCatalog = lazy(() => import('../pages/Hotel/Catalog/HotelCatalog'));

const OffersList = lazy(() => import('../pages/Hotel/Catalog/Offers/OffersList'));
const OffersDetail = lazy(() => import('../pages/Hotel/Catalog/Offers/OffersDetail'));
// import OffersAdd from '../pages/Hotel/Catalog/Offers/OffersAdd';
const OffersAddWizard = lazy(() => import('../pages/Hotel/Catalog/Offers/OffersAddWizard'));

const RoomsList = lazy(() => import('../pages/Hotel/Catalog/Rooms/RoomsList'));
const RoomsDetail = lazy(() => import('../pages/Hotel/Catalog/Rooms/RoomsDetail'));
const RoomsAdd = lazy(() => import('../pages/Hotel/Catalog/Rooms/RoomsAdd'));


export default [{
    path: '/rooms/create',
    component: RoomsAdd,
    layout: MainLayout,
    exact: true,
    permissions: { memberships: ["OFFERS","BOOKING","QUOTATIONS"] },
    analytics: { name: ["Camere","Creazione"] }
},{
    path: '/offers/create',
    component: OffersAddWizard,
    layout: MainLayout,
    exact: true,
    permissions: { memberships: ["OFFERS"] },
    analytics: { name: ["Offerte","Creazione"] }
},{
    path: '/offers/create/:offerId',
    component: OffersAddWizard,
    layout: MainLayout,
    exact: true,
    permissions: { memberships: ["OFFERS"] },
    analytics: { name: ["Offerte","Creazione"] }
},{
    path: '/rooms/:roomId',
    component: RoomsDetail,
    layout: MainLayout,
    permissions: { memberships: ["OFFERS","BOOKING","QUOTATIONS"] },
    analytics: { name: ["Camere","Dettaglio"] }
},{
    path: '/offers/:offerId',
    component: OffersDetail,
    layout: MainLayout,
    permissions: { memberships: ["OFFERS"] },
    analytics: { name: ["Offerte","Dettaglio"] }
},{
    path: '',
    component: HotelCatalog,
    layout: MainLayout,
    routes: [{
        path: `/rooms`,
        component: RoomsList,
        exact: true,
        permissions: { memberships: ["OFFERS","BOOKING","QUOTATIONS"] },
        analytics: { name: ["Camere"] }
    }, {
        path: `/offers`,
        component: OffersList,
        exact: true,
        permissions: { memberships: ["OFFERS"] },
        analytics: { name: ["Offerte"] }
    }].map(route => {
        return {
            ...route,
            path: `/hotels/:hotelId/catalog${route.path}`,
            private: true,
            analytics: { name: ["Hotel", "Catalogo", ...(route.analytics?.name || [])] }
        }
    })
}].map(route => {
    return {
        ...route,
        path: `/hotels/:hotelId/catalog${route.path}`,
        private: true,
        analytics: { name: ["Hotel", "Catalogo", ...(route.analytics?.name || [])] }
    }
})

