import {
    getPrinterStatusXml as fp_getPrinterStatus,
    getReservationProductsXml as fp_getReservationProductsXml,
    getOrderReceiptXml as fp_getOrderReceiptXml,
    getNonFiscalReceiptXml as fp_getNonFiscalReceiptXml
} from './fp';
import {
    getPrinterStatusXml as tm_getPrinterStatus,
    getReservationProductsXml as tm_getReservationProductsXml,
    getOrderReceiptXml as tm_getOrderReceiptXml,
    getNonFiscalReceiptXml as tm_getNonFiscalReceiptXml
} from './tm';

export function getPrinterStatusXml({printer}) {
    if(["TM-T88", "TM-T20"].includes(printer?.model)) {
        return tm_getPrinterStatus();
    }
    return fp_getPrinterStatus();
}

export function getReservationProductsXml({printer, ...data}) {
    if(["TM-T88", "TM-T20"].includes(printer?.model)) {
        return tm_getReservationProductsXml(data);
    }
    return fp_getReservationProductsXml(data);
}

export function getOrderReceiptXml({printer, ...data}) {
    if(["TM-T88", "TM-T20"].includes(printer?.model)) {
        return tm_getOrderReceiptXml(data);
    }
    return fp_getOrderReceiptXml(data);
}

export function getNonFiscalReceiptXml({printer, ...data}) {
    if(["TM-T88", "TM-T20"].includes(printer?.model)) {
        return tm_getNonFiscalReceiptXml(data);
    }
    return fp_getNonFiscalReceiptXml(data);
}