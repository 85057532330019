import { lazy } from 'react';

import MainLayout from '../layouts/MainLayout/MainLayout';
import EmptyLayout from '../layouts/EmptyLayout/EmptyLayout';

const GeneralDashboard = lazy(() =>  import('../pages/General/GeneralDashboard'));
const NotFound = lazy(() =>  import('../pages/NotFound'));
const Terms = lazy(() =>  import('../pages/General/Terms'));
const Privacy = lazy(() =>  import('../pages/General/Privacy'));

export default [
    {
        path: "/404",
        component: NotFound,
        layout: MainLayout,
        analytics: { name: ["404"]},
    },
    {
        path: '/dashboard',
        component: GeneralDashboard,
        layout: EmptyLayout,
        layoutOptions: { emptyHeader: true },
        exact: true,
        analytics: { name: ["Dashboard"] },
        private: true
    },
    {
        path: '/terms',
        component: Terms,
        layout: EmptyLayout,
        exact: true,
        analytics: { name: ["Termini e condizioni"] }
    },
    {
        path: '/privacy',
        component: Privacy,
        layout: EmptyLayout,
        exact: true,
        analytics: { name: ["Privacy policy"] }
    }
]
