import React, { useState, Fragment } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import qs from 'query-string';
import { add, eachDayOfInterval, endOfYear, format, isAfter, startOfYear } from 'date-fns';

import { formatDate, getCustomersAndHotelsString, getBeachesStatusColor, isMobile } from '@beacharound/beacharound-ui-helpers';
import { ButtonsRow, Divider, MainHeader, MainHeaderActions, MainHeaderActionsItem, MainHeaderDropdown, MainHeaderLogo, MainHeaderSearch, MainHeaderSelector, MainMenu, MainMenuItem, MobileToolbar, Paragraph, SearchComponent, SectionTitle, SimpleLink, MainButton, MainContainer, ColumnContainer, Column } from '@beacharound/beacharound-ui';
import NotificationsListItem from './Notifications/NotificationsListItem';

import { getReservations } from '../api/reservations/offers';
import { getQuotations } from "../api/quotations";
import { logoutUser } from '../api/user';
import { getActiveMemberships } from '../api/memberships';
import { getBeachesMapActive } from '../api/beachesMaps/maps';
import { getBeach } from '../api/beach';
import { getHotel } from '../api/hotel';
import { getNearestReservations, getReservation } from '../api/reservations/beaches';
import { getPlacedProduct } from '../api/beachesMaps/placedProducts';
import { getNotifications, getNotificationsCount, viewNotifications } from '../api/notifications';

import { usePrinters } from '../context/printers';
import { useActivities } from '../context/activities';
import { useRoles } from '../context/roles';

export default function MainHeaderBusiness({ empty, user, activities }) {

    const [mobileSearch, setMobileSearch] = useState(false);
    const [notificationsVisible, setNotificationsVisible] = useState();
    const history = useHistory();
    const { pathname, search } = useLocation();
    const { date: mapDate } = qs.parse(search ?? "");

    const cache = useQueryClient();
    const { mutate: logout } = useMutation(logoutUser, {
        onSuccess: () => cache.invalidateQueries('user')
    });

    const { setActiveActivity, isPublicBeach, activeActivity } = useActivities();
    const { role, isGuest } = useRoles();
    const { hasPrinters } = usePrinters();

    const activity = activities?.find(activity => pathname.indexOf(activity._id) > 0);

    useQuery(["beaches", activity?._id], () =>
        getBeach({ elementId: activity?._id }),
        {
            enabled: !!activity?._id && activity?.type === "beaches",
            onSuccess: (data) => setActiveActivity(data)
        }
    );

    useQuery(["hotels", activity?._id], () =>
        getHotel({ elementId: activity?._id }),
        {
            enabled: !!activity?._id && activity?.type === "hotels",
            onSuccess: (data) => setActiveActivity(data)
        }
    );

    const { data: reservations } = useQuery(
        ["reservations", activity?._id, "PENDING"],
        () =>
            getReservations({
                elementId: activity?._id,
                params: { params: { status: "PENDING" } },
            }),
        { enabled: !!activity?._id && activity?.type === "hotels" }
    );

    const { data: quotations } = useQuery(
        ["quotations", activity?._id, "PENDING"],
        () =>
            getQuotations({
                elementId: activity?._id,
                params: { params: { status: "PENDING" } },
            }),
        { enabled: !!activity?._id && activity?.type === "hotels" }
    );

    const { data: memberships } = useQuery(
        ["memberships", activity?._id],
        () => getActiveMemberships({ activityId: activity?._id }),
        { enabled: !!activity?._id }
    );

    const { data: beachMaps, isLoading: isLoadingMap } = useQuery(["map", activity?._id, "active"], () =>
        getBeachesMapActive({ activityId: activity?._id }),
        { enabled: !!activity?._id }
    );

    const { data: notificationsCounter } = useQuery(["notifications", activity?._id, "count"], () =>
        getNotificationsCount({ activityId: activity?._id }),
        {
            enabled: !!activity?._id,
            refetchInterval: 1 * 60 * 1000 // 1 minuto
        }
    );

    const { data: notifications } = useQuery(["notifications", activity?._id], () =>
        getNotifications({
            activityId: activity?._id,
            params: { limit: 20 }
        }),
        {
            enabled: !!activity?._id,
            refetchInterval: 1 * 60 * 1000 // 1 minuto
        }
    );

    const { mutate: setView, status: notificationsViewStatus } = useMutation(viewNotifications, {
        onSuccess: () => {
            return cache.invalidateQueries(["notifications", activity?._id]);
        },
        onError: (error) => {
            console.log(error);
        }
    });

    const map = beachMaps?.map;

    const startDate = activeActivity?.availability?.fromDate;
    const startMapDate = (startDate && isAfter(new Date(startDate), new Date())) ? startDate : format(new Date(), 'yyyy-MM-dd');

    if (activeActivity?.map) {
        const dates = eachDayOfInterval({ start: new Date(startMapDate), end: add(new Date(startMapDate), { days: 30 }) });
        dates.map(date => {
            cache.prefetchQuery(
                ["map", activity?._id, formatDate(new Date(date), "yyyy-MM-dd"), "active"],
                () => getBeachesMapActive({
                    activityId: activity?._id,
                    params: {
                        date: format(new Date(date), "yyyy-MM-dd"),
                        reservations: true
                    }
                }),
                {
                    enabled: !!activity?._id,
                    staleTime: 10 * 60 * 1000 // 10 minuti
                }
            );
            return date;
        });
    }

    let menuActions = [{
        icon: "plus",
        text: "Nuova prenotazione",
        url: `/map/manual-schedule?fromDate=${formatDate(new Date(), 'yyyy-MM-dd')}&toDate=${formatDate(new Date(), 'yyyy-MM-dd')}`,
        activityType: ["beaches"],
        beachType: ["private"],
        memberships: ["MANAGEMENT"],
        excludedRoles: ["steward", "guest"]
    }, {
        text: "Cerca",
        icon: 'search',
        action: () => setMobileSearch(true),
        activityType: ["hotels", "beaches"],
        beachType: ["public", "private"],
        memberships: ["BASIC", "MANAGEMENT"],
        excludedRoles: ["guest"],
        mobileOnly: true
    },
    {
        text: "Dashboard",
        icon: 'dashboard',
        url: "",
        active: pathname.replace(/\//g, "") === `/${activity?.type}/${activity?._id}`.replace(/\//g, "") ? true : false,
        activityType: ["beaches"],
        beachType: ["private"],
        memberships: ["BOOKING"],
        noMobile: false
    },
    // {
    //     icon: 'stats',
    //     text: "Statistiche",
    //     url: "/stats",
    //     activityType: ["beaches"],
    //     beachType: ["private"],
    //     memberships: ["MANAGEMENT"],
    //     excludeRoles: ["steward", "bagnino"],
    //     noMobile: true
    {
        icon: 'qrcode',
        text: "Accessi",
        url: "/entrances/scanner",
        activityType: ["beaches"],
        beachType: ["private"],
        memberships: ["MANAGEMENT"],
        excludedRoles: ["guest"]
    }, {
        text: "Impostazioni",
        icon: 'gear',
        faIcon: true,
        faStyle: "solid",
        url: "/settings",
        activityType: ["beaches"],
        memberships: ["MANAGEMENT"],
        excludedRoles: ["steward", "guest"],
        noMobile: true
    }, {
        text: "Notifiche",
        icon: 'bell',
        faIcon: true,
        faStyle: "regular",
        url: "/notifications",
        activityType: ["hotels", "beaches"],
        memberships: ["BASIC", "BOOKING", "MANAGEMENT"],
        excludedRoles: ["steward", "guest"],
        notificationsNumber: notificationsCounter?.notifications || 0,
        _id: "notifications"
    }].filter((link) => {
        const hasMemberships = link.memberships?.some((pack) => ["ACTIVE", "TRIAL"].includes(memberships?.[pack]));
        const hasExcludedMemberships = link.excludeMemberships?.some((pack) => ["ACTIVE", "TRIAL"].includes(memberships?.[pack]));
        const hasExcludedRole = link.excludedRoles?.includes(role);

        return hasMemberships && !hasExcludedMemberships && !hasExcludedRole;
    });

    let mapUrl, plannerUrl;
    if (map && !isLoadingMap) {
        mapUrl = isPublicBeach ? `/map?durationType=morning` : `/map?date=${startMapDate}`;
        plannerUrl = `/planner?map=${map._id}`;
    }
    if (!map && !isLoadingMap) {
        mapUrl = `/settings/maps/create`;
    }

    let menuList = [{
        text: "Accessi",
        icon: 'address-card',
        faIcon: true,
        faStyle: "regular",
        url: "/entrances",
        activityType: ["beaches"],
        beachType: ["public", "private"],
        memberships: ["MANAGEMENT"],
        excludeRoles: ["admin", "bagnino", "guest"],
    }, {
        text: "Mappa",
        icon: 'umbrella-beach',
        faIcon: true,
        url: mapUrl,
        activityType: ["beaches"],
        beachType: ["public", "private"],
        memberships: ["MANAGEMENT"],
        excludeRoles: ["steward"],
        hide: !mapUrl
    }, {
        text: "Planner",
        icon: 'sliders',
        faIcon: true,
        url: plannerUrl,
        linkComponent: 'a',
        blank: true,
        activityType: ["beaches"],
        beachType: ["private"],
        memberships: ["MANAGEMENT"],
        excludeRoles: ["steward", "guest"],
        hide: !plannerUrl
    }, {
        text: "Prenotazioni",
        icon: 'table-list',
        faIcon: true,
        url: "/reservations",
        notifications: reservations?.length,
        activityType: ["hotels", "beaches"],
        beachType: ["public", "private"],
        memberships: ["OFFERS", "MANAGEMENT", "BOOKING"],
        excludeRoles: ["steward", "bagnino", "guest"]
    }, {
        text: "Preventivi",
        icon: 'book',
        faIcon: true,
        url: "/quotes",
        notifications: reservations?.length,
        activityType: ["hotels", "beaches"],
        beachType: ["public", "private"],
        memberships: ["OFFERS", "MANAGEMENT"],
        excludeRoles: ["steward", "bagnino", "guest"]
    }, {
        text: "Food",
        icon: 'ice-cream',
        faIcon: true,
        url: "/food/reservations",
        activityType: ["beaches"],
        beachType: ["public", "private"],
        memberships: ["MANAGEMENT"],
        excludeRoles: ["steward", "guest"]
    }, {
        text: "Eventi",
        icon: 'calendar',
        faIcon: true,
        faStyle: "regular",
        url: "/events/reservations",
        activityType: ["beaches"],
        beachType: ["public", "private"],
        memberships: ["MANAGEMENT"],
        excludeRoles: ["steward", "guest"]
    }, {
        text: "Noleggi",
        icon: 'calendar',
        faIcon: true,
        url: "/rental/reservations",
        notifications: reservations?.length,
        activityType: ["beaches"],
        beachType: ["public", "private"],
        memberships: ["MANAGEMENT"],
        excludeRoles: ["steward", "guest"]
    }, {
        text: "Preventivi",
        icon: 'book',
        faIcon: true,
        url: "/quotations",
        notifications: quotations?.length,
        activityType: ["hotels"],
        memberships: ["QUOTATIONS"],
        excludeRoles: ["steward", "bagnino", "guest"]
    }, {
        text: "Stampanti",
        icon: 'cash-register',
        faIcon: true,
        url: "/printers/fiscal",
        activityType: ["beaches"],
        beachType: ["private"],
        memberships: ["MANAGEMENT"],
        excludeRoles: ["steward", "bagnino", "guest"],
        printers: true
    }, {
        text: "Clienti",
        icon: 'address-book',
        faIcon: true,
        faStyle: "regular",
        url: "/customers",
        activityType: ["beaches"],
        beachType: ["private"],
        memberships: ["MANAGEMENT"],
        excludeRoles: ["steward", "guest"]
    }, {
        text: "Hotel",
        icon: 'hotel',
        faIcon: true,
        url: "/hotels",
        activityType: ["beaches"],
        beachType: ["private"],
        memberships: ["MANAGEMENT"],
        excludeRoles: ["steward", "guest"]
    }, {
        text: "Catalogo",
        icon: 'boxes-stacked',
        faIcon: true,
        url: "/catalog/offers",
        activityType: ["hotels"],
        memberships: ["OFFERS", "QUOTATIONS"],
        excludeRoles: ["steward", "guest"]
    }, {
        text: "Catalogo",
        icon: 'boxes-stacked',
        faIcon: true,
        url: "/catalog/umbrellas",
        activityType: ["beaches"],
        beachType: ["private"],
        memberships: ["MANAGEMENT", "BOOKING"],
        excludeRoles: ["steward", "guest"]
    }, {
        text: "Gestione disponibilità",
        icon: 'dashboard',
        url: "/catalog/pitches",
        activityType: ["beaches"],
        beachType: ["public"],
        memberships: ["MANAGEMENT", "BOOKING"],
        excludeRoles: ["steward", "bagnino", "guest"]
    }, {
        text: "Annuncio",
        icon: 'clipboard',
        faIcon: true,
        faStyle: "regular",
        url: "/settings/info",
        activityType: ["beaches"],
        beachType: ["public", "private"],
        memberships: ["BASIC"],
        excludeMemberships: ["MANAGEMENT", "BOOKING"],
        excludeRoles: ["steward", "bagnino", "guest"]
    }, {
        text: "Dashboard",
        icon: 'dashboard',
        url: "",
        activityType: ["hotels", "beaches"],
        beachType: ["public", "private"],
        memberships: ["BASIC", "MANAGEMENT"],
        excludeRoles: ["admin", "bagnino"]
    }, {
        text: "Statistiche",
        icon: 'chart-line',
        faIcon: true,
        url: "/stats",
        activityType: ["beaches"],
        beachType: ["private"],
        memberships: ["MANAGEMENT"],
        excludeRoles: ["steward", "bagnino", "guest"],
        // onlyMobile: true
    }, {
        text: "Impostazioni",
        icon: 'settings-gear',
        url: "/settings",
        activityType: ["hotels", "beaches"],
        memberships: ["MANAGEMENT"],
        excludeMemberships: ["BOOKING"],
        excludeRoles: ["steward", "bagnino", "guest"],
        onlyMobile: true
    }, {
        text: "Recensioni",
        icon: 'star-half-stroke',
        faIcon: true,
        url: "/settings/reviews",
        activityType: ["beaches"],
        beachType: ["public", "private"],
        memberships: ["BASIC"],
        excludeMemberships: ["MANAGEMENT", "BOOKING"],
        excludeRoles: ["steward", "bagnino", "guest"]
    }/* ,{
        text: "Prenota",
        icon: 'dashboard',
        url: "/reservations/new/empty",
        activityType: ["beaches"],
        beachType: ["private"],
        memberships: ["BOOKING"],
        excludeMemberships: ["MANAGEMENT"],
        excludeRoles: ["steward", "bagnino"],
        highlight: true
    }*/, {
        text: "Fatturazione",
        icon: 'money',
        url: "/settings/billing",
        activityType: ["beaches"],
        beachType: ["private"],
        memberships: ["BOOKING"],
        excludeMemberships: ["MANAGEMENT"],
        excludeRoles: ["steward", "bagnino", "guest"],
        highlight: true
    }, {
        text: "Express",
        icon: 'stripe-s',
        faIcon: 'stripe-s',
        faStyle: "brands",
        url: "/settings/stripe/connect",
        activityType: ["beaches"],
        beachType: ["private"],
        memberships: ["BOOKING"],
        excludeMemberships: ["MANAGEMENT"],
        excludeRoles: ["steward", "bagnino", "guest"],
        highlight: true
    }, {
        text: "Date di apertura",
        icon: 'calendar',
        url: "/settings/seasonality",
        activityType: ["beaches"],
        beachType: ["private"],
        memberships: ["BOOKING"],
        excludeMemberships: ["MANAGEMENT"],
        excludeRoles: ["steward", "bagnino", "guest"],
        highlight: true
    }, {
        text: "Notifiche",
        icon: 'settings',
        url: "/settings/notificationsSettings",
        activityType: ["beaches"],
        beachType: ["private"],
        memberships: ["BOOKING"],
        excludeMemberships: ["MANAGEMENT"],
        excludeRoles: ["steward", "bagnino", "guest"],
        highlight: true
    }, {
        text: "WhatsApp",
        icon: 'whatsapp',
        faIcon: 'whatsapp',
        faStyle: "brands",
        url: "/settings/whatsapp",
        activityType: ["beaches"],
        beachType: ["private"],
        memberships: ["BOOKING"],
        excludeMemberships: ["MANAGEMENT"],
        excludeRoles: ["steward", "bagnino", "guest"],
        highlight: true
    }].filter((link) => {
        if (link.hide) return false;
        if (!link.activityType && !link.memberships && !link.excludeRoles) return true;
        if (link.printers && !hasPrinters) return false;

        const hasActivityType = link.activityType?.includes(activity?.type);
        let hasBeachType = true;
        if (hasActivityType && link.beachType) {
            hasBeachType = link.beachType?.includes(activity?.beachType);
        }
        const hasMemberships = link.memberships?.some((pack) =>
            ["ACTIVE", "TRIAL"].includes(memberships?.[pack])
        );
        const hasExcludedMemberships = link.excludeMemberships?.some((pack) =>
            ["ACTIVE", "TRIAL"].includes(memberships?.[pack])
        );
        const hasExcludedRoles = link.excludeRoles?.some((excludeRole) => excludeRole === role);

        return hasActivityType && hasMemberships && !hasExcludedMemberships && hasBeachType && !hasExcludedRoles;
    });

    const menuListParsed = menuList?.map(route => {
        const pathnameArray = pathname?.split('/');
        const routeArray = route?.url?.includes("?") ? route?.url?.slice(0, route?.url?.indexOf("?"))?.split('/') : route?.url?.split('/');

        let currentPath = pathnameArray?.[3];
        let activePath = routeArray?.[1];

        if (["payments", "transactionsScheduled", "deactivations", "coupons", "transactions", "groups"].includes(currentPath)) {
            currentPath = "reservations";
        }

        if (route?.memberships.includes("BOOKING") && route?.url?.includes("settings")) {
            currentPath = pathnameArray?.[4];
            activePath = routeArray?.[2];
        }

        return {
            ...route,
            url: route.absoluteUrl || `/${activity?.type}/${activity?._id}${route.url}`,
            active: currentPath === activePath
        };
    });

    const menuActionsParsed = menuActions?.map(route => {
        return {
            ...route,
            url: route.absoluteUrl || `/${activity?.type}/${activity?._id}${route.url}`,
            active: route.active !== undefined ? route.active : pathname.includes(route.url)
        };
    });

    function logoutHandler() {
        logout();
        return history.push(`/user/login`);
    }

    // const { setActiveActivity } = useActivities();
    function changeActivity(id, type) {
        // const activeActivity = activities.find(({_id}) => _id === id);
        // setActiveActivity(activeActivity);
        return history.push(`/${type}/${id}`);
    }

    async function selectReservationHandler(name, value, field) {

        if (field && field.date && field.placedProduct) {

            // const newProduct = placedProducts?.find((prod) => prod._id === field.placedProduct);
            cache.prefetchQuery(
                ["placedProduct", activeActivity?._id, field.placedProduct, field.date],
                () => getPlacedProduct({ activityId: activeActivity?._id, date: field.date, placedProductId: field.placedProduct }),
                { enabled: !!field.date && !!field.placedProduct }
            );
            cache.prefetchQuery(
                ["placedProduct", activeActivity?._id, field.placedProduct],
                () => getPlacedProduct({ activityId: activeActivity?._id, placedProductId: field.placedProduct }),
                { enabled: !!field.placedProduct }
            );
            cache.prefetchQuery(
                ["reservations", activeActivity?._id, field.reservation],
                () => getReservation({ activityId: activeActivity?._id, reservationId: field.reservation }),
                { enabled: !!field.reservation }
            );

            mobileSearch && setMobileSearch(false);

            return history.push(`/beaches/${activeActivity?._id}/map?${qs.stringify({
                date: field.date,
                placedProduct: field.placedProduct,
                reservation: field.reservation
            })}`);
        }

        // let afterSearch = qs.parse(locationSearch);
        // return history.push(`${url}?${qs.stringify({...afterSearch, placedProduct: undefined})}`);
    }

    function getReservationOptions(data) {
        return data?.map(option => ({
            id: option._id,
            reservation: option._id,
            label: getCustomersAndHotelsString(option),
            sublabel: `<strong>${option?.firstPlacedProduct?.customNumber || option?.firstPlacedProduct?.index}</strong> - checkin il ${formatDate(option.checkin, 'dd/MM/yyyy')}`,
            date: option.checkin,
            placedProduct: option?.firstPlacedProduct?._id,
            icon: 'beach',
            iconColor: getBeachesStatusColor(option.status)
        }));
    }

    async function reservationsLoadOptions(search, callback) {

        if (!search) {
            return [];
        }

        const searchDate = new Date(mapDate || map?.fromDate);

        const searchOptions = {
            nearestDate: formatDate(new Date(), 'yyyy-MM-dd'),
            search,
            params: {
                checkin: { $gte: startOfYear(searchDate) },
                checkout: { $lte: endOfYear(searchDate) }
            }
        };

        const result = await getNearestReservations({
            activityId: activeActivity?._id,
            params: searchOptions,
        });

        cache.setQueryData(["reservations", searchOptions], result);
        return callback(getReservationOptions(result));
    }

    function setNotificationsVisibleHandler(dropdownStatus) {
        return setNotificationsVisible(dropdownStatus);
    }

    function viewAllHandler() {
        return setView({
            activityId: activity?._id,
            data: {
                notifications: notifications?.map(({ _id }) => _id)
            }
        });
    }

    const notView = (notifications && Array.isArray(notifications)) ? notifications?.filter(el => !el.view) : false;

    return (
        <Fragment>
            <MainHeader
                menuActions={menuActionsParsed}
                empty={empty}
                user={user}
                activityId={activity?._id}
                userName={user && `${user.firstName} ${user.lastName}`}
                activities={activities?.sort((a, b) => a?.name?.localeCompare(b?.name))}
                linkComponent={Link}
                logoUrl={user ? "/dashboard" : "/"}
                backToDashboard={() => history.push(`/dashboard`)}
                backAction={() => history.goBack()}
                logoutHandler={logoutHandler}
                reservationsDefaultOptions={[]}
                reservationsLoadOptions={reservationsLoadOptions}
                selectReservationHandler={selectReservationHandler}
            >
                <MainHeaderLogo
                    component={Link}
                    to={user ? "/dashboard" : "/"}
                />

                {!isGuest &&
                    <MainHeaderSearch
                        defaultOptions={[]}
                        loadOptions={reservationsLoadOptions}
                        onChangeInput={selectReservationHandler}
                    />
                }

                <MainHeaderSelector
                    options={activities}
                    selected={activity?._id}
                    onSelect={changeActivity}
                >
                    <Paragraph text={user && `${user.firstName} ${user.lastName}`} larger />
                    {memberships?.BOOKING !== "ACTIVE" && ["admin", "bagnino"].includes(role) &&
                        <ButtonsRow noMargin>
                            <SimpleLink to={`/${activity?.type}/${activity?._id}/settings`} component={Link} text="Impostazioni" />
                        </ButtonsRow>
                    }
                    <ButtonsRow marginBottom>
                        <SimpleLink action={logoutHandler} text="Logout" />
                    </ButtonsRow>
                    <Divider />
                </MainHeaderSelector>

                <MainHeaderActions>
                    {menuActionsParsed.map((action, index) => {
                        if (action._id === "notifications" && !isMobile()) {
                            return (
                                <MainHeaderActionsItem
                                    key={(index + action?.text.toLowerCase()).replace(/ /g, "")}
                                    action={() => setNotificationsVisibleHandler(true)}
                                    {...action}
                                >
                                    <MainHeaderDropdown
                                        isOpen={notificationsVisible}
                                        setOpen={setNotificationsVisibleHandler}
                                    >
                                        <MainContainer flex flexSpaceBetween autoWidth alignCenter>
                                            <ColumnContainer noMargin>
                                                <Column column={2}>
                                                    <SectionTitle text="Notifiche" />
                                                    <SimpleLink component={Link} text="Vedi di più >" to={`/beaches/${activity?._id}/notifications`} />
                                                </Column>
                                                <Column column={2} flexEnd>
                                                    {notView?.length > 0 &&
                                                        <MainButton onlyIcon icon="bookmark" faIcon action={viewAllHandler} status={notificationsViewStatus} />
                                                    }
                                                </Column>
                                            </ColumnContainer>
                                        </MainContainer>

                                        <Divider />

                                        {!!notifications?.length ? notifications?.map(notification =>
                                            <NotificationsListItem
                                                key={notification?._id}
                                                notification={notification}
                                                noBorder
                                            />
                                        ) : <Paragraph text="Nessuna nuova notifica" />}
                                    </MainHeaderDropdown>
                                </MainHeaderActionsItem>
                            );
                        }

                        return (
                            <MainHeaderActionsItem
                                key={(index + action?.text.toLowerCase()).replace(/ /g, "")}
                                component={action.url && Link}
                                to={action.url}
                                {...action}
                            />
                        );
                    })}
                </MainHeaderActions>
            </MainHeader>

            <MobileToolbar
                visibleList={!!role ? menuListParsed : []}
                linkComponent={Link}
            />

            {/* <MobileMenu
                linkComponent={Link}
                user={user}
                open={menuOpen}
                list={menuListParsed}
                closeMenu={() => setMenuOpen(false)}
                loginAction={() => history.push("/user/login")}
            /> */}

            {!isGuest && mobileSearch &&
                <div className='header-search-container'>
                    <SearchComponent
                        // headerStyle
                        isAsync
                        isVisible
                        closeCallback={() => setMobileSearch(false)}
                        defaultOptions={[]}
                        loadOptions={reservationsLoadOptions}
                        onChangeInput={selectReservationHandler}
                        buttonText="Cerca prenotazioni"
                        placeholder="Cerca prenotazioni"
                    />
                </div>
            }

            <MainMenu>
                {!!role ? menuListParsed?.map((item, index) =>
                    <MainMenuItem
                        key={index}
                        {...item}
                        linkComponent={Link}
                    />
                ) : []}
            </MainMenu>
        </Fragment>
    );
}
