import axiosV2 from "./_defaults/v2/user";

export async function getArticles({ params }) {
    try {
        const response = await axiosV2.get(`/blog/articles`, {params});
    
        if (response.data) {
            return response.data;
        }
    } catch(error) {
        if(error.response.status === 404) {
            return []
        }
        throw error;
    }
}

export async function getArticle({ slug, params }) {
    try {
        const response = await axiosV2.get(`/blog/articles/${slug}`, {params});
    
        if (response.data) {
            return response.data;
        }
    } catch(error) {
        if(error.response.status === 404) {
            return {}
        }
        throw error;
    }
}
