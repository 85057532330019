import { uploadImage } from './images';
import axiosV2 from './_defaults/v2/user';

export async function createBeach(data) {
    try {
        const response = await axiosV2.post("/beaches", data);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export async function updateBeach({ data, elementId }) {
    try {
        const response = await axiosV2.post(`/beaches/${elementId}`, data);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export async function getBeaches() {
    try {
        const response = await axiosV2.get(`/beaches`);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export async function getBeach({elementId}) {

    try {
        const response = await axiosV2.get(`/beaches/${elementId}`);
        return response?.data;
    } catch(error) {
        throw error;
    }
    
}

// Public api
//  TODO: [BEACHDEV-58] Creare api pubbliche per spiagge e hotel
export async function getPublicBeaches({params}) {

    try {
        const response = await axiosV2.get(`/business-beaches`, {params});

        if (response.data) {
            return response.data
        }
    } catch(error) {
        
        if(error.response.status === 404) return [];
        throw error;
    }
}

export async function getOwnerlessBeaches({params}) {

    try {
        const response = await axiosV2.get(`/business-beaches/ownerless`, {params});

        if (response.data) {
            return response.data
        }
    } catch(error) {
        
        if(error.response.status === 404) return [];
        throw error;
    }
}

export async function getPutUrl({elementId, file}) {
    const response = await axiosV2.post(`/business-beaches/${elementId}/images`, {
        contentType: file.type,
        extension: file.name?.split(".")[1],
    });

    if (response.data) {
        return response.data
    }

    return response
}

export async function uploadCover({ elementId, file }) {

    try {
        
        const { url, key } = await getPutUrl({ elementId, file });
        await uploadImage({ url, file });
        await updateBeach({
            elementId,
            data: {
                cover: {
                    url: key,
                },
            },
        });
        
    } catch (error) {
        throw error;
    }
}

export async function uploadGalleryImage({ elementId, file, index }) {
    try {
        const { url, key } = await getPutUrl({
            elementId,
            file,
            isGallery: true,
            galleryIndex: index,
        });

        await uploadImage({ url, file });

        const response = await updateBeach({
            elementId,
            data: {
                $addToSet: {
                    gallery: {
                        url: key,
                        index,
                    },
                },
            },
        });

        if (response.data) {
            return response.data;
        }

        return response;
    } catch (error) {
        throw error;
    }
}

export async function getBeachTransactions({activityId, params}) {
    try {
        const response = await axiosV2.get(
            `/transactions/${activityId}`,
            {params}
        );

        if (response.data) {
            return response.data;
        }
    } catch (error) {
        if (error?.response?.status === 404) {
            return [];
        }
        throw error;
    }
}

export async function getPlannerOptions({ activityId }) {
    try {
        const response = await axiosV2.get(`/options-planner/${activityId}`);

        if(response.data) {
            return response.data;
        }

    } catch (error) {
        throw error;
    }
}

export async function updatePlannerOptions({ activityId, data }) {

    try {
        const response = await axiosV2.post(`/options-planner/${activityId}`, data);
        return response?.data;
        
    } catch (error) {
        throw error;
    }
}

export async function updateMapOptions({ activityId, data }) {

    try {
        const response = await axiosV2.post(`/options-map/${activityId}`, data);
        return response?.data;
        
    } catch (error) {
        throw error;
    }
}

export async function updateRating({ activityId }) {

    try {
        const response = await axiosV2.post(`/beaches/${activityId}/rating`);
        return response?.data;
        
    } catch (error) {
        throw error;
    }
}