import { lazy } from 'react';
import { getMassiveUrl } from '../helpers/massive.js';

import FrontendLayout from '../layouts/FrontendLayout/FrontendLayout';
import LoginLayout from '../layouts/LoginLayout/LoginLayout';

import features from '../pages/Frontend/data/beaches';
const BeachBooking = lazy(() => import('../pages/Frontend/BeachBooking'));

const Home = lazy(() => import('../pages/Frontend/HomeTest'));
const Beach = lazy(() => import('../pages/Frontend/BeachTest'));
const Hotel = lazy(() => import('../pages/Frontend/HotelTest'));

const BeachManagement = lazy(() => import('../pages/Frontend/BeachManagement'));
const BeachManagementDetail = lazy(() => import('../pages/Frontend/BeachManagementDetail'));

const Contacts = lazy(() => import('../pages/Frontend/Contacts'));
const RequestDemo = lazy(() => import('../pages/Frontend/RequestDemo'));
const RequestCall = lazy(() => import('../pages/Frontend/RequestCall'));
const RequestThanks = lazy(() => import('../pages/Frontend/RequestThanks'));
const ContactsThanks = lazy(() => import('../pages/Frontend/ContactsThanks'));
const GeneralSeoPage = lazy(() => import('../pages/Frontend/GeneralSeoPage'));


let frontendRoutes = [{
    path: '/',
    component: Home,
    layout: FrontendLayout,
    layoutOptions: { noPadding: true, noBack: true },
    exact: true,
    analytics: { name: ["Frontend","Home"] }
},{
    path: '/hotel-beacharound',
    component: Hotel,
    layout: FrontendLayout,
    layoutOptions: { noPadding: true },
    analytics: { name: ["Frontend","Hotel"] }
},{
    path: '/spiagge-beacharound',
    component: Beach,
    layout: FrontendLayout,
    layoutOptions: { noPadding: true },
    analytics: { name: ["Frontend","Spiagge"] }
},{
    path: '/booking-online',
    component: BeachBooking,
    layout: FrontendLayout,
    layoutOptions: { noPadding: true },
    analytics: { name: ["Frontend","Spiagge","Booking online"] }
},{
    path: '/gestionale-spiaggia',
    component: BeachManagement,
    layout: FrontendLayout,
    layoutOptions: { noPadding: true },
    analytics: { name: ["Frontend","Spiagge","Gestionale"] }
},
...features?.map(feature => {
    return {
        path: `/${feature.slug}`,
        component: BeachManagementDetail,
        layout: FrontendLayout,
        layoutOptions: { noPadding: true },
        analytics: { name: ["Frontend","Spiagge","Gstionale", feature.title] }
    }
}),{ 
    path: '/contatta-beacharound',
    component: Contacts,
    layout: FrontendLayout,
    exact: true,
    analytics: { name: ["Frontend","Contatta"] }
},{
    path: '/contatta-beacharound/grazie',
    component: ContactsThanks,
    layout: FrontendLayout,
    analytics: { name: ["Frontend","Contatta","Grazie"] }
},{
    path: '/richiedi-la-prova-gratuita',
    component: RequestDemo,
    layout: LoginLayout,
    layoutOptions: { noPadding: true },
    exact: true,
    analytics: { name: ["Frontend","Richiedi prova"] }
},{
    path: '/richiedi-la-prova-gratuita/grazie',
    component: RequestThanks,
    layout: FrontendLayout,
    analytics: { name: ["Frontend","Richiedi prova","Grazie"] }
},{
    path: '/ti-chiamiamo-noi',
    component: RequestCall,
    layout: LoginLayout,
    layoutOptions: { noPadding: true },
    exact: true,
    analytics: { name: ["Frontend","Richiedi contatto"] }
}, {
    path: '/ti-chiamiamo-noi/grazie',
    component: RequestThanks,
    layout: FrontendLayout,
    analytics: { name: ["Frontend","Richiedi contatto","Grazie"] }
},
...getMassiveUrl().map(url => {
    return {
        ...url,
        component: GeneralSeoPage,
        layout: FrontendLayout,
        layoutOptions: { noPadding: true },
        analytics: { name: ["seo", url.seo.title] },
    };
})]

export default frontendRoutes;
