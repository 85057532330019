import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { Redirect, Route, useLocation } from "react-router-dom";
import { useQueryClient } from 'react-query'
import { Helmet } from "react-helmet";

import { HeadSeo } from '@beacharound/beacharound-ui';
import { trackPage } from '@beacharound/beacharound-ui-helpers';

import MainLayout from '../../layouts/MainLayout/MainLayout';
import CheckPermissions from '../Permissions/CheckPermissions';
import UsersConsentsCheck from '../UsersConsents/UsersConsentsCheck';
// import { useRoles } from '../../context/roles';

export default function MainRoute(route) {

    const cache = useQueryClient();
    // const { role } = useRoles();
    const LayoutComponent = route.layout || MainLayout;

    const location = useLocation();

    useEffect(() => {
        if(!route?.analytics) {
            return;
        }

        return trackPage(route.analytics);
    }, [location, route.analytics]);

    // if(!!route.permissions?.roles?.length && role && !route.permissions?.roles.includes(role)) {
    //     return <Redirect to="/dashboard" />
    // }


    return (
        <LayoutComponent options={route.layoutOptions} {...route}>
            <Route
                exact={route.exact}
                path={route.path}
                render={(props) => {
                    if (route.private) {
                        const token = Cookies.get('user-authorization');
                        const refreshToken = Cookies.get('user-refreshToken');
                        const userId = Cookies.get('user-id');

                        if (!token && !refreshToken && !userId) {
                            cache.invalidateQueries()
                            return <Redirect to="/user/login" />
                        }
                    }
                    return (
                        <CheckPermissions permissions={route.permissions}>
                            <Helmet>
                                <meta name="robots" content={route.private ? "noindex, follow" : "index, follow"} />
                            </Helmet>
                            {route.private && route.analytics?.name &&
                                <HeadSeo title={route.analytics?.name} isBusiness/>
                            }
                            <UsersConsentsCheck />
                            <route.component {...props} {...route} routes={route.routes} />
                        </CheckPermissions>
                    )
                }}
            />
        </LayoutComponent>
    )
}