import React from 'react';

import { EmptyLayoutContainer, EmptyLayoutContent } from '@beacharound/beacharound-ui';

import MainFooter from '../../components/MainFooter';
import FrontendHeader from '../../components/FrontendHeader';

export default function EmptyLayout({ children, options }) {

    return (

        <EmptyLayoutContainer>
            <FrontendHeader empty={options?.emptyHeader}/>

            <EmptyLayoutContent>
                {children}
            </EmptyLayoutContent>

            <MainFooter noPadding/>
        </EmptyLayoutContainer>
    )
}
