import { addCouponToReservation } from "./reservations/beaches";
import axios from "./_defaults/v2/user";

export async function getCoupons({activityId, params}) {

    try {
        const response = await axios.get(`/coupons/${activityId}`, {params})
        if (response.data) {
            return response.data;
        }

        return response;
    } catch(error) {
        console.log(error);
        throw error;
    }
}

export async function getCoupon({activityId, couponId}) {
    try {
        const response = await axios.get(`/coupons/${activityId}/${couponId}`)
        return response?.data
    } catch(error) {
        console.log(error.response)
        throw error;
    }
}

export async function createCoupon({activityId, data}) {
    try {
        const response = await axios.post(`/coupons/${activityId}`, data)
        return response?.data
    } catch(error) {
        console.log(error.response)
        throw error;
    }
}

export async function cancelCoupon({activityId, couponId}) {
    try {
        const response = await axios.post(`/coupons/${activityId}/${couponId}/cancel`)
        return response?.data
    } catch(error) {
        console.log(error.response)
        throw error;
    }
}

export async function removeCoupon({activityId, couponId}) {
    try {
        const response = await axios.delete(`/coupons/${activityId}/${couponId}`)
        return response?.data
    } catch(error) {
        console.log(error.response)
        throw error;
    }
}

export async function sendCoupon({activityId, couponId, data}) {
    try {
        const response = await axios.post(`/coupons/${activityId}/${couponId}/send`, data)
        return response?.data
    } catch(error) {
        console.log(error.response)
        throw error;
    }
}

export async function createAndApplyCoupon({activityId, reservationId, data}) {
    try {
        const coupon = await createCoupon({
            activityId,
            data: {
                ...data,
                reservation: reservationId
            }
        });
        const response = await addCouponToReservation({
            activityId,
            reservationId,
            couponId: coupon._id
        });
        return response?.data
    } catch(error) {
        console.log(error.response)
        throw error;
    }
}