import { lazy } from 'react';
import MainLayout from '../layouts/MainLayout/MainLayout';

// Catalogo
const BeachRentals = lazy(() => import('../pages/Beach/Rentals/BeachRentals'));

const RentalsList = lazy(() => import('../pages/Beach/Rentals/Catalogue/RentalsList'));
const RentalsDetail = lazy(() => import('../pages/Beach/Rentals/Catalogue/RentalsDetail'));
const RentalsAdd = lazy(() => import('../pages/Beach/Rentals/Catalogue/RentalsAdd'));
const Planner = lazy(() => import('../pages/Beach/Rentals/Planner/Planner'));
const Settings = lazy(() => import('../pages/Beach/Rentals/Settings/Settings'));

const RevervationsList = lazy(() => import('../pages/Beach/Rentals/Reservations/ReservationsList'))
const RevervationsDetail = lazy(() => import('../pages/Beach/Rentals/Reservations/ReservationsDetail'))
const RevervationsDetailInfo = lazy(() => import('../pages/Beach/Rentals/Reservations/Detail/RevervationsDetailInfo'))
const BeachReservationsDetailTransactions = lazy(() => import('../pages/Beach/Reservations/Detail/BeachReservationsDetailTransactions'));
const BeachReservationsDetailBeacharoundPayments = lazy(() => import('../pages/Beach/Reservations/Detail/BeachReservationsDetailBeacharoundPayments'));

export default [{
    path: '/reservations/:reservationId',
    component: RevervationsDetail,
    layout: MainLayout,
    analytics: { name: ["Prenotazioni", "Dettaglio"] },
    routes: [{
        path: ``,
        component: RevervationsDetailInfo,
        exact: true,
        analytics: { name: ["Info"] }
    },{
        path: `/transactions`,
        exact: true,
        component: BeachReservationsDetailTransactions,
        analytics: { name: ["Transazioni"] }
    },{
        path: `/payments`,
        exact: true,
        component: BeachReservationsDetailBeacharoundPayments,
        analytics: { name: ["Pagamenti"] }
    }].map(route => {
        return {
            ...route,
            path: `/beaches/:beachId/rental/reservations/:reservationId${route.path}`,
            private: true,
            permissions: { memberships: ["MANAGEMENT", "BOOKING"] },
            analytics: { name: ["Spiagge", "Noleggi", ...(route.analytics?.name || [])] }
        }
    })
},{
    path: '/catalogue/add',
    component: RentalsAdd,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Catalogo","Creazione"] }
},{
    path: '/catalogue/:productId',
    component: RentalsDetail,
    layout: MainLayout,
    analytics: { name: ["Catalogo", "Dettaglio"] }
},{
    path: '',
    component: BeachRentals,
    layout: MainLayout,
    routes: [{
        path: `/reservations`,
        component: RevervationsList,
        exact: true,
        analytics: { name: ["Prenotazioni"] }
    }, {
        path: `/catalogue`,
        component: RentalsList,
        exact: true,
        analytics: { name: ["Servizi"] }
    }, {
        path: `/planner`,
        component: Planner,
        exact: true,
        analytics: { name: ["Planner"] }
    }, {
        path: `/settings`,
        component: Settings,
        exact: true,
        analytics: { name: ["Impostazioni"] }
    }].map(route => {
        return {
            ...route,
            path: `/beaches/:beachId/rental${route.path}`,
            private: true,
            permissions: { memberships: ["MANAGEMENT", "BOOKING"] },
            analytics: { name: ["Spiagge", "Noleggi", ...(route.analytics?.name || [])] }
        }
    })
}].map(route => {
    return {
        ...route,
        path: `/beaches/:beachId/rental${route.path}`,
        private: true,
        permissions: { memberships: ["MANAGEMENT", "BOOKING"] },
        analytics: { name: ["Spiagge", "Noleggi", ...(route.analytics?.name || [])] }
    }
})

