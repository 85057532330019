import axios from './_defaults/v2/user';

// TRNASAZIONI NORMALI
export async function createTransaction({ data, activityId }) {
    const response = await axios.post(`/transactions/${activityId}`, data);

    if (response.data) {
        return response.data
    }

    return response
}

export async function updateTransaction({ activityId, transactionId, data }) {
    try {
        const response = await axios.post(`/transactions/${activityId}/${transactionId}`, data);
        return response?.data;
    } catch (error) {
        if (error.response?.status === 404) {
            return [];
        }
        return error;
    }
}

export async function getTransaction({ activityId, transactionId, params }) {
    try {
        const response = await axios.get(`/transactions/${activityId}/${transactionId}`, {
            params
        });

        return response?.data;
    } catch (error) {
        if (error.response?.status === 404) {
            return []
        }
        return error;
    }
}

export async function getTransactions({ activityId, params }) {
    try {
        const response = await axios.get(`/transactions/${activityId}`, {
            params
        });

        return response?.data;
    } catch (error) {
        if (error.response?.status === 404) {
            return []
        }
        return error;
    }
}

export async function removeTransaction({ activityId, transactionId }) {
    try {
        const response = await axios.delete(`/transactions/${activityId}/${transactionId}`);

        return response?.data;
    } catch (error) {
        if (error.response?.status === 404) {
            return []
        }
        return error;
    }
}

// TRANSAZIONI PROGRAMMATE
export async function createScheduledTransaction({ activityId, data }) {
    const response = await axios.post(`/transactions/${activityId}/scheduled`, data);

    if (response.data) {
        return response.data
    }

    return response
}

export async function getScheduledTransactions({ activityId, params }) {
    try {
        const response = await axios.get(`/transactions/${activityId}/scheduled`, {
            params
        });

        return response?.data;
    } catch (error) {
        if (error.response?.status === 404) {
            return []
        }
        return error;
    }
}

export async function getScheduledTransaction({ activityId, transactionId, params }) {
    try {
        const response = await axios.get(`/transactions/${activityId}/scheduled/${transactionId}`, {
            params
        });

        return response?.data;
    } catch (error) {
        if (error.response?.status === 404) {
            return []
        }
        return error;
    }
}

export async function updateScheduledTransaction({ activityId, transactionId, data }) {
    try {
        const response = await axios.post(`/transactions/${activityId}/scheduled/${transactionId}`, data);

        return response?.data;
    } catch (error) {
        if (error.response?.status === 404) {
            return []
        }
        return error;
    }
}

export async function reloadScheduledTransaction({ activityId, transactionId, data }) {
    try {
        const response = await axios.post(`/transactions/${activityId}/scheduled/${transactionId}/reload`, data);

        return response?.data;
    } catch (error) {
        if (error.response?.status === 404) {
            return []
        }
        return error;
    }
}

export async function payScheduledTransaction({ activityId, transactionId, data }) {
    try {
        const response = await axios.post(`/transactions/${activityId}/scheduled/${transactionId}/pay`, data);

        return response?.data;
    } catch (error) {
        if (error.response?.status === 404) {
            return []
        }
        return error;
    }
}

export async function removeScheduledTransaction({ activityId, transactionId }) {
    try {
        const response = await axios.delete(`/transactions/${activityId}/scheduled/${transactionId}`);

        return response?.data;
    } catch (error) {
        if (error.response?.status === 404) {
            return []
        }
        return error;
    }
}