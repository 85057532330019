import { lazy } from 'react';

import MainLayout from '../layouts/MainLayout/MainLayout';

const AccessBookingMap = lazy(() => import('../pages/Beach/Access/AccessBooking/AccessBookingMap'));
const AccessBookingUserData = lazy(() => import('../pages/Beach/Access/AccessBooking/AccessBookingUser'));
const AccessBookingDate = lazy(() => import('../pages/Beach/Access/AccessBooking/AccessBookingDate'));
const AccessBookingEmpty = lazy(() => import('../pages/Beach/Access/AccessBooking/AccessBookingEmpty'));
const AccessBookingProducts = lazy(() => import('../pages/Beach/Access/AccessBooking/AccessBookingProducts'));
const AccessBookingServices = lazy(() => import('../pages/Beach/Access/AccessBooking/AccessBookingServices'));
const AccessBookingPrice = lazy(() => import('../pages/Beach/Access/AccessBooking/AccessBookingPrice'));
const AccessBookingPayment = lazy(() => import('../pages/Beach/Access/AccessBooking/AccessBookingPayment'));

const BeachRevervationsList = lazy(() => import('../pages/Beach/Reservations/BeachReservationsList'));
const BeachRevervationsDetail = lazy(() => import('../pages/Beach/Reservations/BeachReservationsDetail'));
const BeachReservationsPayment = lazy(() => import('../pages/Beach/Reservations/BeachReservationsPayment'));
const BeachGroupsPayment = lazy(() => import('../pages/Beach/Reservations/BeachGroupsPayment'));
const BeachGroupsSplitPayment = lazy(() => import('../pages/Beach/Reservations/BeachGroupsSplitPayment'));

const BeachRevervationsDetailInfo = lazy(() => import('../pages/Beach/Reservations/Detail/BeachReservationsDetailInfo'));
const BeachRevervationsDetailPlanning = lazy(() => import('../pages/Beach/Reservations/Detail/BeachReservationsDetailPlanning'));
const BeachReservationsDetailEntrances = lazy(() => import('../pages/Beach/Reservations/Detail/BeachReservationsDetailEntrances'));
const BeachReservationsDetailTransactions = lazy(() => import('../pages/Beach/Reservations/Detail/BeachReservationsDetailTransactions'));
const BeachReservationsDetailBeacharoundPayments = lazy(() => import('../pages/Beach/Reservations/Detail/BeachReservationsDetailBeacharoundPayments'));
const OnePageBooking = lazy(() => import('../pages/Beach/OnePageBooking/OnePageBooking'));
const BookingGroup = lazy(() => import('../pages/Beach/OnePageBooking/BookingGroup'));
const BookingFood = lazy(() => import('../pages/Beach/OnePageBooking/BookingFood'));
const BookingRental = lazy(() => import('../pages/Beach/OnePageBooking/BookingRental'));
const PublicBooking = lazy(() => import('../pages/Beach/OnePageBooking/PublicBooking'));
const SeasonalBooking = lazy(() => import('../pages/Beach/SeasonalBooking/SeasonalBooking'));
const BeachReservationsDetailProducts = lazy(() => import('../pages/Beach/Reservations/Detail/BeachReservationsDetailProducts'));
const BeachReservationsEdit = lazy(() => import('../pages/Beach/Reservations/BeachReservationsEdit'));

export default [{
    path: "/new/dates",
    component: AccessBookingDate,
    layout: MainLayout,
    analytics: { name: ["Nuova prenotazione", "Date"] },
}, {
    path: "/new/empty",
    component: AccessBookingEmpty,
    layout: MainLayout,
    analytics: { name: ["Nuova prenotazione", "Date"] },
}, {
    path: "/new/:reservationId/dates",
    component: AccessBookingDate,
    layout: MainLayout,
    analytics: { name: ["Nuova prenotazione", "Date"] },
}, {
    path: "/new/:reservationId/schedule",
    component: AccessBookingMap,
    layout: MainLayout,
    analytics: { name: ["Nuova prenotazione", "Mappa"] },
}, {
    path: "/new/:reservationId/customer",
    component: AccessBookingUserData,
    layout: MainLayout,
    analytics: { name: ["Nuova prenotazione", "Utente"] },
}, {
    path: "/new/:reservationId/products",
    component: AccessBookingProducts,
    layout: MainLayout,
    analytics: { name: ["Nuova prenotazione", "Dettaglio"] },
}, {
    path: "/new/:reservationId/services",
    component: AccessBookingServices,
    layout: MainLayout,
    analytics: { name: ["Nuova prenotazione", "Servizi"] },
}, {
    path: "/new/:reservationId/price",
    component: AccessBookingPrice,
    layout: MainLayout,
    analytics: { name: ["Nuova prenotazione", "Prezzo"] },
}, {
    path: "/new/:reservationId/payment",
    component: AccessBookingPayment,
    layout: MainLayout,
    analytics: { name: ["Nuova prenotazione", "Pagamento"] },
}, {
    path: "/:reservationId/reservationPayment",
    component: BeachReservationsPayment,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Prenotazioni", "Dettaglio", "Pagamento"] },
}, {
    path: "/simple",
    component: OnePageBooking,
    layout: MainLayout,
    exact: true,
    permissions: { memberships: ["BOOKING", "MANAGEMENT"] },
    analytics: { name: ["Prenotazione veloce"] },
}, {
    path: "/simple/:reservationId",
    component: OnePageBooking,
    layout: MainLayout,
    exact: true,
    permissions: { memberships: ["BOOKING", "MANAGEMENT"] },
    analytics: { name: ["Prenotazione veloce"] },
}, {
    path: "/food/:reservationId",
    component: BookingFood,
    layout: MainLayout,
    exact: true,
    permissions: { memberships: ["BOOKING", "MANAGEMENT"] },
    analytics: { name: ["Ordine veloce"] },
}, {
    path: "/rental/:reservationId",
    component: BookingRental,
    layout: MainLayout,
    exact: true,
    permissions: { memberships: ["BOOKING", "MANAGEMENT"] },
    analytics: { name: ["Prenotazione veloce servizi"] },
}, {
    path: "/group/:groupId",
    component: BookingGroup,
    layout: MainLayout,
    exact: true,
    permissions: { memberships: ["BOOKING", "MANAGEMENT"] },
    analytics: { name: ["Prenotazione per gruppi"] },
}, {
    path: "/group/:groupId/payment",
    component: BeachGroupsPayment,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Prenotazioni", "Gruppo", "Pagamento"] },
}, {
    path: "/group/:groupId/splitPayment",
    component: BeachGroupsSplitPayment,
    layout: MainLayout,
    exact: true,
    analytics: { name: ["Prenotazioni", "Gruppo", "Pagamento separato"] },
}, {
    path: "/public",
    component: PublicBooking,
    layout: MainLayout,
    exact: true,
    permissions: { memberships: ["BOOKING", "MANAGEMENT"] },
    analytics: { name: ["Prenotazione pubblica"] },
}, {
    path: "/public/:reservationId",
    component: PublicBooking,
    layout: MainLayout,
    exact: true,
    permissions: { memberships: ["BOOKING", "MANAGEMENT"] },
    analytics: { name: ["Prenotazione pubblica"] },
}, {
    path: "/seasonal",
    component: SeasonalBooking,
    layout: MainLayout,
    exact: true,
    permissions: { memberships: ["BOOKING", "MANAGEMENT"] },
    analytics: { name: ["Prenotazione stagionale"] },
}, {
    path: "/seasonal/:reservationId",
    component: SeasonalBooking,
    layout: MainLayout,
    exact: true,
    permissions: { memberships: ["BOOKING", "MANAGEMENT"] },
    analytics: { name: ["Prenotazione stagionale"] },
}, {
    path: "/edit",
    component: BeachReservationsEdit,
    layout: MainLayout,
    exact: true,
    permissions: { memberships: ["BOOKING", "MANAGEMENT"] },
    analytics: { name: ["Comferma più prenotazioni copiate"] },
}, {
    path: "/:reservationId",
    component: BeachRevervationsDetail,
    layout: MainLayout,
    permissions: { memberships: ["BOOKING", "MANAGEMENT"] },
    routes: [{
        path: ``,
        exact: true,
        component: BeachRevervationsDetailInfo,
    }, {
        path: `/products`,
        exact: true,
        component: BeachReservationsDetailProducts,
        analytics: { name: ["Prodotti"] }
    }, {
        path: `/entrances`,
        exact: true,
        component: BeachReservationsDetailEntrances,
        analytics: { name: ["Accessi"] }
    }, {
        path: `/transactions`,
        exact: true,
        component: BeachReservationsDetailTransactions,
        analytics: { name: ["Transazioni"] }
    }, {
        path: `/payments`,
        exact: true,
        component: BeachReservationsDetailBeacharoundPayments,
        analytics: { name: ["Transazioni"] }
    }, {
        path: `/planning`,
        exact: true,
        component: BeachRevervationsDetailPlanning,
        analytics: { name: ["Programmazione"] }
    }].map((route) => {
        return {
            ...route,
            path: `/beaches/:beachId/reservations/:reservationId${route.path}`,
            private: true,
            analytics: { name: ["Prenotazioni", "Dettaglio", ...(route.analytics?.name || [])] },
            permissions: { memberships: ["MANAGEMENT", "BOOKING"] },
        };
    })
}, {
    path: "/",
    component: BeachRevervationsList,
    layout: MainLayout,
}].map((route) => {
    return {
        ...route,
        path: `/beaches/:beachId/reservations${route.path}`,
        private: true,
        permissions: { memberships: ["MANAGEMENT", "BOOKING"] },
        analytics: {
            name: ["Spiagge", "Prenotazioni", ...(route.analytics?.name || [])],
        },
    };
});