import { lazy } from 'react';
import MainLayout from '../layouts/MainLayout/MainLayout';

// const Customers = lazy(() => import('../pages/Shared/Customers/Customers'));

const CustomerList = lazy(() => import('../pages/Shared/Customers/List'));
const CustomerAdd = lazy(() => import('../pages/Shared/Customers/Add'));
const CustomersCategories = lazy(() => import('../pages/Shared/Customers/Categories'));
const CustomersMerge = lazy(() => import('../pages/Shared/Customers/Merge'));

const CustomerDetail = lazy(() => import('../pages/Shared/Customers/Detail'));
const CustomerDetailInfo = lazy(() => import('../pages/Shared/Customers/Detail/CustomerDetailInfo'));
const CustomerDetailReservations = lazy(() => import('../pages/Shared/Customers/Detail/CustomerDetailReservations'));
const CustomerDetailPayments = lazy(() => import('../pages/Shared/Customers/Detail/CustomerDetailPayments'));
const CustomerDetailTransactions = lazy(() => import('../pages/Shared/Customers/Detail/CustomerDetailTransactions'));
const CustomerDetailScheduledTransactions = lazy(() => import('../pages/Shared/Customers/Detail/CustomerDetailScheduledTransactions'));

export default [
    {
        path: '',
        component: CustomerList,
        exact: true,
        analytics: { name: ["Lista"] },
    },
    {
        path: '/create',
        component: CustomerAdd,
        layout: MainLayout,
        exact: true,
        analytics: { name: ["Creazione"] },
    },
    {
        path: `/categories`,
        component: CustomersCategories,
        exact: true,
        analytics: { name: ["Categorie"] }
    },
    {
        path: '/:customerId/merge',
        component: CustomersMerge,
        layout: MainLayout,
        analytics: { name: ["Unisci duplicati"] },
    },
    {
        path: '/:customerId',
        component: CustomerDetail,
        layout: MainLayout,
        permissions: { memberships: ["BOOKING", "MANAGEMENT"] },
        routes: [
            {
                path: '',
                exact: true,
                component: CustomerDetailInfo,
                analytics: { name: ["Dettaglio cliente"] }
            },
            {
                path: '/reservations',
                exact: true,
                component: CustomerDetailReservations,
                analytics: { name: ["Prenotazioni cliente"] }
            },
            {
                path: '/payments',
                exact: true,
                component: CustomerDetailPayments,
                analytics: { name: ["Pagamenti cliente"] }
            }, {
                path: '/transactions',
                exact: true,
                component: CustomerDetailTransactions,
                analytics: { name: ["Transazioni cliente"] }
            }, {
                path: '/transactionsScheduled',
                exact: true,
                component: CustomerDetailScheduledTransactions,
                analytics: { name: ["Scadenziario cliente"] }
            }
        ].map((route) => ({
            ...route,
            path: `/beaches/:beachId/customers/:customerId${route.path}`,
            private: true,
            analytics: { name: ["Anagrafiche dettaglio", "Dettaglio", ...(route.analytics?.name || [])] },
            permissions: { memberships: ["MANAGEMENT", "BOOKING"] },
        }))
    }
].map((route) => ({
    ...route,
    path: `/beaches/:beachId/customers${route.path}`,
    private: true,
    permissions: { memberships: ["MANAGEMENT"] },
    analytics: {
        name: ["Spiagge", "Anagrafiche", ...(route.analytics?.name || [])],
    },
}));
