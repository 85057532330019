import { lazy } from 'react';
import EmptyLayout from '../layouts/EmptyLayout/EmptyLayout';
import LoginLayout from '../layouts/LoginLayout/LoginLayout';

const Login = lazy(() => import('../pages/User/Login'));
const SignupRequest = lazy(() => import('../pages/User/Signup/SignupRequest'));

const SignupAccount = lazy(() => import('../pages/User/Signup/SignupAccount'));
const SignupRole = lazy(() => import('../pages/User/Signup/SignupRole'));
const SignupData = lazy(() => import('../pages/User/Signup/SignupData'));
const SignupPassword = lazy(() => import('../pages/User/Signup/SignupPassword'));
const SignupThanks = lazy(() => import('../pages/User/Signup/SignupThanks'));

const ForgotPassword = lazy(() => import('../pages/User/ForgotPassword'));
const ChangePassword = lazy(() => import('../pages/User/ChangePassword'));
const SignupAdminRestore = lazy(() => import('../pages/User/Signup/SignupAdminRestore'));
const SignupCompleteProfile = lazy(() => import('../pages/User/Signup/SignupCompleteProfile'));

const SignupConversion = lazy(() => import('../pages/User/Signup/SignupConversion'));
const BeachActivation = lazy(() => import('../pages/User/BeachActivation'));

export default [{
    path: "/user/login",
    component: Login,
    layout: LoginLayout,
    analytics: { name: ["User", "Login"] },
},{
    path: "/user/signup-request",
    component: SignupRequest,
    layout: EmptyLayout,
    exact: true,
    analytics: { name: ["User", "Richiesta di registrazione"] },
},{
    path: '/user/signup',
    component: SignupAccount,
    layout: LoginLayout,
    exact: true,
    analytics: { name: ["User", "Signup", "Account"] },
},{
    path: '/user/signup/role',
    component: SignupRole,
    layout: EmptyLayout,
    analytics: { name: ["User", "Signup", "Ruolo"] },
},{
    path: '/user/signup/data',
    component: SignupData,
    layout: EmptyLayout,
    analytics: { name: ["User", "Signup", "Data"] },
},{
    path: '/user/signup/password',
    component: SignupPassword,
    layout: EmptyLayout,
    analytics: { name: ["User", "Signup", "Password"] },
},{
    path: '/user/signup/thanks',
    component: SignupThanks,
    layout: EmptyLayout,
    layoutOptions: { emptyHeader: true },
    analytics: { name: ["User", "Signup", "Thanks"] },
},{
    path: '/user/beach-activation',
    component: BeachActivation,
    layout: LoginLayout,
    layoutOptions: { emptyHeader: true },
    analytics: { name: ["User", "Attivazione spiaggia"] },
},{
    path: "/user/forgot-password",
    component: ForgotPassword,
    layout: EmptyLayout,
    analytics: { name: ["User", "Password", "Forgot"] },
},{
    path: "/user/restore-password/:token",
    component: ChangePassword,
    layout: EmptyLayout,
    analytics: { name: ["User", "Password", "Reset"] },
},{
    path: "/user/account-restore",
    component: SignupAdminRestore,
    layout: EmptyLayout,
    analytics: { name: ["User", "Restore", "Data"] },
},{
    path: "/user/complete-profile",
    component: SignupCompleteProfile,
    layout: EmptyLayout,
    analytics: { name: ["User", "Restore", "Profile"] },
},{
    path: "/user/convert-account",
    component: SignupConversion,
    layout: LoginLayout,
    analytics: { name: ["User", "Restore", "Profile"] },
}]
