import React from 'react';

import { Footer } from '@beacharound/beacharound-ui';

export default function MainFooter({noPadding}) {

    const footerString = `<a href="/mappa-del-sito">Mappa del sito</a>`

    return (
        <Footer
            footerString={footerString}
            noPadding={noPadding}
            // privacyLink={`<a href="https://www.iubenda.com/privacy-policy/66513587" class="iubenda-nostyle iubenda-embed" title="Privacy Policy" target="_blank">Privacy Policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>`}
            cookieLink={`<a href="https://www.iubenda.com/privacy-policy/66513587/cookie-policy" class="iubenda-nostyle iubenda-embed" title="Cookie Policy" target="_blank" rel="noreferrer">Cookie Policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>`}
            // termsLink={`<a href="https://www.iubenda.com/termini-e-condizioni/66513587" class="iubenda-nostyle iubenda-embed" title="Termini e Condizioni" target="_blank">Termini e Condizioni</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>`}
            privacyLink={`<a href="/privacy" title="Privacy Policy" target="_blank">Privacy Policy</a>`}
            termsLink={`<a href="/terms" title="Termini e condizioni" target="_blank">Termini e Condizioni</a>`}
        />
    )
}
