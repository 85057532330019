import axios from 'axios';
import https from 'https';
import { convert } from 'xmlbuilder2';

import { returnError } from '../error';

const epsonAxios = axios.create({
    headers: {
        'content-type': 'application/xml'
    },
    transformResponse: [(data) => {
        const payload = convert(data, { format: "object" });
        let response = payload?.["soapenv:Envelope"]?.["soapenv:Body"]?.["response"] || payload?.["s:Envelope"]?.["s:Body"]?.["response"];
        if(response?.["@success"] === "true") {
            // console.log(response?.["@success"])
            return response?.addInfo;
        }
        if(response?.["@success"] === "false") {
            // console.log(response?.["@error"])
            let error = new Error(response?.addInfo);
            response.status = response?.["@status"];
            error.response = response;

            if(response?.["@code"] === "EPTR_REC_EMPTY") {
                error.code = "EPTR_REC_EMPTY";
                error.message = "Carta esaurita";
            }

            return Promise.reject(error);
        }
        let error = new Error(response);
        return Promise.reject(error);
    },
    ...axios.defaults.transformResponse],
    httpsAgent: new https.Agent({
        rejectUnauthorized: false
    })
})

epsonAxios.interceptors.request.use(async(config) => {
    return config;
});

epsonAxios.interceptors.response.use(response => response, error => {
    return returnError(error);
});

export default epsonAxios;