import { lazy } from 'react';
import MainLayout from '../layouts/MainLayout/MainLayout';

const HotelDashboard = lazy(() => import('../pages/Hotel/HotelDashboard'));

const HotelRevervationsList = lazy(() => import('../pages/Hotel/Reservations/HotelReservationsList'));
const HotelRevervationsDetail = lazy(() => import('../pages/Hotel/Reservations/HotelReservationsDetail'));
const HotelReservationsPayment = lazy(() => import('../pages/Hotel/Reservations/HotelReservationsPayment'));

const HotelQuotationsList = lazy(() => import('../pages/Hotel/Quotations/HotelQuotationsList'));
const HotelQuotationsDetail = lazy(() => import('../pages/Hotel/Quotations/HotelQuotationsDetail'));

export default [
    {
        path: '/',
        component: HotelDashboard,
        layout: MainLayout,
        exact: true,
        analytics: { name: ["Dashboard"] }
    },
    // PRENOTAZIONI
    {
        path: '/reservations',
        component: HotelRevervationsList,
        layout: MainLayout,
        exact: true,
        permissions: { memberships: ["BOOKING","OFFERS"] },
        analytics: { name: ["Prenotazioni"] }
    },{
        path: '/reservations/:reservationId',
        component: HotelRevervationsDetail,
        layout: MainLayout,
        exact: true,
        permissions: { memberships: ["BOOKING","OFFERS"] },
        analytics: { name: ["Prenotazioni","Dettaglio"] }
    },{
        path: '/reservations/:reservationId/payment',
        component: HotelReservationsPayment,
        layout: MainLayout,
        permissions: { memberships: ["BOOKING","OFFERS"] },
        analytics: { name: ["Prenotazioni","Dettaglio","Pagamento"] }
    },
    // PREVENTIVI
    {
        path: '/quotations',
        component: HotelQuotationsList,
        layout: MainLayout,
        exact: true,
        permissions: { memberships: ["QUOTATIONS"] },
        analytics: { name: ["Preventivi"] }
    },{
        path: '/quotations/:quotationId',
        component: HotelQuotationsDetail,
        layout: MainLayout,
        exact: true,
        permissions: { memberships: ["QUOTATIONS"] },
        analytics: { name: ["Preventivi","Dettaglio"] }
    },
    
].map(route => {
    return {
        ...route,
        path: `/hotels/:hotelId${route.path}`,
        private: true,
        analytics: { name: ["Hotel", ...(route.analytics?.name || [])] }
    }
})