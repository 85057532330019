import format from "date-fns/format";
import { create as createXml } from "xmlbuilder2";
import { getTransactionsMethodString } from '../../../helpers/reservations';

export function getPrinterStatusXml() {

    const xml = createXml({ version: "1.0", encoding: "UTF-8" });
    const envelope = xml.ele({
        "s:Envelope": {
            "@xmlns:s": "http://schemas.xmlsoap.org/soap/envelope/"
        }
    });
    const body = envelope.ele("s:Body");
    body.ele("printerCommand").ele({
        "queryPrinterStatus": {
            "@operator": "",
            "@statusType": ""
        }
    });
    return xml.toString();
}

export function getReservationProductsXml({ data }) {

    const xml = createXml({ version: "1.0", encoding: "UTF-8" });
    const envelope = xml.ele({
        "s:Envelope": {
            "@xmlns:s": "http://schemas.xmlsoap.org/soap/envelope/"
        }
    });
    const body = envelope.ele("s:Body");
    const print = body.ele("printerNonFiscal")
    const nonFiscal = print.ele("beginNonFiscal");
    const { products, prices, price } = data;

    for (const product of products) {
        let name = product.name;
        let subname;

        if (["voucher", "normal", "seasonal"].includes(product.type)) {
            subname = product.mainItem?.name || product.name;

            if (product.relatedProducts) {
                subname = `${subname} con ${product.relatedProducts.map(({ name, quantity }) => `${quantity} ${name}`).join(", ")}`
            }
        }
        nonFiscal.ele({
            "printNormal": {
                "@description": name
            }
        });
        if (subname) {
            nonFiscal.ele({
                "printNormal": {
                    "@description": name,
                    "@data": subname
                }
            });
        }
        if (product.type !== "voucher") {
            nonFiscal.ele({
                "printNormal": {
                    "@description": name,
                    "@data": `prezzo: ${product.price?.toFixed(2)} €`
                }
            });
        }
        nonFiscal.ele({
            "printNormal": {
                "@description": ""
            }
        });
    }

    if (prices?.coupons > 0) {
        nonFiscal.ele({
            "printNormal": {
                "@description": "Sconto applicato"
            }
        });
        nonFiscal.ele({
            "printNormal": {
                "@description": "Sconto applicato",
                "@data": `- ${prices?.coupons?.toFixed(2)} €`
            }
        });
        nonFiscal.ele({
            "printNormal": {
                "@description": ""
            }
        });
    }

    if (price) {
        nonFiscal.ele({
            "printNormal": {
                "@description": prices?.custom ? "Prezzo personalizzato" : "Prezzo prenotazione",
                "@font": 4
            }
        });
        nonFiscal.ele({
            "printNormal": {
                "@description": prices?.custom ? "Prezzo personalizzato" : "Prezzo prenotazione",
                "@data": `${price?.toFixed(2)} €`,
                "@font": 4
            }
        });
    }

    nonFiscal.ele("endNonFiscal");

    return xml.toString();
}

export function getOrderReceiptXml({ receipt }) {

    const xml = createXml({ version: "1.0", encoding: "UTF-8" });
    const envelope = xml.ele({
        "s:Envelope": {
            "@xmlns:s": "http://schemas.xmlsoap.org/soap/envelope/"
        }
    });
    const body = envelope.ele("s:Body");
    const print = body.ele("printerNonFiscal")
    const nonFiscal = print.ele("beginNonFiscal");
    // const { products, prices, price } = data;
    const { products, foodNote, foodPlacedProduct, foodPaymentType, foodStatus, amount } = receipt;

    if (foodPlacedProduct?.number) {
        nonFiscal.ele({
            "printNormal": {
                "@description": `Posto: ${foodPlacedProduct?.number}`,
                "@font": 4
            }
        });
        nonFiscal.ele({
            "printNormal": {
                "@description": ""
            }
        });
    }

    let fixedProducts = products?.reduce((acc, item) => {
        const addedProductIndex = acc.findIndex(({ product }) => product === item.product);
        if (addedProductIndex >= 0) {
            acc[addedProductIndex] = {
                ...acc[addedProductIndex],
                quantity: acc[addedProductIndex].quantity + item.quantity
            }
        } else {
            acc = [
                ...acc,
                item
            ]
        }
        return acc;
    }, [])

    for (const product of fixedProducts) {
        let name = product.name;
        let quantity = product.quantity;
        nonFiscal.ele({
            "printNormal": {
                "@description": name
            }
        });
        if (quantity) {
            nonFiscal.ele({
                "printNormal": {
                    "@description": name,
                    "@data": `quantità: ${quantity}`
                }
            });
        }
        nonFiscal.ele({
            "printNormal": {
                "@description": ""
            }
        });
    }

    if (foodNote) {
        nonFiscal.ele({
            "printNormal": {
                "@description": "RICHIESTA",
                "@font": 4
            }
        });
        nonFiscal.ele({
            "printNormal": {
                "@description": foodNote,
            }
        });
        nonFiscal.ele({
            "printNormal": {
                "@description": ""
            }
        });
    }

    if (foodStatus !== "PAID" && foodPaymentType) {
        let paymentTypeString = getTransactionsMethodString(foodPaymentType);
        nonFiscal.ele({
            "printNormal": {
                "@description": "DA PAGARE",
                "@font": 4
            }
        });
        nonFiscal.ele({
            "printNormal": {
                "@description": paymentTypeString,
            }
        });
        nonFiscal.ele({
            "printNormal": {
                "@description": ""
            }
        });
    }

    if (foodStatus === "PAID") {
        nonFiscal.ele({
            "printNormal": {
                "@description": "PAGATO",
                "@font": 4
            }
        });
    }

    if (!!amount) {
        nonFiscal.ele({
            "printNormal": {
                "@description": `IMPORTO: ${amount.toFixed(2)}`,
                "@font": 4
            }
        });
    }

    nonFiscal.ele("endNonFiscal");
    return xml.toString();
}

export function getNonFiscalReceiptXml({ activityName, receipt }) {

    const xml = createXml({ version: "1.0", encoding: "UTF-8" });
    const envelope = xml.ele({
        "s:Envelope": {
            "@xmlns:s": "http://schemas.xmlsoap.org/soap/envelope/"
        }
    });
    const body = envelope.ele("s:Body");
    const print = body.ele("printerNonFiscal")
    const nonFiscal = print.ele("beginNonFiscal");
    const { products, amount, reservations } = receipt;

    if (!!activityName) {
        nonFiscal.ele({
            "printNormal": {
                "@description": activityName,
                "@align": "center"
            }
        });
    }

    if (!!reservations?.length) {
        for (const reservation of reservations) {
            let reservationString = `checkin: ${format(new Date(reservation.checkin), 'dd/MM')} - checkout ${format(new Date(reservation.checkout), 'dd/MM')}`;
            nonFiscal.ele({
                "printNormal": {
                    "@description": "Prenotazione",
                    "@data": reservationString,
                    "@font": 4
                }
            });
            if (reservation?.placedProduct?.number) {
                nonFiscal.ele({
                    "printNormal": {
                        "@description": "Prenotazione",
                        "@data": `Posto: ${reservation?.placedProduct?.number}`,
                        "@font": 4
                    }
                });
            }
            nonFiscal.ele({
                "printNormal": {
                    "@description": ""
                }
            });
        }
    }

    for (const product of products) {
        let name = product.description || product.name;
        let quantity = product.quantity;
        let price = product.amount;
        nonFiscal.ele({
            "printNormal": {
                "@description": name
            }
        });
        nonFiscal.ele({
            "printNormal": {
                "@description": name,
                "@data": `quantità: ${quantity} - prezzo: ${price.toFixed(2)} €`
            }
        });
        nonFiscal.ele({
            "printNormal": {
                "@description": ""
            }
        });
    }

    if (amount) {
        nonFiscal.ele({
            "printNormal": {
                "@description": "Totale",
                "@font": 4
            }
        });
        nonFiscal.ele({
            "printNormal": {
                "@description": "Totale",
                "@data": `${amount?.toFixed(2)} €`,
                "@font": 4
            }
        });
    }

    nonFiscal.ele("endNonFiscal");
    return xml.toString();
}

export function getFiscalReceiptXml({ receipt }) {

    const xml = createXml({ version: "1.0", encoding: "UTF-8" });
    const envelope = xml.ele({
        "s:Envelope": {
            "@xmlns:s": "http://schemas.xmlsoap.org/soap/envelope/"
        }
    });
    const body = envelope.ele("s:Body");
    const print = body.ele("printerFiscalReceipt")
    print.ele({
        "displayText": {
            "@operator": 1,
            "@data": "Stampa scontrino"
        }
    });
    const fiscal = print.ele({ "beginFiscalReceipt": { "@operator": 1 } });
    const { products, amount, method, adjustement, overrides } = receipt;

    if (!overrides?.includes("MODIFICATIONS")) {
        for (const product of products) {
            let name = product.description || product.name;
            let quantity = product.quantity;
            let price = product.amount;
            let department = product.department;
            if (quantity > 1) {
                name = `${name} x${quantity}`
            }
            fiscal.ele({
                "printRecItem": {
                    "@operator": 1,
                    "@description": name,
                    "@quantity": 1,
                    "@unitPrice": price,
                    "@department": department || 1,
                    "@justification": 1,
                }
            });
        };
    }

    if (overrides?.includes("MODIFICATIONS")) {
        fiscal.ele({
            "printRecItem": {
                "@operator": 1,
                "@description": "Aggiunte sucessive",
                "@quantity": 1,
                "@unitPrice": amount,
                "@department": 1,
                "@justification": 1,
            }
        });
    }

    if (adjustement?.amount && adjustement?.amount < 0) {
        fiscal.ele({
            "printRecSubtotalAdjustment": {
                "@operator": 1,
                "@adjustmentType": 1,
                "@description": adjustement?.description,
                "@amount": Math.abs(adjustement?.amount),
                "@justification": 1
            }
        });
    }

    if (adjustement?.amount && adjustement?.amount > 0) {
        fiscal.ele({
            "printRecSubtotalAdjustment": {
                "@operator": 1,
                "@adjustmentType": 6,
                "@description": adjustement?.description,
                "@amount": Math.abs(adjustement?.amount),
                "@justification": 1
            }
        });
    }

    if (amount) {
        let paymentType = 0;
        switch (method) {
            case "cash":
                paymentType = 0
                break;
            case "card":
                paymentType = 2
                break;
            case "transfer":
                paymentType = 2
                break;
            case "check":
                paymentType = 2
                break;
            case "applepay":
                paymentType = 2
                break;
            case "googlepay":
                paymentType = 2
                break;
            default:
                paymentType = 0
                break;
        }
        fiscal.ele({
            "printRecTotal": {
                "@operator": 1,
                "@description": "Totale",
                "@payment": amount,
                "@paymentType": paymentType,
                "@justification": 1
            }
        });
    }

    print.ele({ "endFiscalReceipt": { "@operator": 1 } });
    return xml.toString();
}

export function getFiscalVoidXml({ printerUrl, receipt }) {

    const xml = createXml({ version: "1.0", encoding: "UTF-8" });
    const envelope = xml.ele({
        "s:Envelope": {
            "@xmlns:s": "http://schemas.xmlsoap.org/soap/envelope/"
        }
    });
    const body = envelope.ele("s:Body");
    const print = body.ele("printerCommand")
    print.ele({
        "directIO": {
            "@command": "1078",
            "@data": receipt.cancelString
        }
    });
    return xml.toString();
}

export function getReportXml({ printerUrl, type }) {

    const xml = createXml({ version: "1.0", encoding: "UTF-8" });
    const envelope = xml.ele({
        "s:Envelope": {
            "@xmlns:s": "http://schemas.xmlsoap.org/soap/envelope/"
        }
    });
    const body = envelope.ele("s:Body");
    const print = body.ele("printerFiscalReport");
    let reportElement;
    switch (type) {
        case "z":
            reportElement = "printZReport"
            break;
        case "x":
            reportElement = "printXReport"
            break;
        default:
            reportElement = "printZReport"
            break;
    }
    print.ele({
        [reportElement]: {
            "@operator": 1,
            "@timeout": 10000
        }
    });
    return xml.toString();
}